import React, { useState } from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
// import { useNavigate } from "react-router-dom";
import { Button } from '@mantine/core';
// import { useModals } from '@mantine/modals';
import { useNotifications } from '@mantine/notifications';
import { PlusIcon, CheckIcon } from '@radix-ui/react-icons';

import TemplateFormModal from './modal';

import { callFunctionsAPI, getAPIErrorMessage } from '../../helpers/api';

const TemplateAddNew = ({
    authData,
    dispatch
}) => {
    // const modals = useModals();
    const notifications = useNotifications();
    const [ opened, setOpened ] = useState(false);
    const [ loading, setLoading ] = useState(false);

    const doModalClose = () => {
        // reset everything
        setLoading(false);
        setOpened(false);
    }

    const handleAddNew = (formData) => {
        let error = false;
        // do error check
        // check if name is empty
        if ( !formData.name ) {
            error = 'Name is required';
        }

        // check if errors are empty
        if ( error ) {
            notifications.showNotification({
                color: 'red',
                title: error,
            })
        } else {
            // no errors, add new project
            setLoading(true);
            callFunctionsAPI({
                url: 'template',
                action: 'add',
                formData: formData
            })
            .then(() => {
                notifications.showNotification({
                    color: 'teal',
                    icon: <CheckIcon />,
                    title: 'Template added',
                }) 
                // do reset
                doModalClose();
            })
            .catch(error => {
                setLoading(false);
                notifications.showNotification({
                    color: 'red',
                    title: getAPIErrorMessage( error ),
                }) 
            });
        }
    }

    return (
    <>

        <Button
            size='md'
            color={"indigo"}
            leftIcon={<PlusIcon />}
            onClick={() => setOpened(true)}>
            Add New Template
        </Button>

        <TemplateFormModal 
            opened={opened}
            loading={loading}
            onClose={doModalClose}
            doAction={handleAddNew} />
    
    </>
    )
}

const mapStateToProps = state => {
    return {
        authData: ( state.auth && state.auth.user ) ? state.auth.user : null,
    };
};

export default compose(
    connect(mapStateToProps)
)(TemplateAddNew);