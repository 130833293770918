import React, { useState, useMemo } from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
// import { useParams } from "react-router-dom";
import { Space, Box, MultiSelect, SegmentedControl, NumberInput, InputWrapper } from '@mantine/core';
// import { useDidUpdate, useDebouncedValue } from '@mantine/hooks';
// import { useNotifications } from '@mantine/notifications';
import { GearIcon, CodeIcon } from '@radix-ui/react-icons';

// import ButtonIconCircle from '../../components/ButtonIconCircle';
// import FormRangeInput from '../../components/FormRangeInput';
// import FormItems from '../../components/FormItems';
import AIModelSelector from '../../modules/AIModelSelector';

import { isArrayExists } from '../../helpers/validation';
import { getCustomModel } from '../../helpers/ai';

// import { useTemplate } from '../Templates/redux/hooks';

const FormSettings = ({
    authData,
    currentTeam,
    formData = {},
    onUpdate = () => {}
}) => {

    const templateEngine = useMemo(() => {
        return getCustomModel( ( formData.model || '' ), currentTeam, 'engine' );
    }, [ formData, currentTeam ] );

    return (
    <>
        <InputWrapper
            label="Model">
            <AIModelSelector
                noLabel
                dividerPosition="none"
                team={currentTeam}
                value={formData.model || ''}
                onUpdate={(newValue) => {
                    const newModelEngine = getCustomModel( newValue, currentTeam, 'engine' );
                    if ( newModelEngine === 'ai21' ) {
                        onUpdate({ ...formData, model: newValue, mode: 'completions' });
                    } else {
                        onUpdate({ ...formData, model: newValue });
                    }
                }} />
        </InputWrapper>

        <Space h="sm" />

        <InputWrapper
            label="Mode">
            <Box>
                <SegmentedControl
                    label="Mode"
                    color={"dark"}
                    radius="xl"
                    size='sm'
                    data={ templateEngine && templateEngine === 'openai' ? [
                        { label: 'Complete', value: 'completions' },
                        { label: 'Insert', value: 'inserts' },
                        { label: 'Edit', value: 'edits' },
                    ] : [ { label: 'Complete', value: 'completions' } ]}
                    value={formData.mode || 'completions'}
                    onChange={(newValue) => {
                        onUpdate({ ...formData, mode: newValue });
                    }} />
            </Box>
        </InputWrapper>

        { !( formData.mode && formData.mode === 'edits' ) && (
        <>

            <Space h="sm" />
            
            <NumberInput
                label="Max Output Length"
                value={formData.max_tokens || 0}
                min={1}
                max={2048}
                step={1}
                hideControls
                onChange={(newValue) => {
                    // make sure number is 2 decimal places
                    onUpdate({ ...formData, max_tokens: ( newValue ? newValue : 1 ) });
                }} />

        </>
        ) }

        <Space h="xs" />

        <NumberInput
            label="Temperature"
            value={formData.temperature || 0}
            min={0}
            max={1}
            step={0.01}
            precision={2}
            hideControls
            onChange={(newValue) => {
                // make sure number is 2 decimal places
                onUpdate({ ...formData, temperature: ( newValue ? parseFloat(newValue.toFixed(2)) : 0 ) });
            }} />

        <Space h="xs" />
        
        <NumberInput
            label="Top P"
            value={formData.top_p || 0}
            min={0}
            max={1}
            step={0.01}
            precision={2}
            hideControls
            onChange={(newValue) => {
                // make sure number is 2 decimal places
                onUpdate({ ...formData, top_p: ( newValue ? parseFloat(newValue.toFixed(2)) : 0 ) });
            }} />

        <Space h="xs" />

        <MultiSelect
            label="Stop Sequence"
            description="Max 4 stop sequences"
            data={[{ value: '', label: 'Enter a sequence', disabled: true }]}
            value={formData.stop && isArrayExists( formData.stop ) ? [ ...formData.stop ] : [] }
            searchable
            creatable
            getCreateLabel={(query) => `Add ${query}`}
            onChange={(newValue) => {
                onUpdate({ ...formData, stop: ( newValue && isArrayExists(newValue) ? newValue : [] ) });
            }}
        />

        { !( formData.mode && formData.mode === 'edits' ) && (
        <>

            <Space h="xs" />

            <NumberInput
                label="Presence penalty"
                value={formData.presence_penalty || 0}
                min={0}
                max={ templateEngine === 'ai21' ? 5 : 2 }
                step={0.01}
                precision={2}
                hideControls
                onChange={(newValue) => {
                    // make sure number is 2 decimal places
                    onUpdate({ ...formData, presence_penalty: ( newValue ? parseFloat(newValue.toFixed(2)) : 0 ) });
                }} />

            <Space h="xs" />

            <NumberInput
                label="Frequency penalty"
                value={formData.frequency_penalty || 0}
                min={0}
                max={ templateEngine === 'ai21' ? 500 : 2 }
                step={ templateEngine === 'ai21' ? 1 : 0.01}
                precision={ templateEngine === 'ai21' ? 0 : 2}
                hideControls
                onChange={(newValue) => {
                    // make sure number is 2 decimal places
                    onUpdate({ 
                        ...formData, 
                        frequency_penalty: templateEngine === 'ai21' ? newValue : ( newValue ? parseFloat(newValue.toFixed(2)) : 0 ) 
                    });
                }} />

            { templateEngine === 'ai21' && (
            <>
                <Space h="xs" />
                <NumberInput
                    label="Count penalty"
                    value={formData.count_penalty || 0}
                    min={0}
                    max={1}
                    step={0.01}
                    precision={2}
                    hideControls
                    onChange={(newValue) => {
                        // make sure number is 2 decimal places
                        onUpdate({ ...formData, count_penalty: ( newValue ? parseFloat(newValue.toFixed(2)) : 0 ) });
                    }} />
            </>
            ) }

        </>
        ) }

    </>
    );
}

const mapStateToProps = state => {
    return {
        authData: ( state.auth && state.auth.user ) ? state.auth.user : null,
        currentTeam: ( state.auth && state.auth.team ) ? state.auth.team : null,
        projectsList: ( state.projects && state.projects.projects ) ? state.projects.projects : null,
    };
};

export default compose(
    connect(mapStateToProps)
)(FormSettings);