

import { 
    GET_PROJECTS,
    GET_PROJECT,
    DELETING_PROJECT,
    RESET_PROJECTS,
    RESET_PROJECT,
} from './types';

import { callFunctionsAPI, getAPIErrorMessage } from '../../../helpers/api';

export const getProjects = () => {
	return dispatch => {

        callFunctionsAPI({
            url: 'project',
            action: 'get_projects',
        })
        .then(list => {
            dispatch({
                type: GET_PROJECTS,
                payload: {
                    list,
                }
            });
        })
        .catch(error => {
            alert(getAPIErrorMessage(error));
            dispatch({
                type: GET_PROJECTS,
                payload: {
                    list: [],
                }
            });
        });

    }
};

export const getProject = (id) => {
	return dispatch => {

        callFunctionsAPI({
            url: 'project',
            action: 'get_project',
            formData: { id }
        })
        .then(project => {
            dispatch({
                type: GET_PROJECT,
                payload: {
                    project,
                }
            });
        })
        .catch(error => {
            dispatch({
                type: GET_PROJECT,
                payload: {
                    project: {},
                }
            });
        });

    }
};

export const deletingProject = (id) => {
    return dispatch => {
        setTimeout(() => {
            dispatch({
                type: DELETING_PROJECT,
                payload: { id }
            });
        },50);
    }
}

export const resetProjects = () => {
    return dispatch => {
        setTimeout(() => {
            dispatch({
                type: RESET_PROJECTS,
            });
        },150);
    }
}

export const resetProject = () => {
    return dispatch => {
        setTimeout(() => {
            dispatch({
                type: RESET_PROJECT,
            });
        },150);
    }
}