import React from 'react';
import { connect } from "react-redux";
import { Box, Grid, Text, Group, Button } from '@mantine/core';

const getThisYear = () => {
    const date = new Date();
    return date.getFullYear();
}

const footerLinks = [
    {
        id: 'terms',
        label: 'Terms of Use',
        link: '/terms-of-use',
    },
    {
        id: 'privacy',
        label: 'Privacy Policy',
        link: '/privacy-policy',
    },
];

const Footer = ({
    authData
}) => {
    return (
    <Box sx={(theme) => ({
        paddingTop: theme.spacing.lg,
        color: theme.colors.gray[5],
        fontSize: theme.fontSizes.sm,
    })}>
        <Grid>
            <Grid.Col span={4}>
                <Group>
                    {footerLinks.map(item => (
                        <Button
                            key={item.id}
                            href={item.link}
                            p="xs"
                            variant="subtile"
                            formTarget='_blank'
                            sx={(theme) => ({
                                border: 'none',
                                backgroundColor: 'transparent',
                                color: theme.colors.gray[5],
                                fontSize: theme.fontSizes.sm,
                                '&:hover': {
                                    color: theme.colors.gray[6],
                                },
                            })}
                        >
                            {item.label}
                        </Button>
                    ))}
                </Group>
            </Grid.Col>
            <Grid.Col span={8}>
                <Text 
                    align='right'
                    sx={(theme) => ({
                        fontSize: theme.fontSizes.sm,
                    })}>
                    Copyright © {getThisYear()} Smashing Advantage. All Rights Reserved
                </Text>
            </Grid.Col>
        </Grid>
    </Box>
    )
}

const mapStateToProps = state => {
    return {
        authData: ( state.auth && state.auth.user ) ? state.auth.user : null,
    };
};

export default connect(mapStateToProps)(Footer);