import React, { useEffect, useState } from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { useDidUpdate } from '@mantine/hooks';
// import { useNavigate } from "react-router-dom";
import { Box, Text, Space } from '@mantine/core';
// import { useModals } from '@mantine/modals';
// import { useNotifications } from '@mantine/notifications';
// import { Pencil2Icon, TrashIcon } from '@radix-ui/react-icons';

import Loader from '../../components/Loader';
import Item from './item';

import { getProjects } from './redux/actions';

const ProjectList = ({
    authData,
    projectsList,
    projectsRand,
    dispatch
}) => {
    // const navigate = useNavigate();
    // const [ list, setList ] = useState([]);
    const [ loaded, setLoaded ] = useState(0);

    useEffect(() => {
        if ( projectsRand ) {
            setLoaded(true);
        }
    },[projectsRand]);

    // useEffect(() => {
    //     dispatch(getProjects());
    // },[]);
    
    // useDidUpdate(() => {
    //     if ( projectsRand && projectsRand !== loaded ) {
    //         setList(projectsList);
    //         setLoaded(projectsRand);
    //     }
    // }, [projectsRand] );

    return loaded ? (
    <Box
        sx={(theme) => ({
            paddingTop: theme.spacing.xl,
            paddingBottom: theme.spacing.xl,
        })}>

        { projectsList && projectsList.length ? 
            projectsList.map(item => (
            <div key={item.id}>
                <Space h="xl" />
                <Item
                    {...item}
                    />
            </div>
            ))
        : <Box sx={(theme) => ({ paddingTop: theme.spacing.lg })}><Text>No project(s) added yet</Text></Box> }
    
    </Box>
    ) : <Loader height="70vh" /> ;
}

const mapStateToProps = state => {
    return {
        authData: ( state.auth && state.auth.user ) ? state.auth.user : null,
        projectsList: ( state.projects && state.projects.projects ) ? state.projects.projects : null,
        projectsRand: ( state.projects && state.projects.rand ) ? state.projects.rand : null,
    };
};

export default compose(
    connect(mapStateToProps)
)(ProjectList);