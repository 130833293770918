
export const WIZARD_TYPES = [
    { 
        id: 'instant_article', 
        label: 'Instant Article Wizard', 
        desc: 'Create complete article in minutes', 
        default: true,
        steps: [
            {
                id: 'topic',
                item: 'Topic',
                label: 'Tell the AI what you want to write about',
                desc: 'Choose a topic that best describes your content',
                type: 'form',
                fields: [
                    {
                        id: 'topic',
                        label: '',
                        type: 'string',
                        required: 'yes',
                        field_type: 'textarea',
                        placeholder: 'Ex: How to become more productive',
                        helper_text: '',
                        error_msg: 'Please insert a topic'
                    }
                ],
                // processing_msg: 'Generating article titles based on the given topic...'
            },
            {
                id: 'title',
                item: 'Article Title',
                label: 'Choose a title for your article',
                desc: '',
                type: 'selector',
                error_msg: 'You must select a valid title for your article',
                processing_msg: 'Generating article titles based on the given topic...'
            },
            {
                id: 'brief',
                item: 'Article Description',
                label: 'Choose a brief description for your article',
                desc: '',
                type: 'selector',
                processing_msg: 'Generating article description...'
            },
            {
                id: 'outline',
                item: 'Article Outline',
                label: 'Choose an outline for your article',
                desc: '',
                type: 'selector',
                processing_msg: 'generating article outline...'
            },
            {
                id: 'review',
                label: 'Review Your Article Brief',
                desc: '',
                type: 'review',
                submit_label: 'Submit Article Brief',
                processing_msg: 'Compiling article brief...'
            },
            {
                id: 'generation',
                label: 'Article Generation',
                desc: '',
                type: 'generation',
                processing_msg: 'Preparing for article generation...'
            }
        ] 
    },
    // { 
    //     id: 'instant_email', 
    //     label: 'Instant Email', 
    //     desc: 'Create complete email in minutes', 
    //     default: false 
    // },
    { 
        id: 'batch_article', 
        label: 'Batch Article Wizard', 
        desc: 'Create multiple articles in minutes', 
        default: false,
        steps: [
            {
                id: 'titles',
                item: 'Article Title(s)',
                label: 'Tell AI the titles of your articles',
                desc: 'You can insert up to 10 titles',
                type: 'form',
                fields: [
                    {
                        id: 'titles',
                        label: 'Article Title(s):',
                        type: 'string',
                        required: 'yes',
                        field_type: 'textarea',
                        minRows: 10,
                        maxRows: 10,
                        placeholder: `Ex: 
How to become more productive
How to reduce stress
How to read better`,
                        helper_text: 'One article title per line',
                        error_msg: 'Please insert at least one article title'
                    },
                ],
                // processing_msg: ''
            },
            {
                id: 'model',
                item: 'AI Model',
                label: 'Select AI Model',
                desc: 'Pick a model you want to use',
                type: 'form',
                fields: [
                    {
                        id: 'model',
                        label: '',
                        type: 'string',
                        required: 'yes',
                        field_type: 'model_selector',
                        helper_text: '',
                        error_msg: 'Please select an AI model'
                    },
                    {
                        id: 'stop',
                        label: '(optional) Custom Stop Sequence',
                        type: 'array',
                        required: 'no',
                        field_type: 'stop_sequence_selector',
                        helper_text: 'Max 4 stop sequences',
                        // error_msg: ''
                    }
                ],
                // processing_msg: ''
            },
            {
                id: 'review',
                label: 'Review Your Settings',
                desc: '',
                type: 'review',
                submit_label: 'Submit Settings',
                processing_msg: 'Processing...'
            },
            {
                id: 'generation',
                label: 'Article Generation',
                desc: '',
                type: 'generation',
                processing_msg: 'Preparing for article generation...'
            }
        ] 
    },
    { 
        id: 'article_body_content', 
        label: 'Article Body Content Wizard', 
        desc: 'Create body content for your article', 
        default: false,
        steps: [
            {
                id: 'subheading',
                item: 'Subheading',
                label: 'Enter your subheading',
                type: 'form',
                fields: [
                    {
                        id: 'subheading',
                        label: 'Subheading',
                        type: 'string',
                        required: 'yes',
                        field_type: 'text',
                        error_msg: 'Please insert your subheading'
                    },
                ],
                // processing_msg: ''
            },
            {
                id: 'title',
                item: 'Article Title',
                label: 'Enter your article title',
                type: 'form',
                fields: [
                    {
                        id: 'title',
                        label: 'Article Title',
                        type: 'string',
                        required: 'yes',
                        field_type: 'text',
                        error_msg: 'Please insert your article title'
                    },
                ],
                // processing_msg: ''
            },
            {
                id: 'outlines',
                item: 'Article Outlines',
                label: 'Enter your article outlines',
                type: 'form',
                fields: [
                    {
                        id: 'outlines',
                        label: 'Article Outlines',
                        type: 'string',
                        required: 'yes',
                        field_type: 'textarea',
                        minRows: 10,
                        maxRows: 10,
                        error_msg: 'Please insert your article outlines'
                    }
                ],
                // processing_msg: ''
            },
            {
                id: 'review',
                label: 'Review Your Article Brief',
                desc: '',
                type: 'review',
                submit_label: 'Submit article brief',
                processing_msg: 'Processing...'
            },
            {
                id: 'generation',
                label: 'Body Content Generation',
                desc: '',
                type: 'generation',
                processing_msg: 'Preparing for body content generation...'
            }
        ] 
    },
];