import { useEffect, useState } from 'react';
// import { useDidUpdate } from '@mantine/hooks';
import { useSelector, useDispatch } from "react-redux";
import { getFirestore, onSnapshot, doc } from "firebase/firestore";
import { getDatabase, ref, onValue, off, orderByChild, equalTo, query } from "firebase/database";

import { 
    GET_TEMPLATES,
    GET_TEMPLATE,
    // GET_TEMPLATE_OPTIONS
} from './types';

import { resetTemplates, resetTemplate, 
    // resetTemplateOptions 
    } from './actions';
// import { callFunctionsAPI } from '../../../helpers/api';
import { isArrayExists } from '../../../helpers/validation';
import { getUserActiveTeam } from '../../../helpers/auth';

export const useTemplates = () => {
    const db = getDatabase();
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.auth);
    const [ loaded, setLoaded ] = useState(false);
    const [ list, setList ] = useState([]);

    useEffect(() => {
        const Ref = query( ref(db, 'templates'), orderByChild("team_id"), equalTo(getUserActiveTeam(user)) );
        onValue(Ref, (snapshot) => {
            let list = [];
            if ( snapshot.exists() && snapshot.hasChildren() ) {
                snapshot.forEach((childSnapshot) => {
                    let item = childSnapshot.val();
                    item.id = childSnapshot.key;
                    // for projects
                    if ( childSnapshot.child("projects").exists() && childSnapshot.child("projects").hasChildren() ) {
                        item.projects = [];
                        childSnapshot.child("projects").forEach((projectSnapshot) => {
                            item.projects.push({ id: projectSnapshot.key });
                        });
                    } 
                    // for inputs
                    if ( childSnapshot.child("inputs").exists() && childSnapshot.child("inputs").hasChildren() ) {
                        item.inputs = [];
                        childSnapshot.child("inputs").forEach((inputSnapshot) => {
                            item.inputs.push({ ...inputSnapshot.val() });
                        });
                    }
                    // for access
                    if ( childSnapshot.child("access").exists() && childSnapshot.child("access").hasChildren() ) {
                        item.access = [];
                        childSnapshot.child("access").forEach((accessSnapshot) => {
                            item.access.push({ ...accessSnapshot.val() });
                        });
                    }
                    list.push(item);
                });
            }

            // sort by modified_on
            if ( isArrayExists( list ) ) {
                list = list.sort((a, b) => {
                    if ( a.modified_on._seconds && b.modified_on._seconds && a.modified_on._seconds < b.modified_on._seconds ) {
                        return 1;
                    }
                    if ( a.modified_on._seconds && b.modified_on._seconds && a.modified_on._seconds > b.modified_on._seconds ) {
                        return -1;
                    }
                    return 0;
                });
            }

            dispatch({
                type: GET_TEMPLATES,
                // payload: { list: list.sort((a, b) => {
                //     return a.name.localeCompare(b.name);
                // }) }
                payload: { list }
            });
            setList(list);
            setLoaded(true);
        }, (error) => {
            setList([]);
            setLoaded(true);
            dispatch({
                type: GET_TEMPLATES,
                payload: { list: [] }
            });
        });
        return () => {
            setLoaded(false);
            setList([]);
            off(Ref);
            dispatch(resetTemplates());
        }
    },[ user ]);

    return [ loaded, list ];
}

export const useTemplate = ({ 
    template_id = 'noid',
}) => {
    const db = getFirestore();
    const dispatch = useDispatch();
    const [ loaded, setLoaded ] = useState(false);
    const [ data, setData ] = useState({});

    useEffect(() => {
        let unsubscribe;
        setLoaded(false);
        unsubscribe = onSnapshot(doc(db, "templates", template_id ), (doc) => {
            const val = ( doc.exists ? doc.data() : {} );
            dispatch({
                type: GET_TEMPLATE,
                payload: {
                    template: val
                }
            });
            setData({ ...val });
            setLoaded(true);
        });

        return () => {
            unsubscribe();
            dispatch(resetTemplate());
        }
    },[ template_id ]);

    return [ loaded, data ];
}

export const useTemplateOptions = ({ 
    project_id = '',
}) => {
    const { templates } = useSelector((state) => state.templates);
    const [ list, setList ] = useState([]);

    useEffect(() => {
        if ( templates && isArrayExists( templates ) ) {
            setList( templates.filter( template => template.projects && isArrayExists( template.projects ) && template.projects.find( p => p.id === 'all' || p.id === project_id ) ) );
        }
        // return () => {
        //     dispatch(resetTemplateOptions());
        // }
    },[ project_id, templates ]);

    return list;
}