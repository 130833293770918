import React, { useState, useMemo, useEffect } from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
// import { useNavigate } from "react-router-dom";
import { Button, Title, Box, Center, Paper, Text, TextInput, Space } from '@mantine/core';
import { DoubleArrowRightIcon, ExitIcon } from '@radix-ui/react-icons';
import { useNotifications } from '@mantine/notifications';

import { getAPIErrorMessage } from '../../helpers/api';

import { googleSignIn, activateUser } from './api';
import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_GET_PROFILE_IMAGE } from '../../redux/auth/types';
import { onAuth, logoutUser } from '../../redux/auth/api';

import { PLATFORM_NAME } from '../../constants';

const Login = ({
    authData,
    dispatch
}) => {
    const notifications = useNotifications();
    const [ loading, setLoading ] = useState(false);
    const [ loggingOut, setLoggingOut ] = useState(false);
    const [ inviteCode, setInviteCode ] = useState('');

    useEffect(() => {
        setLoading(false);
    }, [ authData ]);

    const disabled = useMemo(() => {
        return ( loggingOut || loading ? true : false );
    }, [ loggingOut, loading ]);

    const handleInviteSubmit = () => {
        let error = false;
        // do error check
        if ( !( inviteCode && inviteCode.length ) ) {
            error = "Please insert a valid invitation code.";
        }

        if ( error ) {
            notifications.showNotification({
                color: 'red',
                title: error
            });
        } else {
            // do submit
            setLoading(true);
            activateUser({ invite_code: inviteCode })
            .then(() => {
                return onAuth();
            })
            .then(data => {
                dispatch({
                    type: AUTH_LOGIN,
                    payload: data
                });
            })
            .catch(error => {
                setLoading(false);
                notifications.showNotification({
                    color: 'red',
                    title: getAPIErrorMessage(error),
                })
            });
        }
    }

    const handleLogout = () => {
        setLoggingOut(true);
        logoutUser()
        .then(() => {
            setLoggingOut(false);
            dispatch({ type: AUTH_LOGOUT });
        })
        .catch(error => {
            setLoggingOut(false);
            notifications.showNotification({
                color: 'red',
                title: getAPIErrorMessage(error),
            })
        });
    }

    const handleLogin = () => {
        setLoading(true);
        googleSignIn()
        .then(({ data, userObj }) => {
            dispatch({
                type: AUTH_LOGIN,
                payload: data
            });
            dispatch({
                type: AUTH_GET_PROFILE_IMAGE,
                payload: userObj && userObj.photoURL || null
            });
        })
        .catch(error => {
            setLoading(false);
            notifications.showNotification({
                color: 'red',
                title: getAPIErrorMessage(error),
            })
        });
    }

    return (
    <Box sx={(theme) => ({
        height: '100%',
        minHeight: '100vh',
        backgroundColor: theme.colors.brand[0],
    })}>
        <Center style={{ width: '100%', height: '100%', minHeight: '100vh', padding: '60px 0 30px 0' }}>
            <Paper 
                shadow="lg" 
                radius={"lg"}
                p="xl"
                sx={(theme) => ({
                    width: '100%',
                    maxWidth: '400px',
                    background: ( authData ? theme.colors.gray[0] : 'none' ),
                    marginTop: '-60px',
                })}>

                <Title order={1} align='center' sx={(theme) => ({
                    fontSize: '24px',
                    color: ( authData ? theme.colors.dark[8] : theme.colors.gray[0] ),
                })}>{PLATFORM_NAME}</Title>

                {/* if already logged in, show logout button */}
                { authData && (
                <Box sx={(theme) => ({
                    padding: '20px 0',
                })}>

                    { authData.status && authData.status === 'disabled' && (
                    <Box sx={(theme) => ({
                        marginBottom: theme.spacing.lg,
                        padding: theme.spacing.lg,
                        border: '2px solid' + theme.colors.dark[5],
                        borderRadius: theme.radius.md,
                        backgroundColor: theme.colors.gray[2]
                    })}>
                        <Text size={"xl"} weight="500">We are Still in Private Beta Mode</Text>
                        <Text size={"sm"}>If you have an invitation code, please enter it here:</Text>
                        <Space h="md" />
                        <TextInput
                            label="Invitation Code"
                            value={inviteCode}
                            required
                            disabled={disabled}
                            onChange={(event) => setInviteCode(event.target.value)} />
                        <Space h="sm" />
                        <Button
                            color="indigo"
                            size="sm"
                            disabled={disabled}
                            loading={loading}
                            // leftIcon={ loading ? <Loader size={20} /> : null }
                            onClick={handleInviteSubmit}>
                            Submit        
                        </Button>
                    </Box>   
                    ) }

                    <Button
                        color="gray"
                        size="lg"
                        radius={"25px"}
                        fullWidth
                        uppercase
                        disabled={disabled}
                        loading={loggingOut}
                        // leftIcon={ loggingOut ? <Loader size={20} /> : null }
                        // rightIcon={( loading ? null : <ExitIcon /> )}
                        rightIcon={<ExitIcon />}
                        sx={(theme) => ({
                            fontSize: '0.85rem'
                        })}
                        onClick={handleLogout}
                        >
                        Logout
                    </Button>
                </Box>
                )}

                {/* if not logged in, show login button */}
                { !authData &&
                (
                <Box sx={(theme) => ({
                    padding: '20px 0',
                })}>
                    <Button
                        color="indigo"
                        size="lg"
                        radius={"25px"}
                        fullWidth
                        uppercase
                        disabled={disabled}
                        // leftIcon={ loading ? <Loader size={20} /> : null }
                        loading={loading}
                        // rightIcon={( loading ? null : <DoubleArrowRightIcon /> )}
                        rightIcon={<DoubleArrowRightIcon />}
                        sx={(theme) => ({
                            fontSize: '0.85rem'
                        })}
                        onClick={handleLogin}
                        >
                        Continue with Google Account
                    </Button>
                </Box>
                ) }

            </Paper>
        </Center>
    </Box>
    )
}

const mapStateToProps = state => {
    return {
        authData: ( state.auth && state.auth.user ) ? state.auth.user : null,
    };
};

export default compose(
    connect(mapStateToProps)
)(Login);