// import { useState } from 'react';
import { Card, Loader, Box } from '@mantine/core';

import ChatBubble from '../ChatBubble';

const ChatBox = ({
    messages = [],
    onUpdate = () => {},
}) => {
    return (
    <Box
        sx={(theme) => ({
            padding: theme.spacing.md,
            paddingRight: theme.spacing.xl,
        })}
        >
        {messages.map((message) => {
            return (
            <ChatBubble
                key={message.id}
                message={message}
                onEdit={(text) => {
                    const newMessages = messages.map((message) => {
                        if ( message.id === message.id ) {
                            message.content = text;
                        }
                        return message;
                    });
                    onUpdate(newMessages);
                }}
                />
            )
        })}

    </Box>
    )
}

export default ChatBox;