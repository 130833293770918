import React, { useMemo } from 'react';
// import { useParams } from "react-router-dom";
import { Space, Text, Group, Avatar, Box, ScrollArea } from '@mantine/core';
// import { useClipboard } from '@mantine/hooks';
// import { PlusCircledIcon, TrashIcon, CopyIcon, Pencil1Icon } from '@radix-ui/react-icons';

import MultilineText from '../../components/MultilineText';

import { isArrayExists } from '../../helpers/validation';

// import { getWizardTypeData } from './helpers';

const StepReview = ({
    document = false,
    wizard = false,
    currentStep = 0,
    step = false,
    disabled = false,
    steps = [],
    contents = [],
    onUpdate = () => {},
}) => {

    const previousSteps = useMemo(() => {
        return steps.filter((step,index) => index < currentStep);
    }, [ currentStep, steps ] );

    const getContent = (step) => {
        const content = contents.find((content) => content.id === step.id);
        switch( step.type ) {
            case 'form':
                if ( step.fields && isArrayExists( step.fields ) ) {
                    if ( step.fields.length === 1 ) {
                        return <MultilineText 
                        text={( content && content.formdata && step.fields[0] && step.fields[0].id && content.formdata[step.fields[0].id] ? content.formdata[step.fields[0].id] : '' )} 
                        textProps={{ size: 'sm', component: 'p' }} />
                    } else {
                        return <MultilineText 
                        text={( content && content.formdata && step.fields && isArrayExists( step.fields ) ? step.fields.map((field) => {
                            return ( field.label ? field.label+': ' : '' )+content.formdata[field.id];
                        }).join('\n ') : '' )} 
                        textProps={{ size: 'sm', component: 'p' }} />
                    }
                } else {
                    return null;
                }
            default:
                return <MultilineText 
                    text={( content && content.formdata && content.formdata.text ? content.formdata.text : '' )} 
                    textProps={{ size: 'sm', component: 'p' }} />;
        }
    }

    return (
    <>

        <Group>
            <Avatar color="indigo" radius={"md"}><Text size="md">{currentStep+1}</Text></Avatar>
            <Text size="lg" weight="500">{step.label}</Text>
        </Group>

        <Space h="lg" />


        { previousSteps && isArrayExists( previousSteps ) && (
        <ScrollArea
            sx={(theme) => ({
                height: 'calc( 75vh - 220px )',
                borderRadius: theme.radius.md,
                border: '2px solid ' + theme.colors.yellow[5], 
                background: theme.colors.yellow[0],
            })}>
            <Box
                sx={(theme) => ({
                    // borderBottom: '1px solid ' + theme.colors.gray[5],
                    padding: theme.spacing.md,

                })}>
                { previousSteps.map((step,index) => (
                <Box
                    key={step.id}
                    sx={(theme) => ({
                        // borderTop: '1px solid ' + theme.colors.gray[5],
                        padding: '5px 5px 10px 5px',
                    })}>
                    <Text size="md" weight="700">{step.item}:</Text>
                    {getContent(step)}
                </Box>  
                ))}
            </Box>
        </ScrollArea>
        ) }
    

    </>
    );
}

export default StepReview;