import React, { useEffect, useState, useMemo } from 'react';
import { useSelector } from "react-redux";
import { Card, Skeleton, Title, Space, Box, Text, Group } from '@mantine/core';
// import { getDatabase, ref, query, onValue, off } from "firebase/database";

import { getFormattedCredits } from '../../helpers/number';


const WidgetCreditStats = () => {
    const tokens = useSelector(state => state.tokens.tokens);
    const loaded = useSelector(state => state.tokens.loaded);

    const formattedTokens = useMemo(() => {
        // format credits number
        return getFormattedCredits(tokens);
    },[ tokens ]);

    return (
    <Card
        id="dashboard-widget-credits"
        p="md"
        withBorder
        shadow={"sm"}>
        <Title order={4}>Token Balance:</Title>
        <Space h="sm" />
        { loaded ? (
        <Group>
            <i className="ri-copper-coin-line"></i>
            <Text size="lg" weight={700} color="indigo">{formattedTokens}</Text>
        </Group>   
        ) : (
        <Box>
            <Skeleton width="100%" height={8} />
            <Skeleton width="80%" height={8} mt={6} />
            <Skeleton width="60%" height={8} mt={6} />
        </Box>
        ) }
    </Card>
    )
}

export default WidgetCreditStats;