import React, { useMemo, useState } from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
// import { useParams } from "react-router-dom";
import { Grid, Card, Button, Group, Space } from '@mantine/core';
// import { useDidUpdate } from '@mantine/hooks';
import { useNotifications } from '@mantine/notifications';
import { ArrowRightIcon } from '@radix-ui/react-icons';

import Results from './results';
import StepForm from './step_form';
import StepSelector from './step_selector';
import StepReview from './step_review';
import StepGeneration from './step_generation';
import StepImprove from './step_improve';
import { getWizardStep, getWizardPreviousStep, getWizardPreviousStepIndex, getWizardSelectedContent, reorganizeWizardResults } from './helpers';

import { isArrayExists, isObjectExists } from '../../helpers/validation';
import { callFunctionsAPI, getAPIErrorMessage } from '../../helpers/api';

import { toggleGlobalDisabled } from '../../redux/global/actions';


const getStepID = (step, nextStep, move) => {
    if ( move ) {
        return ( nextStep && nextStep.id ? nextStep.id : false );
    } else {
        return ( step && step.id ? step.id : false );
    }
}

const getStepSelection = ( step_id, wizard = {}, contents = [], key ) => {
    const previousStep = getWizardPreviousStep( wizard.id, step_id ),
        previousIndex = getWizardPreviousStepIndex( wizard.id, step_id ),
        selected = previousIndex >= 0 ? ( contents[previousIndex] && contents[previousIndex].formdata ? contents[previousIndex].formdata : false ) : false ;
    let returnData = '';
    switch( previousStep.type ) {
        case 'form':
            if ( previousStep.fields && isArrayExists( previousStep.fields ) ) {
                if ( previousStep.fields.length > 1 ) {
                    // if have multiple fields, return it as object
                    if ( key === 'id' ) {
                        returnData = previousStep.id;
                    } else {
                        returnData = {};
                        previousStep.fields.forEach(field => {
                            returnData[field.id] = selected[field.id] ? selected[field.id] : '';
                        });
                    } 
                } else {
                    if ( key === 'id' ) {
                        returnData = ( previousStep.fields[0] && previousStep.fields[0].id && selected[ previousStep.fields[0].id ] ? previousStep.fields[0].id : '' );
                    } else {
                        returnData = ( selected && previousStep.fields[0] && previousStep.fields[0].id && selected[ previousStep.fields[0].id ] ? selected[ previousStep.fields[0].id ] : '' );
                    }   
                }
            } // end - previousStep.fields
            break;
        case 'selector':
            if ( key === 'id' ) {
                returnData = ( selected && selected.id ? selected.id : '' );
            } else {
                returnData = ( selected && selected.text ? selected.text : '' );
            }
            break;
        case 'review':
            // return all the selections

            break;
        case 'generation':

            break;
        default:
            break;
    }
    return returnData;
}

const Step = ({
    globalDisabled,
    dispatch,
    editorRef = null,
    wizardModel = false,
    contents = [],
    document,
    wizardData = false,
    wizard = false,
    currentStep = 0,
    onStepUpdate = () => {},
    onUpdate = () => {},
    onWizardUpdate = () => {},
    onWizardClose = () => {}
}) => {
    const notifications = useNotifications();
    const [ loading, setLoading ] = useState(false);
    const [ improveResult, setImproveResult ] = useState({});

    const step = useMemo(() => {
        return ( wizard && wizard.steps && wizard.steps.length ? wizard.steps[currentStep] : false );
    }, [ wizard, currentStep ]);

    const nextStep = useMemo(() => {
        return ( wizard && wizard.steps && isArrayExists( wizard.steps ) ? wizard.steps[currentStep+1] : false )
    }, [ wizard, currentStep ]);

    const formData = useMemo(() => {
        const selected = ( contents && isArrayExists( contents ) ? contents.find( ( item ) => item.id === step.id ) : false );
        return ( selected && selected.formdata ? selected.formdata : {} );
    }, [ contents, step ]);

    const results = useMemo(() => {
        const selected = ( contents && isArrayExists( contents ) ? contents.find( ( item ) => item.id === step.id ) : false );
        return ( selected && selected.results ? selected.results : [] );
    }, [ contents, step ]);

    const status = useMemo(() => {
        return ( wizardData && wizardData.status ? wizardData.status : 'setup' );
    }, [ wizardData ] );

    const handleUpdate = (newValue,key,step_id = null ) => {
        const newContents = [ ...contents ];
        const index = newContents.findIndex( ( item ) => ( step_id && step_id === item.id ) ? true : ( !step_id && item.id === step.id ) );
        if ( index > -1 ) {
            newContents[index][key] = newValue;
        } else {
            const newItem = {
                id: ( step_id || step.id ),
                formdata: {},
                results: []
            };
            newContents.push({
                ...newItem,
                [key]: newValue
            });
        }      
        onUpdate( newContents );
    }

    const handleNext = () => {
        let wizardDataFormData = getWizardSelectedContent( wizardData, step ),
            error = false,
            changes = false;
        // do error check
        switch( step.type ) {
            case 'form':
                if ( step.fields && isArrayExists( step.fields ) ) {
                    step.fields.forEach(field => {
                        if ( field.required && field.required === 'yes' && !formData[field.id] ) {
                            error = field.error_msg || 'This field is required';
                        }
                    });
                }
                break;
            case 'selector':
                if ( !( formData && formData.text && formData.text.length ) ) {
                    error = step.error_msg || 'Please select an option';
                }
                break;
            default:
                break;
        }

        // compare and check if there are any changes between wizardDataFormData & formData
        if ( wizardDataFormData && isObjectExists( wizardDataFormData ) ) {
            for ( let key in wizardDataFormData ) {
                if ( wizardDataFormData.hasOwnProperty(key) ) {
                    if ( wizardDataFormData[key] !== formData[key] ) {
                        changes = true;
                        break;
                    }
                }
            }
        }

        if ( error ) {
            notifications.showNotification({
                message: error,
                color: 'red'
            })
        } else {
            // check if contains results or not, and make sure formData didn't change
            if ( contents && isArrayExists( contents ) && contents.find( ( item ) => nextStep && item.id === nextStep.id && item.results && isArrayExists( item.results ) ) && !changes ) {
                // if contains results, then just forward to next step
                onStepUpdate(currentStep + 1);
            } else {
                triggerSuggestions(true);
            }
        }
        
    }

    const triggerSuggestions = (move) => {
        const step_id = getStepID(step,nextStep,move,wizard);
        const selectedStep = getWizardStep(wizard.id,step_id);
        dispatch(toggleGlobalDisabled(true));

        if ( move ) {
            setLoading(true);
        }

        if ( selectedStep && selectedStep.processing_msg ) {
            notifications.showNotification({
                loading: true,
                disallowClose: true,
                title: selectedStep.processing_msg,
                autoClose: false
            });
        }

        callFunctionsAPI({
            url: 'wizard',
            action: wizard.id,
            formData: {
                model: wizardData && wizardData.id ? false : wizardModel,
                wizard_id: wizardData && wizardData.id ? wizardData.id : false,
                document_id: document.id,
                step_id,
                step_selection: getStepSelection( step_id, wizard, contents, 'value' ), 
                step_selection_id: getStepSelection( step_id, wizard, contents, 'id' )
            }
        })
        .then( data => {
            // update wizard ID
            onWizardUpdate(data.id);

            // unload everything
            dispatch(toggleGlobalDisabled(false));

            if ( move ) {
                onStepUpdate(currentStep + 1);
                setLoading(false);
            }

            if ( selectedStep && selectedStep.processing_msg ) {
                notifications.clean();
            }

        })
        .catch( error => {
            dispatch(toggleGlobalDisabled(false));
            if ( move ) {
                setLoading(false);
            }
            if ( selectedStep && selectedStep.processing_msg ) {
                notifications.clean();
            }
            notifications.showNotification({
                message: getAPIErrorMessage( error ),
                color: 'red'
            })
        });
    }

    const stepType = useMemo(() => {
        return ( step && step.type ? step.type : false );
    },[step]);

    const stepSubmitLabel = useMemo(() => {
        switch( step.type ) {
            case 'review':
                return ( step.submit_label ? step.submit_label : 'Submit' );
            default:
                return 'Next';
        }
    }, [ step ] );

    const showStepNavButtons = useMemo(() => {
        switch( stepType ) {
            case 'generation':
                return false;
            default:
                return true;
        }
    }, [ stepType ]);

    const stepComponent = useMemo(() => {
        switch(stepType) {
            case 'form':
                return <StepForm
                    document={document}
                    wizard={wizard}
                    currentStep={currentStep}
                    step={step}
                    disabled={globalDisabled}
                    formData={formData}
                    onUpdate={(newValue) => handleUpdate(newValue,'formdata')} />;
            case 'selector':
                return <StepSelector
                    document={document}
                    wizard={wizard}
                    currentStep={currentStep}
                    step={step}
                    results={results}
                    selected={formData}
                    onUnselect={() => handleUpdate({},'formdata')}
                    onUpdate={(newValue) => handleUpdate(newValue,'formdata')} />;
            case 'review':
                return <StepReview
                    document={document}
                    wizard={wizard}
                    currentStep={currentStep}
                    step={step}
                    steps={wizard && wizard.steps ? wizard.steps : []}
                    contents={contents}
                    disabled={globalDisabled}
                    formData={formData} />;
            case 'generation':
                return <StepGeneration
                    editorRef={editorRef}
                    document={document}
                    wizard={wizard}
                    currentStep={currentStep}
                    step={step}
                    steps={wizard && wizard.steps ? wizard.steps : []}
                    contents={contents}
                    disabled={globalDisabled}
                    formData={formData}
                    improveResult={improveResult}
                    onImproveResult={(result) => setImproveResult(result)}
                    onWizardClose={onWizardClose} />;
            default:
                return null;
        }
    }, [ stepType, formData, improveResult ]); 

    const selectedImproveResult = useMemo(() => {
        const reorganizedResults = reorganizeWizardResults( ( wizardData && wizardData.results ) || [] );
        return reorganizedResults.find( ( item ) => item.id === improveResult.id );
    }, [ wizardData, improveResult ]);

    return (
    <Grid 
        gutter="lg"
        sx={(theme) => ({
            height: '100%',
            flexDirection: ( stepType && stepType === 'selector' ? 'row-reverse' : 'row' ),
        })}>
        <Grid.Col
            xs={12} md={ stepType && stepType === 'generation' ? ( status === 'complete' ? 7 : 12 ) : 6 }>
            <Card
                shadow={"md"}
                radius="md"
                p="lg"
                // pb="70px"
                sx={(theme) => ({
                    // height: '100%',
                    margin: '10px',
                })}>

                {stepComponent}

                { showStepNavButtons && (
                <>
                    <Space h="sm" />
                    <Group 
                        position='apart'
                        sx={(theme) => ({
                            paddingTop: '30px'
                        })}>
                        <Button 
                            variant="light" 
                            color="gray"
                            disabled={currentStep === 0 || globalDisabled}
                            onClick={() => onStepUpdate(currentStep-1)}
                            >
                            Back
                        </Button>
                        <Button 
                            color="indigo"
                            disabled={ globalDisabled || ( stepType === 'selector' && !( formData && isObjectExists( formData ) ) ) }
                            loading={loading}
                            onClick={handleNext}
                            rightIcon={<ArrowRightIcon />}
                            >
                            {stepSubmitLabel}
                        </Button>
                    </Group>
                </>
                )}
                
            </Card>
        </Grid.Col>

        { stepType && stepType === 'selector' ? (
        <Grid.Col xs={12} md={6}>
            <Results
                document={document}
                wizard={wizard}
                step={step}
                results={results}
                selected={formData}
                currentStep={currentStep}
                onSuggestionTrigger={() => triggerSuggestions(false)}
                onSelect={(result) => handleUpdate(result,'formdata')}
                onDelete={(newResults) => handleUpdate(newResults,'results')} />
        </Grid.Col>
        ) : null }

        { status && status === 'complete' ? (
        <Grid.Col xs={12} md={5}>
            { selectedImproveResult && isObjectExists( selectedImproveResult ) && (
                <StepImprove
                    document={document}
                    wizard={wizard}
                    wizardData={wizardData}
                    selected={selectedImproveResult}
                    onUpdate={() => { /* donothing now */ }}
                    onUnselect={() => setImproveResult({})} />
            ) }
        </Grid.Col>
        ) : null }

    </Grid>
    );
}

const mapStateToProps = state => {
    return {
        authData: ( state.auth && state.auth.user ) ? state.auth.user : null,
        globalDisabled: ( state.global && state.global.disabled ) ? state.global.disabled : false,
        wizardData: ( state.wizards && state.wizards.wizard ) ? state.wizards.wizard : null,
    };
};

export default compose(
    connect(mapStateToProps)
)(Step);