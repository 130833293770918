import React, { useRef, useEffect, useState, useMemo } from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
// import { useParams } from "react-router-dom";
import { useMantineTheme, Space, Text, Group, Avatar, Card, ScrollArea, Notification, Box, Grid, Transition, Loader, Center, Progress, Button, Menu } from '@mantine/core';
import { useClipboard } from '@mantine/hooks';
import { useNotifications } from '@mantine/notifications';
import { useModals } from '@mantine/modals';
import { CheckIcon, CopyIcon, MagicWandIcon, PlusCircledIcon, UploadIcon } from '@radix-ui/react-icons';

import ButtonIconCircle from '../../components/ButtonIconCircle';
import MultilineText from '../../components/MultilineText';

import { isArrayExists } from '../../helpers/validation';

import { reorganizeWizardResults, getTotalWizardResults } from './helpers';

const Result = ({
    item,
    status = 'setup',
    disabled = false,
    selected = {},
    onImprove = () => {},
}) => {
    const theme = useMantineTheme();
    const clipboard = useClipboard({ timeout: 1000 });
    const [ opened , setOpened] = useState(true);

    const isSelected = useMemo(() => {
        return selected && selected.id === item.id;
    }, [ item, selected ] );

    useEffect(() => {
        let mounted = true;
        if ( mounted ) {
            setTimeout(() => {
                setOpened(true);
            },500);
        }
        return () => {
            mounted = false;
        }
    }, [] );

    const handleImprove = () => {
        onImprove(item);
    }

    return (
    <Transition 
        mounted={opened} 
        transition={"pop"} 
        duration={400} 
        timingFunction="ease">
        {(styles) => (
        <Box
            style={{ ...styles }}
            sx={(theme) => ({
                paddingTop: theme.spacing.md,
                paddingBottom: theme.spacing.md,
            })}>
            <Space h="xs" />
            <Grid 
                gutter={40} 
                >
                <Grid.Col xs={12} sm={3}>
                    <Space h="xs" />
                    <Text size="lg" weight={"700"} color="dimmed" align='right'>{item.label}</Text>
                </Grid.Col>
                <Grid.Col xs={12} sm={9}>
                    <Card
                        shadow={"xs"}
                        sx={(theme) => ({
                            border: '1px solid ' + ( isSelected ? theme.colors.indigo[5] : theme.colors.gray[3] ),
                            backgroundColor: isSelected ? theme.colors.indigo[0] : theme.colors.white[0],
                        })}>
                        <Card.Section
                            sx={(theme) => ({
                                padding: '5px 10px',
                            })}>
                            <Group 
                                spacing={"3px"}
                                position='right'>
                                <ButtonIconCircle
                                    icon={<CopyIcon />}
                                    label={ clipboard.copied ? "Copied" : "Copy" }
                                    useClipboard={true}
                                    btnProps={{
                                        variant: "light",
                                        color: 'gray',
                                        radius: 'md',
                                        size: 'xs'
                                    }}
                                    color={theme.colors.lime[8]}
                                    onClick={() => clipboard.copy( ( item.heading ? item.heading : '' ) + ( item.heading && item.content ? '\n\n' : '' ) + ( item.content || '' ))}
                                />
                                { status && status === 'complete' && item.can_improve && <ButtonIconCircle
                                    icon={<MagicWandIcon />}
                                    label={"Improve"}
                                    btnProps={{
                                        variant: ( isSelected ? 'filled' : 'light' ),
                                        color: ( isSelected ? 'indigo' : 'gray' ),
                                        radius: 'md',
                                        size: 'xs',
                                        disabled,
                                    }}
                                    color={theme.colors.indigo[8]}
                                    onClick={handleImprove}
                                /> }
                            </Group>
                        </Card.Section>
                        { item.heading && (
                        <>
                            <Text size="md" weight={"700"}>{item.heading}</Text>
                            { item.heading && item.content && <Space h="sm" /> }
                        </>
                        ) }
                        { item.content && <MultilineText text={item.content} textProps={{ component: 'p', size: 'sm' }} /> }
                    </Card>
                </Grid.Col>
            </Grid>
        </Box>
        )}
      </Transition>
    )
}

const StepGeneration = ({
    editorRef = null,
    document = false,
    wizard = false,
    wizardData = {},
    currentStep = 0,
    step = false,
    globalDisabled = false,
    contents = [],
    improveResult = {},
    onImproveResult = () => {},
    onWizardClose = () => {}
}) => {
    const viewport = useRef();
    const clipboard = useClipboard({ timeout: 1000 });
    const notifications = useNotifications();
    const modals = useModals();
    const [ results, setResults ] = useState([]);

    const status = useMemo(() => {
        return wizardData && wizardData.status ? wizardData.status : 'setup';
    }, [ wizardData ]);

    const disabled = useMemo(() => {
        return globalDisabled ? true : false;
    },[ globalDisabled ]);

    useEffect(() => {
        if ( wizardData && wizardData.results && isArrayExists( wizardData.results ) ) {
            let newResults = [];
            // update results, but only put it in if status is ready
            reorganizeWizardResults(wizardData.results).forEach(result => {
                if ( result.ready ) {
                    newResults.push(result);
                }
            })
            setResults( newResults );
        }
    },[ wizardData ]);

    const percentage = useMemo(() => {
        let totalCount = ( wizardData && wizardData.results && isArrayExists( wizardData.results ) ? getTotalWizardResults( wizardData.results ) : 0 );
        return Math.ceil(( ( results.length / totalCount ) * 100 ));
    },[ results, wizardData ]);

    const getContent = ( type = 'text' ) => {
        let content = '';
        results.forEach(result => {
            switch( result.type ) {
                case 'title':
                    if ( type === 'html' ) {
                        // content += '<h2>' + result.heading + '</h2>';
                    } else {
                        content += result.heading || '';
                    }
                    break;
                case 'introduction':
                    if ( type === 'html' ) {
                        
                    } else {
                        content += ( content !== '' ? '\n\n' : '' ) + result.content || '';
                    }
                    break;
                case 'outlines':
                    if ( type === 'html' ) {

                    } else {
                        content += ( content !== '' ? '\n\n' : '' ) +  result.heading + ( content !== '' ? '\n\n' : '' ) + result.content || '';
                    }
                    break;
                case 'conclusion':
                    if ( type === 'html' ) {

                    } else {
                        content += ( content !== '' ? '\n\n' : '' ) + result.content || '';
                    }
                    break;
            }
        });
        return content;
    }

    const setEditorContent = () => {
        const editor = ( editorRef && editorRef.current ? editorRef.current : false );
        editor.chain().clearContent().insertContent( getContent(), { 
            updateSelection: true 
        } ).run();
        onWizardClose();
    }

    const handleAddToEditor = () => modals.openConfirmModal({
        title: 'Please Confirm Your Action',
        centered: true,
        children: (
        <>
            <Text size="sm">
                Are you sure you want to add the content to editor?
            </Text>
            <Text size="sm" weight={"700"} color="red">
                This action will replace all the existing content in the editor with this content.
            </Text>
        </>
        ),
        zIndex: 100000,
        labels: { confirm: 'Confirm', cancel: "No don't add it" },
        confirmProps: { color: 'indigo' },
        onConfirm: () => setEditorContent(),
    });

    const handleCopy = (type) => () => {
        clipboard.copy( getContent(type) );
        notifications.showNotification({
            message: 'Copied to clipboard',
            color: 'teal'
        });
    }

    return (
    <>

        { status && status === 'complete' ? (
        <Group position='apart'>
            <Group>
                <Avatar color="indigo" radius={"md"}><MagicWandIcon /></Avatar>
                <Text size="lg" weight="500">{wizard.label}</Text>
            </Group> 
            <Button 
                variant='filled'
                color="indigo"
                size='xs'
                // compact
                leftIcon={<CopyIcon />}
                onClick={handleCopy('text')}
                >
                Copy Whole Article
            </Button>
            {/* <Menu
                control={(
                <Button 
                    variant='filled'
                    color="indigo"
                    size='xs'
                    // compact
                    leftIcon={<UploadIcon />}
                    >
                    Export
                </Button>
                )}>
                <Menu.Item 
                    icon={<PlusCircledIcon size={14} />}
                    onClick={handleAddToEditor}><Text size='sm'>Add To Editor</Text></Menu.Item>
                <Menu.Item 
                    icon={<CopyIcon size={14} />}
                    onClick={handleCopy('html')}><Text size='sm'>Copy Article (HTML)</Text></Menu.Item> 
                <Menu.Item 
                    icon={<CopyIcon size={14} />}
                    onClick={handleCopy('text')}><Text size='sm'>Copy Whole Article</Text></Menu.Item>
            </Menu> */}
        </Group>
        ) : (
        <Group>
            <Avatar color="indigo" radius={"md"}><Text size="md">{currentStep+1}</Text></Avatar>
            <Text size="lg" weight="500">{step.label}</Text>
        </Group>
        ) }

        { status && status === 'process' && (
        <>
            <Space h="sm" />
            <Notification
                // loading
                title="The AI is writing your content in the background..."
                disallowClose
                sx={(theme) => ({
                    boxShadow: 'none',
                    backgroundColor: theme.colors.indigo[0],
                    borderColor: theme.colors.indigo[3],
                })}
            >
                You can close this modal and come back later to see the results.
                <Space h="sm" />
                <Progress value={percentage} label={percentage+"%"} size="xl" radius="xl" animate />
            </Notification>
        </>
        )}

        { status && status === 'complete' && (
        <>
            <Space h="sm" />
            <Notification
                color={"green"}
                icon={<CheckIcon />}
                title="Article Generation Completed!"
                disallowClose
                sx={(theme) => ({
                    boxShadow: 'none',
                    backgroundColor: theme.colors.green[0],
                    borderColor: theme.colors.green[3],
                })}
            >
                
            </Notification>
        </>
        )}      

        <Space h="sm" />
    
        <Card
            shadow={"none"}
            radius="none"
            p="xs">
            <ScrollArea
                viewportRef={viewport}
                type="always"
                sx={(theme) => ({
                    height: 'calc( 75vh - 220px )'
                })}>
                <Box
                    sx={(theme) => ({
                        padding: '0 20px 0 0',
                        maxWidth: '800px'
                    })}>
                    {results && isArrayExists( results ) ? results.map(item => (
                    <Result
                        key={item.id}
                        item={item}
                        status={status}
                        disabled={disabled}
                        selected={improveResult}
                        onImprove={(result) => onImproveResult(result)} />
                    )) : null }
                    { status && status === 'process' && (
                    <Center style={{ height: '150px' }}>
                        <Group position="center" direction="column" noWrap spacing={"xs"}>
                            <Loader
                                variant='bars' />
                            <Text color="dimmed" size="md" align='center'>AI is thinking...</Text>
                        </Group>
                    </Center>
                    ) }
                </Box>
            </ScrollArea>
        </Card>

    </>
    );
}


const mapStateToProps = state => {
    return {
        authData: ( state.auth && state.auth.user ) ? state.auth.user : null,
        globalDisabled: ( state.global && state.global.disabled ) ? state.global.disabled : false,
        wizardData: ( state.wizards && state.wizards.wizard ) ? state.wizards.wizard : false,
    };
};

export default compose(
    connect(mapStateToProps)
)(StepGeneration);