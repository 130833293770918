import { createContext } from 'react';

export const FIREBASE_ACCOUNT_ID = 'wizardly-ai';
export const FIREBASE_REGION = 'us-central1';

// emulator port - make sure it's same as firebase.json
export const EMULATOR_PORT_CLOUD_FUNCTIONS = 6100;
export const EMULATOR_PORT_FIRESTORE = 6200;
export const EMULATOR_PORT_DATABASE = 6300;
export const EMULATOR_PORT_HOSTING = 6400;
export const EMULATOR_PORT_PUBSUB = 6500;
export const EMULATOR_PORT_STORAGE = 6600;
export const EMULATOR_PORT_UI = 6900;

export const FirebaseAppContent = createContext();

export const FIREBASE_CONFIG_DATA = {
    apiKey: "AIzaSyAlO2tg8azqFufqpkw5Se8Ref18rD3kriU",
    authDomain: "wizardly-ai.firebaseapp.com",
    databaseURL: "https://wizardly-ai-default-rtdb.firebaseio.com",
    projectId: "wizardly-ai",
    storageBucket: "wizardly-ai.appspot.com",
    messagingSenderId: "682905770332",
    appId: "1:682905770332:web:494df6227a72a04bda0999"
};