import React, { useMemo } from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
// import { useParams } from "react-router-dom";
import { Text, Grid, Group, Box, Input, Avatar } from '@mantine/core';
// import { useDidUpdate } from '@mantine/hooks';
import { ReaderIcon } from '@radix-ui/react-icons';

// import { isObjectExists } from '../../helpers/validation';
import { useScreenSize } from '../../redux/global/hooks';

const TitleBar = ({
    editorRef = null,
    authData,
    dispatch,
    document = {},
    updating = false,
    onUpdate = () => {}
}) => {
    const [ screenSize, mobileMode ] = useScreenSize();

    const disabled = useMemo(() => {
        return false;
    }, [ updating ] );

    const getWordCount = () => {
        if ( editorRef && editorRef.current ) {
            const editor = editorRef.current;
            const content = editor.getText();
            // make sure content isn't empty and there is no empty space
            return content && content.trim() !== '' ? content.split(' ').length : 0;
        } else {
            return 0;
        }
    }

    const getCharCount = () => {
        if ( editorRef && editorRef.current ) {
            const editor = editorRef.current;
            const content = editor.getText();
            return content && content.trim() !== '' ? content.trim().length : 0;
        } else {
            return 0;
        }
    };

    const handleUpdate = (event) => {
        const { name, value } = event.target;
        onUpdate({ ...document, [name]: value });
    }

    return (
    <Box sx={(theme) => ({
        backgroundColor: theme.colors.indigo[1],
        borderBottom: '1px solid ' + theme.colors.indigo[2],
        padding: theme.spacing.lg,
    })}>
        <Grid justify={"center"}>
            <Grid.Col xs={12} sm={9}>
                <Input 
                    variant="unstyled"
                    placeholder="Enter title here..."
                    name="name" 
                    disabled={disabled}
                    value={( document && document.name ) ? document.name : ''} 
                    sx={(theme) => ({
                        '& .mantine-Input-input': {
                            fontSize: theme.fontSizes.lg,
                            fontWeight: '700',
                        }
                    })}
                    onChange={handleUpdate} />
            </Grid.Col>
            <Grid.Col xs={12} sm={3}>
                <Group position={ mobileMode ? 'center' : 'right'  }>
                    <Avatar color={"indigo"}><ReaderIcon size={24} /></Avatar>
                    <div>
                        <Text size="sm" color="dark" weight={"500"}>{getWordCount()} words</Text>
                        <Text size="sm" color="dimmed">{getCharCount()} characters</Text>
                    </div>
                </Group>
            </Grid.Col>
        </Grid>
    </Box>
    );
}

const mapStateToProps = state => {
    return {
        authData: ( state.auth && state.auth.user ) ? state.auth.user : null,
    };
};

export default compose(
    connect(mapStateToProps)
)(TitleBar);