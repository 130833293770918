import React, { useMemo, useState } from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
// import { useParams } from "react-router-dom";
import { useMantineTheme, Box, Group, Text, Avatar, Textarea, Space, Select, Button, Popover } from '@mantine/core';
// import { useDidUpdate } from '@mantine/hooks';
import { useNotifications } from '@mantine/notifications';
import { InputIcon, PlusCircledIcon } from '@radix-ui/react-icons';

import { getSelectedText } from '../../pages/Document/editor_helpers'

// import { isArrayExists } from '../../helpers/validation';

const HelpersButton = ({
    editorRef = null,
    document = {},
    helpers = [],
    onInsert = () => {}
}) => {
    const theme = useMantineTheme();
    const notifications = useNotifications();
    const [ opened, setOpened ] = useState(false);

    const handleSelect = (selected) => () => {
        let error = false,
            insertedText = '';
        switch( selected ) {
            case 'title':
                insertedText = ( document && document.name ) || '';
                if ( !insertedText )
                    error = 'Document title is empty';
                break;
            case 'brief':
                insertedText = ( document && document.brief ) || '';
                if ( !insertedText )
                    error = 'Article brief is empty';
                break;
            case 'selected_text':
                insertedText = getSelectedText(editorRef);
                if ( !insertedText )
                    error = 'No text selected';
                break;
        }

        if ( error ) {
            notifications.showNotification({
                color: 'red',
                title: error,
            }) 
        } else {
            onInsert(insertedText);
            setOpened(false);
        }

    }

    const Option = ({ helper }) => {
        switch( helper ) {
            case 'title':
                return <Button
                    key={helper}
                    size="xs"
                    compact
                    fullWidth
                    onClick={handleSelect(helper)}>
                    Use Document Title
                </Button>
            case 'brief':
                return <Button
                    key={helper}
                    size="xs"
                    compact
                    fullWidth
                    onClick={handleSelect(helper)}>
                    Use Article Brief
                </Button>
            case 'selected_text':
                return <Button
                    key={helper}
                    size="xs"
                    compact
                    fullWidth
                    onClick={handleSelect(helper)}>
                    Use Selected Text
                </Button>
            default:
                return null;
        }
    }

    return (
    <Box
        sx={(theme) => ({
            paddingTop: '5px',
        })}>
        <Popover
            opened={opened}
            onClose={() => setOpened(false)}
            target={<Button
                variant='light'
                color="gray"
                sx={(theme) => ({
                    padding: '2px 6px'
                })} 
                onClick={() => setOpened((o) => !o)}><PlusCircledIcon size={24} /></Button>}
            width={260}
            position="left" placement="center"
            title="Available Option(s)"
            closeButtonLabel="Close"
            withCloseButton
            withArrow
            styles={{
                popover: {
                    background: theme.colors.gray[0]
                },
                arrow: {
                    background: theme.colors.gray[0]
                }
            }}
            >
            <Box
                sx={(theme) => ({
                    '& .mantine-Button-root': {
                        marginBottom: '5px',
                        '&:last-child': {
                            marginBottom: 0
                        }
                    }
                })}>
                { helpers && helpers.map((helper, index) => (
                <Option
                    key={helper}
                    helper={helper} />
                ))}
            </Box> 
        </Popover>
    
    </Box>
    )
}

const Field = ({
    editorRef = null,
    authData,
    globalDisabled,
    dispatch,
    document,
    formData = {},
    field = {},
    onUpdate = () => {}, 
}) => {

    const handleHelperSelect = (text = '') => {
        onUpdate({ ...formData, [field.id]: text })
    }

    const fieldType = useMemo(() => {
        switch( field.field_type ) {
            case 'textarea':
                return (
                <Textarea
                    placeholder={field.placeholder || ''}
                    value={formData && formData[field.id] ? formData[field.id] : '' }
                    minRows={3}
                    maxRows={10}
                    autosize
                    rightSection={( field.helpers ? <HelpersButton
                        editorRef={editorRef}
                        document={document}
                        helpers={field.helpers}
                        onInsert={handleHelperSelect} /> : null )}
                    rightSectionWidth={( field.helpers ? 36 : 0 )}
                    onChange={(e) => onUpdate({ ...formData, [field.id]: e.target.value })} />
                );
            case 'select':
                return (
                <Select
                    value={formData && formData[field.id] ? formData[field.id] : '' }
                    data={field.options || []}
                    onChange={(newValue) => onUpdate({ ...formData, [field.id]: newValue })} />
                );
            default:
                return null;
        }
    }, [ field, editorRef, formData, document ]);

    return (
    <Box
        sx={(theme) => ({
            padding: '15px 0',
            borderTop: '1px solid ' + theme.colors.gray[3],
            '& .mantine-Textarea-rightSection': {
                alignItems: 'flex-start'
            }
        })}>

        <Group position='left' spacing={"xs"}>
            <Avatar radius={"md"} sx={(theme) => ({
                '& .mantine-Avatar-placeholder': {
                    backgroundColor: theme.colors.indigo[5],
                }
            })}><InputIcon size={24} color="#fff" /></Avatar>
            <div>
                <Text size="md" weight='700'>{field.label}</Text>
                { field.desc && <Text size="xs" color="dimmed">{field.desc}</Text> }
            </div>
        </Group>

        <Space h="sm" />

        {fieldType}

    </Box>
    )
}

const mapStateToProps = state => {
    return {
        authData: ( state.auth && state.auth.user ) ? state.auth.user : null,
        globalDisabled: ( state.global && state.global.disabled ) ? state.global.disabled : false,
    };
};

export default compose(
    connect(mapStateToProps)
)(Field);