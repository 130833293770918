import { useEffect, useState } from 'react';
// import { useDidUpdate } from '@mantine/hooks';
import { useDispatch } from "react-redux";

import { 
    // GET_TEAMS,
    GET_TEAM
} from './types';

import { resetTeam } from './actions';
import { callFunctionsAPI } from '../../../helpers/api';

export const useTeam = ({ project_id = null, team_id = null }) => {
    // const db = getDatabase();
    const dispatch = useDispatch();
    const [ loaded, setLoaded ] = useState(false);
    const [ team, setTeam ] = useState({});

    useEffect(() => {
        setLoaded(false);
        setTeam({});
        callFunctionsAPI({
            url: 'team',
            action: 'get_team',
            formData: { project_id, id: team_id }
        })
        .then(team => {
            dispatch({
                type: GET_TEAM,
                payload: {
                    team,
                }
            });
            setTeam(team);
            setLoaded(true);
        })
        .catch(error => {
            setTeam({});
            setLoaded(true);
            dispatch({
                type: GET_TEAM,
                payload: {
                    team: {},
                }
            });
        });
        return () => {
            dispatch( resetTeam() );
        }
    }, [ project_id, team_id ]);

    return [ loaded, team ];
}