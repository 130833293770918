

import { 
    GET_TEAMS,
    GET_TEAM,
    RESET_TEAMS,
    RESET_TEAM
} from './types';

const initialState = {
    teams: null,
    team: null,
    updated: false,
    rand_single: false,
    rand: false
};

export const teams = (state = initialState, action) => {
    const { type, payload = null } = action;
    switch (type) {
        case GET_TEAMS:
            return {
                ...state,
                teams: payload.list || [],
                rand: Math.floor(Math.random() * 1000000)
            };
        case GET_TEAM:
            return {
                ...state,
                team: payload.team || null,
                rand_single: Math.floor(Math.random() * 1000000)
            };
        case RESET_TEAMS:
            return {
                ...state,
                teams: null,
                rand: false
            };
        case RESET_TEAM:
            return {
                ...state,
                team: null,
                rand_single: false
            };
        default:
            return state;
    }
}