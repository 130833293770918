import React, { useState, useEffect, useMemo } from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { Group, Text, Avatar, UnstyledButton, Box, Menu, Button } from '@mantine/core';
import { DotsVerticalIcon, ChevronRightIcon, TrashIcon, Pencil1Icon, CheckIcon } from '@radix-ui/react-icons';
// import { useClickOutside } from '@mantine/hooks';
import { useModals } from '@mantine/modals';
import { useNotifications } from '@mantine/notifications';

import ProjectFormModal from '../../pages/Projects/modal';

import { callFunctionsAPI, getAPIErrorMessage } from '../../helpers/api';

import { resetProject, deletingProject } from '../../pages/Projects/redux/actions';
import { toggleGlobalDisabled } from '../../redux/global/actions';

const Project = ({
    authData,
    navbarStatus,
    globalDisabled,
    drawerOpened,
    deletingProjectID,
    project = {},
    dispatch,
    onNavigate = () => {}
}) => {
    const { project_id } = useParams();
    const { id = '', name = '', desc = '', team_id = '' } = project;
    const [ opened, setOpened ] = useState(false);
    const [ loading, setLoading ] = useState(false);
    const modals = useModals();
    const notifications = useNotifications();

    const disabled = useMemo(() => {
        return globalDisabled || loading || ( deletingProjectID && deletingProjectID === id ) ? true : false;
    }, [ globalDisabled, loading , deletingProjectID]);

    const handleProjectClicked = (event) => {
        event.preventDefault();
        onNavigate(`/projects/${id}`);
    }

    const handleUpdate = (newData) => {
        let error = false;
        // do error check
        // check if name is empty
        if ( !newData.name ) {
            error = 'Name is required';
        }

        // check if errors are empty
        if ( error ) {
            notifications.showNotification({
                color: 'red',
                title: error,
            })
        } else {
            // no errors, update project
            setLoading(true);
            callFunctionsAPI({
                url: 'project',
                action: 'update',
                formData: newData
            })
            .then(() => {
                setLoading(false);
                setOpened(false);
                notifications.showNotification({
                    color: 'teal',
                    icon: <CheckIcon />,
                    title: 'Project updated',
                })
            })
            .catch(err => {
                setLoading(false);
                notifications.showNotification({
                    color: 'red',
                    title: getAPIErrorMessage(err),
                })
            })
        }
    }

    const handleDelete = () => {
        dispatch(deletingProject(id));
        // dispatch(toggleGlobalDisabled(true));
        notifications.showNotification({
            loading: true,
            disallowClose: true,
            title: 'Deleting project (' + name + ')',
            autoClose: false
        });

        callFunctionsAPI({
            url: 'project',
            action: 'delete',
            formData: { id }
        })
        .then(data => {
            // dispatch(toggleGlobalDisabled(false));
            // setDeleting(false);
            // reset project if needed
            if ( project_id && id === project_id ) {
                dispatch(resetProject());
            }
            setTimeout(() => {
                dispatch(deletingProject(false));
                notifications.clean();
                notifications.showNotification({
                    color: 'teal',
                    icon: <CheckIcon />,
                    title: 'Project ('+name+') deleted',
                }) 
            },151);
        })
        .catch(err => {
            // dispatch(toggleGlobalDisabled(false));
            dispatch(deletingProject(false));
            notifications.clean();
            notifications.showNotification({
                color: 'red',
                title: getAPIErrorMessage(err),
            }) 
        });
    }

    const openDeleteModal = () => modals.openConfirmModal({
        title: 'Delete Project: ' + name,
        centered: true,
        children: (
        <>
            <Text size="sm">
                Are you sure you want to delete this project ({name})? 
            </Text>
            <Text size="sm" weight={"700"} color="red">
                This action cannot be undone.
            </Text>
        </>
        ),
        labels: { confirm: 'Delete Project', cancel: "No don't delete it" },
        confirmProps: { color: 'red' },
        onConfirm: handleDelete,
    });

    return (
    <>
        <Box
            sx={(theme) => ({
                cursor: ( disabled ? 'default' : 'pointer' ),
                opacity: ( disabled ? '0.5' : '1' ),
                pointerEvents: ( disabled ? 'none' : 'auto' )
            })}>
            <Group 
                position='apart' 
                noWrap 
                spacing={0}
                sx={(theme) => ({
                    padding: '0px 10px',
                    height: '40px',
                    transition: 'all 0.15s',
                    '& .mantine-Button-root, & .mantine-UnstyledButton-root': {
                        textDecoration: 'none'
                    },
                    '& .mantine-UnstyledButton-root .mantine-Group-root': {
                        height: '100%',
                    },
                    '&:hover': {
                        backgroundColor: ( drawerOpened ? theme.colors.gray[3] : theme.colors.gray[1] ),
                        '& .mantine-Button-root, & .mantine-UnstyledButton-root': {
                            color: theme.colors.dark[9]
                        }
                    }
                })}>
                <Menu
                    position='top'
                    withArrow
                    control={
                    <Button
                        size="xs"
                        p="0px"
                        mr="xs"
                        radius={"0px"}
                        sx={(theme) => ({
                            height: '40px',
                            color: ( drawerOpened ? theme.colors.dark[8] : '#fff' ),
                            background: 'none',
                            "&:hover" : {
                                background: 'none'
                            }
                        })}>
                        <DotsVerticalIcon />
                    </Button>
                    }>
                    <Menu.Item icon={<Pencil1Icon />} onClick={() => setOpened(true)}>Edit "{name}"</Menu.Item>
                    <Menu.Item color="red" icon={<TrashIcon />} onClick={openDeleteModal}>Delete "{name}"</Menu.Item>
                </Menu>
                <UnstyledButton
                    component="a"
                    href={`/projects/${id}`}
                    sx={(theme) => ({
                        width: '100%',
                        height: '40px',
                        padding: '0',
                        color: ( drawerOpened ? theme.colors.dark[8] : theme.colors.gray[5] ),
                        backgroundColor: 'none',
                    })}
                    onClick={handleProjectClicked} >
                    <Group position='apart'>
                        <Text size="sm">{ name || '' }</Text> 
                        <ChevronRightIcon />
                    </Group>
                </UnstyledButton>
            </Group>
        </Box>

        <ProjectFormModal 
            opened={opened}
            loading={loading}
            disabled={disabled}
            title={'Edit Project: ' + name}
            actionLabel="Update"
            defaultFormData={{ id, name, desc, team_id }}
            onClose={() => setOpened(false)}
            doAction={handleUpdate} />
    </>
    )
}

const mapStateToProps = state => {
    return {
        authData: ( state.auth && state.auth.user ) ? state.auth.user : null,
        globalDisabled: ( state.global && state.global.disabled ) ? state.global.disabled : false,
        navbarStatus: ( state.global && state.global.navbar ) ? state.global.navbar : false,
        deletingProjectID: state.projects && state.projects.deleting ? state.projects.deleting : false,
    };
};

export default compose(
    connect(mapStateToProps)
)(Project);