import React, { useMemo } from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
// import { useParams } from "react-router-dom";
import { useMantineTheme, Card, Text, Group, Box } from '@mantine/core';
import { useClipboard } from '@mantine/hooks';
import { PlusCircledIcon, TrashIcon, CopyIcon, CheckIcon } from '@radix-ui/react-icons';

import ButtonIconCircle from '../../components/ButtonIconCircle';
import MultilineText from '../../components/MultilineText';

// import { isArrayExists } from '../../helpers/validation';

// import { getWizardTypeData } from './helpers';

const Result = ({ 
    globalDisabled,
    result,
    selected = {},
    onSelect = () => {},
    onDelete = () => {}
}) => {
    const theme = useMantineTheme();
    const clipboard = useClipboard({ timeout: 1000 });

    const isSelected = useMemo(() => {
        return selected && selected.id === result.id;
    }, [ result, selected ] );

    return (
    <Card
        radius={"md"}
        shadow="md"
        sx={(theme) => ({
            backgroundColor: isSelected ? theme.colors.indigo[0] : theme.colors.white[0],
            border: '2px solid ' + ( isSelected ? theme.colors.indigo[5] : theme.colors.white[0] ),
        })}>
        <Card.Section
            sx={(theme) => ({
                padding: '5px 10px',
            })}>
            <Group 
                spacing={"md"}
                position='apart' 
                noWrap>
                <Group
                    spacing={"3px"}>
                    <ButtonIconCircle
                        icon={ isSelected ? <CheckIcon /> : <PlusCircledIcon />}
                        label={ isSelected ? "Un-select" : "Select" }
                        btnProps={{
                            variant: ( isSelected ? "filled" : 'light' ),
                            color: ( isSelected ? 'indigo' : 'gray' ),
                            radius: 'md',
                            size: 'xs',
                            disabled: globalDisabled,
                        }}
                        color={theme.colors.indigo[8]}
                        onClick={() => onSelect(( isSelected ? {} : result ))}
                    />
                    <ButtonIconCircle
                        icon={<CopyIcon />}
                        label={ clipboard.copied ? "Copied" : "Copy" }
                        useClipboard={true}
                        btnProps={{
                            variant: "light",
                            color: 'gray',
                            radius: 'md',
                            size: 'xs'
                        }}
                        color={theme.colors.lime[8]}
                        onClick={() => clipboard.copy(( result.text || '' ))}
                    />
                </Group>
                {/* <Box>
                    <ButtonIconCircle
                        icon={<TrashIcon />}
                        label="Remove"
                        btnProps={{
                            variant: "light",
                            color: 'gray',
                            radius: 'md',
                            size: 'xs',
                            disabled: globalDisabled,
                        }}
                        color={theme.colors.red[8]}
                        onClick={() => onDelete(result)}
                    />
                </Box> */}
            </Group>
        </Card.Section>
        <MultilineText text={result.text} textProps={{ size: 'md', component: 'p' }} />
    </Card>
    )
}

const mapStateToProps = state => {
    return {
        authData: ( state.auth && state.auth.user ) ? state.auth.user : null,
        globalDisabled: ( state.global && state.global.disabled ) ? state.global.disabled : false,
    };
};

export default compose(
    connect(mapStateToProps)
)(Result);