import React, { useMemo, useState, forwardRef, useRef, useEffect } from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
// import { useParams } from "react-router-dom";
import { Box, Card, Group, Text, Space, LoadingOverlay, Select, Button, ScrollArea } from '@mantine/core';
import { useModals } from '@mantine/modals';
import { useNotifications } from '@mantine/notifications';
import { useDidUpdate, useViewportSize } from '@mantine/hooks';
import { GearIcon, TrashIcon } from '@radix-ui/react-icons';

import Field from './field';
import Result from './result';
import { getBaseTemplateOptions, getBaseTemplateFields, getBaseTemplateData } from './helpers';

import AIModelSelector from '../AIModelSelector';
// import Loader from '../../components/Loader';

import { isArrayExists, isObjectExists } from '../../helpers/validation';
import { callFunctionsAPI, getAPIErrorMessage } from '../../helpers/api';

import { toggleGlobalDisabled } from '../../redux/global/actions';

import { UPDATE_DOCUMENT_SETTINGS } from '../../pages/Documents/redux/types';
import { useTemplateOptions } from '../../pages/Templates/redux/hooks';
import { SET_TEMPLATES_RESULTS } from '../../pages/Templates/redux/types';

const Templates = ({
    editorRef = null,
    authData,
    globalDisabled,
    dispatch,
    document,
    documentSettings = {},
    templatesResults = [],
    team = {},
    onUpdate = () => {}, 
}) => {
    const viewport = useRef();
    const modals = useModals();
    const notifications = useNotifications();
    const { height } = useViewportSize();
    const [ loading, setLoading ] = useState(false);
    const [ template, setTemplate ] = useState('');
    const [ formData, setFormData ] = useState({});
    const [ results, setResults ] = useState([]);
    const custom_templates = useTemplateOptions({ project_id: document.project_id || '' });

    // // fake results for testing purpose
    // const [ results, setResults ] = useState([
    //     { id: Math.random(), text: 'When someone gets older or is injured, one of the dangers is that they’ll fail to realize that they can’t do the things they used to do in quite the same way.', template: 'article_introduction' },
    //     { id: Math.random(), text: 'None of these changes are failures. They’re simply steps in the journey.', template: 'article_introduction' }
    // ]);

    useEffect(() => {
        let mounted = true;
        if ( mounted ) {
            setTemplate(documentSettings && documentSettings.template ? documentSettings.template : '');
            setFormData(documentSettings && documentSettings.template_form_data ? documentSettings.template_form_data : {});
            // setResults(documentSettings && documentSettings.template_results ? documentSettings.template_results : []);
            setResults( templatesResults && isArrayExists( templatesResults ) ? templatesResults : [] );
        }
        return () => {  
            mounted = false;
        }
    },[]);

    useEffect(() => {
        if ( results && isArrayExists( results ) ) {
            viewport.current.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }, [ results ] );

    // update to redux
    useDidUpdate(() => {
        dispatch({
            type: UPDATE_DOCUMENT_SETTINGS,
            payload: {
                ...documentSettings,
                template,
                template_form_data: formData,
                template_results: results,
            }
        })
    },[ template, formData, results ]);

    const customTemplate = useMemo(() => {
        return custom_templates && isArrayExists( custom_templates ) && template ? custom_templates.find( t => t.id === template ) : false
    }, [ template, custom_templates ] );

    const fields = useMemo(() => {
        if ( customTemplate ) {
            let customTemplateFields = [];
            if ( customTemplate && customTemplate.inputs && isArrayExists( customTemplate.inputs ) ) {
                customTemplate.inputs.forEach( input => {
                    customTemplateFields.push({
                        id: input.code.toLowerCase(),
                        label: input.label || '',
                        desc: '',
                        type: 'string',
                        required: 'yes',
                        field_type: 'textarea',
                        placeholder: '',
                        helpers: [ 'title', 'selected_text' ],
                        error_msg: 'Please fill out all the required field(s)'
                    })
                });
            }
            return customTemplateFields;
        } else {
            return getBaseTemplateFields(template);
        }
    }, [ template, customTemplate ]);

    const displayResults = useMemo(() => {
        // only display results for the selected template
        return results.filter(result => result.template === template);
    }, [ results, template ] );

    const templatesOptions = useMemo(() => {
        let custom_templates_options = [];
        if ( custom_templates && isArrayExists( custom_templates ) ) {
            custom_templates_options = custom_templates.map(template => {
                return {
                    value: template.id,
                    label: template.name,
                    group: 'My Templates'
                }
            });
        }
        return [ ...custom_templates_options, ...getBaseTemplateOptions() ]; 
    }, [ custom_templates ]);


    const handleGenerate = () => {
        let error = false

        // do error check
        fields.forEach(field => {
            if ( field.required === 'yes' && !formData[field.id] ) {
                error = ( field.error_msg || 'Please fill out the form' );
            }
        });

        if ( error ) {
            notifications.showNotification({
                color: 'red',
                title: error,
            }) 
        } else {
            setLoading(true);
            dispatch(toggleGlobalDisabled(true));
            callFunctionsAPI({
                url: 'template',
                action: ( customTemplate ? 'custom_template' : template ),
                formData: {
                    template_id: ( customTemplate && customTemplate.id ) || '',
                    inputs: { ...formData },
                    team_id: ( team && team.id ) || '',
                    document_id: document.id,
                    model: document.model || '',
                }
            })
            .then( newResults => {
                setLoading(false);
                dispatch(toggleGlobalDisabled(false));
                const newTemplatesResults = [
                    ...newResults,
                    ...results,
                ];
                setResults( newTemplatesResults );
                dispatch({ type: SET_TEMPLATES_RESULTS, payload: newTemplatesResults });
            })
            .catch( error => {
                setLoading(false);
                dispatch(toggleGlobalDisabled(false));
                notifications.showNotification({
                    message: getAPIErrorMessage( error ),
                    color: 'red'
                })
            });
        } // end - error
    }

    const handleClearAll = () => {
        // remove all the results for this template
        const newTemplatesResults = [ ...results.filter( result => result.template !== template ) ];
        setResults( newTemplatesResults );
        dispatch({ type: SET_TEMPLATES_RESULTS, payload: newTemplatesResults });
    }

    const handleResultAdd = (result) => {
        const editor = editorRef.current;
        const content = editor.commands.getNodeAtCursorPosition();
        if ( content && content.text && content.text !== '' ) {
            // prepend a blank space to the new result
            editor.commands.insertContent( ' ' + ( result.text || '' ) );
        } else {
            editor.chain().enter().insertContent( ( result.text || '' ) ).run();
        }
    }

    const handleResultReplace = (result) => {
        const editor = editorRef.current;
        const selection = editor.commands.getSelection();
        if ( selection ) {
            // delete existing selection
            editor.chain().deleteSelection().insertContent(result.text || '').run();
        } else {
            notifications.showNotification({
                message: 'Please select the text you want to replace',
                color: 'red'
            })
        }
    }

    const handleResultAddToNotes = (result) => {
        const newNotes = ( document && document.notes || '' );
        onUpdate({ ...document, notes: newNotes + '\n\n' + result.text });
    }

    const handleResultDelete = (result) => {
        const newTemplatesResults = [ ...results.filter( r => r.id !== result.id ) ];
        setResults( newTemplatesResults );
        dispatch({ type: SET_TEMPLATES_RESULTS, payload: newTemplatesResults });
    }

    const openClearAllResults = () => modals.openConfirmModal({
        title: `Clear all the results for ${getBaseTemplateData(template,'label')}`,
        centered: true,
        children: (
        <>
            <Text size="sm">
                Are you sure you want to clear all the results for this template ({getBaseTemplateData(template,'label')})? 
            </Text>
            {/* <Text size="sm" weight={"700"} color="red">
                This action cannot be undone.
            </Text> */}
        </>
        ),
        labels: { confirm: 'Clear All', cancel: "Cancel" },
        confirmProps: { color: 'red' },
        onConfirm: () => handleClearAll(),
    });

    return (
    <Box sx={(theme) => ({
        paddingBottom: (theme.spacing.lg*2)+'px',
        height: height - 36
        })}>
        <ScrollArea 
            viewportRef={viewport}
            sx={(theme) => ({
                marginTop: '-' + theme.spacing.lg + 'px',
                marginLeft: '-' + theme.spacing.lg + 'px',
                marginRight: '-' + theme.spacing.lg + 'px',
                padding: theme.spacing.lg,
                height: 'calc( 100% - 20px )',
            })}> 
            <Card
                p="md"
                radius={"md"}
                // shadow={"md"}
                withBorder
                sx={(theme) => ({
                    // minHeight: '100%'
                })}>
                <LoadingOverlay visible={loading} />

                <Select
                    label="Choose a Template"
                    placeholder="Pick one"
                    value={template}
                    itemComponent={forwardRef(({ label, desc, ...others }, ref) => (
                    <div ref={ref} {...others}>
                        <Group noWrap>
                            <div>
                            <Text size="sm">{label}</Text>
                            <Text size="xs" color="dimmed">
                                {desc}
                            </Text>
                            </div>
                        </Group>
                    </div>
                    ))}
                    data={templatesOptions}
                    // searchable
                    // clearable
                    dropdownComponent="div"
                    maxDropdownHeight={400}
                    nothingFound="No template found."
                    filter={(value, item) =>
                        item.label.toLowerCase().includes(value.toLowerCase().trim()) 
                        || item.desc.toLowerCase().includes(value.toLowerCase().trim())
                    }
                    onChange={(newValue) => setTemplate(newValue)}
                    />
                
                <Space h="sm" />

                { template && fields && isArrayExists( fields ) && (
                <>
                    { fields.map( ( field, index ) => (
                        <Field
                            key={field.id}
                            editorRef={editorRef}
                            field={field}
                            document={document}
                            formData={formData}
                            onUpdate={(newFormData) => setFormData(newFormData)} />
                    ))}         
                </>
                ) }

                { template && (
                <>
                    { !customTemplate && <AIModelSelector
                            noDivider
                            team={team}
                            value={document.model || ''}
                            onUpdate={(newValue) => {
                                onUpdate({ ...document, model: newValue });
                            }} /> }

                    <Space h="sm" />
                    <Button
                        color="indigo"
                        radius={"lg"}
                        leftIcon={<GearIcon />}
                        fullWidth
                        loading={loading}
                        onClick={handleGenerate}>
                        Generate
                    </Button>
                    <Space h="sm" />
                </>
                ) }
            </Card>

            { displayResults && isArrayExists( displayResults ) && (
            <>
                <Space h="lg" />
                <Group 
                    position='right'>
                    <Button
                        variant='light'
                        color="red"
                        leftIcon={<TrashIcon />}
                        onClick={openClearAllResults}>
                        Clear All
                    </Button>
                </Group>
                
                <Space h="sm" />

                <Box
                    sx={(theme) => ({
                        // padding: '0 15px 0 0',
                        '& .mantine-Card-root': {
                            marginBottom: theme.spacing.sm,
                            '&:last-child': {
                                marginBottom: 0,
                            },
                        }
                    })}>
                    { displayResults.map( ( result, index ) => (
                    <Result
                        key={result.id}
                        result={result}
                        onAdd={handleResultAdd}
                        onReplace={handleResultReplace}
                        onAddToNotes={handleResultAddToNotes}
                        onDelete={handleResultDelete} />
                    ))}
                </Box>
            </>   
            )}
        </ScrollArea>
    </Box>
    );
}

const mapStateToProps = state => {
    return {
        authData: ( state.auth && state.auth.user ) ? state.auth.user : null,
        globalDisabled: ( state.global && state.global.disabled ) ? state.global.disabled : false,
        documentSettings: ( state.documents && state.documents.document_settings ) ? state.documents.document_settings : null,
        templatesResults: ( state.templates && state.templates.templates_results ) ? state.templates.templates_results : [],
        team: ( state.teams && state.teams.team ) ? state.teams.team : null,
    };
};

export default compose(
    connect(mapStateToProps)
)(Templates);