
import { 
    GET_DOCUMENTS,
    GET_DOCUMENT,
    RESET_DOCUMENTS,
    RESET_DOCUMENT
} from './types';

import { callFunctionsAPI, getAPIErrorMessage } from '../../../helpers/api';

export const getDocuments = (project_id) => {
	return dispatch => {

        callFunctionsAPI({
            url: 'document',
            action: 'get_documents',
            formData: { project_id }
        })
        .then(list => {
            dispatch({
                type: GET_DOCUMENTS,
                payload: {
                    list,
                }
            });
        })
        .catch(error => {
            alert(getAPIErrorMessage(error));
            dispatch({
                type: GET_DOCUMENTS,
                payload: {
                    list: [],
                }
            });
        });

    }
};

export const getDocument = (id) => {
    return dispatch => {

        callFunctionsAPI({
            url: 'document',
            action: 'get_document',
            formData: { id }
        })
        .then(document => {
            dispatch({
                type: GET_DOCUMENT,
                payload: {
                    document,
                }
            });
        })
        .catch(error => {
            dispatch({
                type: GET_DOCUMENT,
                payload: {
                    document: {},
                }
            });
        });

    }
}

export const resetDocuments = () => {
    return dispatch => {
        setTimeout(() => {
            dispatch({
                type: RESET_DOCUMENTS,
            });
        },150);
    }
}

export const resetDocument = () => {
    return dispatch => {
        setTimeout(() => {
            dispatch({
                type: RESET_DOCUMENT,
            });
        },150);
    }
}