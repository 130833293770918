
import { isObjectExists, isArrayExists } from "../../helpers/validation";
import { TEMPLATE_TYPES } from "./data";

export const getBaseTemplate = (template) => {
    return TEMPLATE_TYPES.find(t => t.id === template);
}

export const getBaseTemplateData = (template,key) => {
    const selected = getBaseTemplate(template);
    return ( selected && selected[key] ? selected[key] : '' );
}

export const getBaseTemplateOptions = () => {
    return TEMPLATE_TYPES.map(type => {
        return {
            value: type.id,
            label: type.label,
            desc: type.desc,
            group: 'Base Templates',
        }
    });
}

export const getBaseTemplateFields = (template) => {
    const selected = getBaseTemplate(template);
    return ( selected && selected.fields ? selected.fields : [] );
}

export const getBaseTemplateField = (template,field_id) => {
    const selected = getBaseTemplate(template);
    return ( selected && selected.fields ? selected.fields.find(f => f.id === field_id) : false );
}

export const getTemplateFieldData = (template,field_id,key) => {
    const field = getBaseTemplateField(template,field_id);
    return ( field && field[key] ? field[key] : '' );
}