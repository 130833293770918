import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Grid, Container, Box } from '@mantine/core';
import { useNotifications } from '@mantine/notifications';
import shortid from 'shortid';

import ChatContainer from '../../modules/Chats/ChatContainer';

import { callFunctionsAPI, getAPIErrorMessage } from '../../helpers/api';

import { shrinkNavbar, expandNavbar } from '../../redux/global/actions';
import { useScreenSize } from '../../redux/global/hooks';

const ChatPage = () => {
    const authData = useSelector(state => state.auth);
    const dispatch = useDispatch();
    const notifications = useNotifications();
    const [ screenSize, mobileMode ] = useScreenSize();
    const [ messages, setMessages ] = useState([
        {
            id: 1,
            role: 'system',
            loaded: true,
            content: 'You are ChatGPT, a large language model trained by OpenAI. Answer as concisely as possible. Knowledge cutoff: Sep 2021 Current date: ' + new Date().toLocaleString('en-US', { timeZone: 'Asia/Singapore' }),
        }
    ]);
    const [ chatID, setChatID ] = useState(false);
    const [ loading, setLoading ] = useState(false);

    useEffect(() => {
        dispatch(shrinkNavbar());
        return () => {
            if ( !mobileMode ) {
                dispatch(expandNavbar());
            }
        }
    },[]);

    const handleAddMessage = (text) => {
        const new_message_id = (( messages.length ? messages.length : 0 )+1) + 2;
        const newMessages = [
            ...messages,
            {
                id: new_message_id - 1,
                role: 'user',
                loaded: true,
                content: text,
            },
            {
                id: new_message_id,
                role: 'assistant',
                loaded: false,
                content: ''
            }
        ];
        setMessages(newMessages);
        setLoading(true);
        // wait for 1 second
        setTimeout(() => {
            callFunctionsAPI({
                url: 'chat',
                action: 'generate',
                formData: {
                    messages: newMessages,
                    chat_id: chatID,
                    team_id: authData.team ? authData.team.id : false,
                }
            })
            .then( result => {
                const content = ( result && result.data && result.data[0] && result.data[0].text && result.data[0].text !== '' ? result.data[0].text.trim() : '' );
                const updatedMessages = [ ...newMessages ];
                updatedMessages[updatedMessages.length - 1] = {
                        id: new_message_id,
                        role: 'assistant',
                        loaded: true,
                        error: ( content === '' ? true : false ),
                        content: ( content !== '' ? content : '<NO RESPONSE>' ),
                    };
                setLoading(false);
                setMessages(updatedMessages);
                if ( result.id && result.id !== '' ) {
                    setChatID(result.id);
                }
            })
            .catch( error => {
                setLoading(false);
                notifications.showNotification({
                    message: getAPIErrorMessage( error ),
                    color: 'red'
                })
            });
        },1000);
    }

    return (
    <Grid
        gutter={"0px"}>
        <Grid.Col
            span={9}>
            <Container
                p="0px"
                sx={() => ({
                    width: '100%',
                    maxWidth: '900px',
                    marginLeft: '0',
                })}
                >
                <ChatContainer
                    loading={loading}
                    messages={messages}
                    onUpdate={(newMessages) => setMessages(newMessages)}
                    onAdd={handleAddMessage}
                    />
            </Container>
        </Grid.Col>
        <Grid.Col
            span={3}>
            <Box
                sx={() => ({
                    paddingLeft: '40px',
                })}>
                    
            </Box>
        </Grid.Col>
    </Grid>
    )
}

export default ChatPage;