import React, { useEffect, useState, useMemo } from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { Title, Grid, Group } from '@mantine/core';
import { useDidUpdate } from '@mantine/hooks';
// import { PlusIcon } from '@radix-ui/react-icons';

import DocumentAddNew from './addnew';
import DocumentsList from './list';

import Loader from '../../components/Loader';
import ItemNotFound from '../../components/ItemNotFound';

import { isObjectExists } from '../../helpers/validation';

import { useProject } from '../Projects/redux/hooks';
import { useDocuments } from './redux/hooks';

import { useScreenSize } from '../../redux/global/hooks';

const Documents = ({
    authData,
    dispatch
}) => {
    const [ screenSize, mobileMode ] = useScreenSize();
    const { project_id } = useParams();
    const [ projectLoaded, project ] = useProject({ project_id });
    const [ documentsLoaded, documents ] = useDocuments({ project_id });

    return projectLoaded && documentsLoaded ? ( project && isObjectExists( project ) ? (
    <>
        <Grid justify={"center"}>
            <Grid.Col span={ mobileMode ? 12 : 6 }>
                <Title align={ mobileMode ? 'center' : 'left' } order={2}>{( project && project.name || '' )}</Title>
            </Grid.Col>
            <Grid.Col span={ mobileMode ? 12 : 6 }>
                <Group position={ mobileMode ? 'center' : 'right'  }>
                    <DocumentAddNew
                        project={project} />
                </Group>
            </Grid.Col>
        </Grid>

        <DocumentsList
            project={project}
            documents={documents} />

    </>
    ) : <ItemNotFound 
            label="Sorry. This project is either doesn't exist, or you don't have access to it."
            goBack="/projects" /> ) : <Loader height='70vh' /> ;
}

const mapStateToProps = state => {
    return {
        authData: ( state.auth && state.auth.user ) ? state.auth.user : null
    };
};

export default compose(
    connect(mapStateToProps)
)(Documents);