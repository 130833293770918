
// navbar
export const TOGGLE_NAVBAR = 'toggle_navbar';
export const EXPAND_NAVBAR = 'expand_navbar';
export const SHRINK_NAVBAR = 'shrink_navbar';

// global disabled
export const TOGGLE_GLOBAL_DISABLED = 'toggle_global_disabled';
export const TOGGLE_EDITOR_DISABLED = 'toggle_editor_disabled';

// changes made
export const CHANGES_MADE = 'changes_made';
export const CHANGES_RESET = 'changes_reset';