import { useState, useEffect } from 'react';
// import { useDidUpdate } from '@mantine/hooks';
import { useSelector, useDispatch } from "react-redux";
import { getDatabase, ref, query, onValue, off } from "firebase/database";
// import { getFirestore, doc, onSnapshot } from "firebase/firestore";

import { 
    TOKENS_GET
} from './types';

// import { isArrayExists, isObjectExists } from '../../../helpers/validation';
// import { getUserActiveTeam } from '../../../helpers/auth';

export const useTokens = () => {
    const currentTeam = useSelector(state => state.auth.team);
    const dispatch = useDispatch();
    const db = getDatabase();
    const [ loaded, setLoaded ] = useState(false);
    const [ tokens, setTokens ] = useState(0);

    useEffect(() => {
        const team_id = currentTeam && currentTeam.id ? currentTeam.id : 'noteamid';
        const Ref = query( ref(db, 'teams/' + team_id + '/tokens' ) );
        onValue(Ref, (snapshot) => {
            const val = ( snapshot.exists() ? snapshot.val() : 0 );
            setTokens( val );
            setLoaded(true);
            dispatch({ type: TOKENS_GET, payload: { tokens: val } });
        });
        return () => {
            setLoaded(false);
            off( Ref );
        };
    },[ currentTeam ]);

    return [ loaded, tokens ];
}