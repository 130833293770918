import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";

import { doPromise, callFunctionsAPI } from '../../helpers/api';

import { getIDToken } from '../../redux/auth/api';

// register user upon sign-up
export const onAccountCreated = (result) => {
    return new Promise((resolve, reject) => {

        getIDToken()
        .then(idToken => {

            doPromise({
                idToken,
                method: 'post',
                url: 'auth',
                params: [{ key: 'action', value: 'post' }],
                formData: {
                    uid: ( result && result.user && result.user.uid || '' ),
                    email: ( result && result.user && result.user.email || '' )
                }
            })
            .then(res => {
                resolve(res.data);
            })
            .catch(error => {
                reject(error);
            });

        })
        .catch(error => {
            reject(error);
        });

    })
}

// perform google sign-in
export const googleSignIn = () => {
    return new Promise((resolve,reject) => {

        const provider = new GoogleAuthProvider();
        provider.addScope('profile');
        provider.addScope('email');

        const auth = getAuth();

        signInWithPopup(auth, provider)
        .then((result) => { 
            onAccountCreated(result)
            .then( data => {
                resolve({ data, userObj: ( ( result && result.user  ) || '' ) });
            })
            .catch(function(error) {
                reject(error);
            });
        }).catch(function(error) {
            reject(error);
        });
          
    });
}

export const activateUser = (formData) => {
    return new Promise((resolve,reject) => {

        callFunctionsAPI({ url: 'auth', action: 'activate_user', formData })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });

    })
}