
import { DEV_USERS } from '../constants';

import { isArrayExists } from './validation';

// check if current user is admin
export const isAdmin = function(authData) {
    return ( authData && authData.type && ( authData.type === 'admin' ) ? true : false );
}

// check if current user is user
export const isValidUser = function(authData) {
    return ( authData && authData.type && ( authData.type === 'user' || authData.type === 'admin' ) ? true : false );
}

// check if current user is user
export const isUser = function(authData) {
    return ( authData && authData.type && authData.type === 'user' ? true : false );
}

// check if current user is dev user
export const isDev = function(authData) {
    return ( authData && authData.uid && DEV_USERS.find( uid => uid === authData.uid ) ? true : false );
}

// check if is active user
export const isActiveUser = (authData) => {
    return isValidUser(authData) && authData.status && authData.status === 'active' ? true : false ;
}

// get user active team
export const getUserActiveTeam = function(authData) {
    return ( authData && authData.active_team && authData.active_team.length > 0 ? authData.active_team : ( authData.teams && isArrayExists( authData.teams ) && authData.teams[0] && authData.teams[0].length > 0 ? authData.teams[0] : 'noactiveteam' ) );
}