import React, { useState } from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
// import { useParams } from "react-router-dom";
import { useMantineTheme, Text, Box, Card, Group, Textarea } from '@mantine/core';
import { useNotifications } from '@mantine/notifications';
import { Cross1Icon, Pencil1Icon, CheckIcon } from '@radix-ui/react-icons';

import ButtonIconCircle from '../../components/ButtonIconCircle';
import MultilineText from '../../components/MultilineText';

import { isObjectExists } from '../../helpers/validation';

import { toggleGlobalDisabled } from '../../redux/global/actions';

// import { getWizardTypeData } from './helpers';

const StepSelector = ({
    globalDisabled,
    dispatch,
    document = false,
    wizard = false,
    currentStep = 0,
    step = false,
    results = [],
    selected = {},
    onUpdate = () => {},
    onUnselect = () => {},
}) => {
    const theme = useMantineTheme();
    const notifications = useNotifications();
    const [ text, setText ] = useState('');
    const [ edit, setEdit ] = useState(false);

    const handleCancelEdit = () => {
        dispatch(toggleGlobalDisabled(false));
        setEdit(false);
        setText('');
    }

    const handleEdit = () => {
        dispatch(toggleGlobalDisabled(true));
        setEdit(true);
        setText( selected && selected.text ? selected.text : '' );
    }

    const handleUpdate = () => {
        if ( text && text.length ) {
            onUpdate({ ...selected, text });
            dispatch(toggleGlobalDisabled(false));
            setEdit(false);
        } else {
            notifications.showNotification({
                message: 'This field cannot be empty',
                color: 'red'
            })
        }
    } 

    return (
    <>
        { selected && isObjectExists( selected ) ?(
        <Card
            radius={"md"}
            shadow="md"
            sx={(theme) => ({
                backgroundColor: theme.colors.indigo[0],
                border: '2px solid ' + theme.colors.indigo[5],
            })}>
            <Card.Section
                sx={(theme) => ({
                    padding: '5px 10px',
                })}>
                <Group 
                    spacing={"md"}
                    position='apart' 
                    noWrap>
                    { edit ? <ButtonIconCircle
                        icon={<CheckIcon />}
                        label="Update"
                        btnProps={{
                            variant: "filled",
                            color: 'green',
                            radius: 'md',
                            size: 'xs'
                        }}
                        color={theme.colors.green[8]}
                        onClick={handleUpdate}
                    /> : <ButtonIconCircle
                        icon={<Pencil1Icon />}
                        label="Edit"
                        btnProps={{
                            variant: "light",
                            color: 'gray',
                            radius: 'md',
                            size: 'xs'
                        }}
                        color={theme.colors.indigo[8]}
                        onClick={handleEdit}
                    /> }
                    { edit ? <ButtonIconCircle
                        icon={<Cross1Icon />}
                        label="Cancel Edit"
                        btnProps={{
                            variant: "light",
                            color: 'gray',
                            radius: 'md',
                            size: 'xs'
                        }}
                        color={theme.colors.red[8]}
                        onClick={handleCancelEdit}
                    /> : <ButtonIconCircle
                        icon={<Cross1Icon />}
                        label="Un-Select"
                        btnProps={{
                            variant: "light",
                            color: 'gray',
                            radius: 'md',
                            size: 'xs'
                        }}
                        color={theme.colors.red[8]}
                        onClick={() => onUnselect(selected)}
                    /> }
                </Group>
            </Card.Section>
            { edit ? (
            <Textarea
                value={text || ''}
                minRows={5}
                maxRows={15}
                onChange={(e) => setText(e.target.value)} />
            ) : <MultilineText text={selected.text} textProps={{ size: 'md', component: 'p' }} />}
        </Card>
        ) : (
        <Box
            sx={(theme) => ({
                backgroundColor: theme.colors.gray[2],
                padding: theme.spacing.md,
                borderRadius: theme.radius.md
            })}>
            <Text size="md" weight={"500"}>Click on the "+" icon to select an option in the left panel.</Text>
            {/* <Text size="sm" color="dimmed" weight={"400"}>Please select one option on the left panel.</Text> */}
        </Box>
        ) }

    </>
    );
}

const mapStateToProps = state => {
    return {
        authData: ( state.auth && state.auth.user ) ? state.auth.user : null,
        globalDisabled: ( state.global && state.global.disabled ) ? state.global.disabled : false,
    };
};

export default compose(
    connect(mapStateToProps)
)(StepSelector);