import React, { useState, useMemo } from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { Card, Space, Grid, Box, Button } from '@mantine/core';

import { useNavigation } from '../../redux/global/hooks';

const Workflows = () => {
    const navigate = useNavigation();
    return (
    <>
        <Button 
            onClick={() => navigate('/workflows/1')}>
            Go to Workflow
        </Button>
    </>
    )
}

export default Workflows;