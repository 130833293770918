import { useState, useEffect } from 'react';
// import { useDidUpdate } from '@mantine/hooks';
import { useSelector, useDispatch } from "react-redux";
import { getFirestore, onSnapshot, where, limit, query, collection } from "firebase/firestore";

import { 
    AUTH_GET_TEAMS,
    AUTH_RESET_TEAMS
} from './types';

// import { isArrayExists, isObjectExists } from '../../../helpers/validation';
// import { getUserActiveTeam } from '../../../helpers/auth';

export const useTeams = (authData) => {
    const db = getFirestore();
    const dispatch = useDispatch();
    const [ loaded, setLoaded ] = useState(false);
    const [ list, setList ] = useState([]);

    useEffect(() => {
        let unsubscribe;
        setLoaded(false);
        const q = query(collection(db, "teams"), where( 'members_ids', 'array-contains-any', [ authData.email ] ), limit(10));
        unsubscribe = onSnapshot(q, (querySnapshot) => {
            const list = [];
            querySnapshot.forEach((doc) => {
                list.push(doc.data());
            });
            dispatch({
                type: AUTH_GET_TEAMS,
                payload: {
                    list,
                }
            });
            setList(list);
            setLoaded(true);
        });

        return () => {
            unsubscribe();
            dispatch({ type: AUTH_RESET_TEAMS });
        }
    },[ authData ]);

    return [ loaded, list ];
}