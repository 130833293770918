
import { isObjectExists, isArrayExists } from "../../helpers/validation";
import { WIZARD_TYPES } from "./data";

export const getDefaultWizardType = () => {
    return WIZARD_TYPES.find(type => type.default === true);
}

export const getDefaultWizardTypeID = () => {
    const defaultType = getDefaultWizardType();
    return ( defaultType && defaultType.id ? defaultType.id : '' );
}

export const getWizardType = (type) => {
    return WIZARD_TYPES.find(t => t.id === type);
}

export const getWizardTypeData = (type,key) => {
    const wizardType = getWizardType(type);
    return ( wizardType && wizardType[key] ? wizardType[key] : '' );
}

export const getWizardTypeOptions = () => {
    return WIZARD_TYPES.map(type => {
        return {
            value: type.id,
            label: type.label,
            desc: type.desc
        }
    });
}

export const getWizardStep = (type,step_id) => {
    const wizardType = getWizardType(type);
    return ( wizardType && wizardType.steps ? wizardType.steps.find(s => s.id === step_id) : false );
}

export const getWizardStepIndex = (type,step_id) => {
    const wizardType = getWizardType(type);
    const steps = wizardType.steps;
    const step = steps.find(s => s.id === step_id);
    return ( step ? steps.indexOf(step) : false );
}

export const getWizardNextStep = (type,step_id) => {
    const wizardType = getWizardType(type);
    const steps = wizardType.steps;
    const step = steps.find(s => s.id === step_id);
    const index = steps.indexOf(step);
    return ( index < steps.length - 1 ? steps[index + 1] : false );
}

export const getWizardNextStepIndex = (type,step_id) => {
    const wizardType = getWizardType(type);
    const steps = wizardType.steps;
    const step = steps.find(s => s.id === step_id);
    const index = steps.indexOf(step);
    return ( index < steps.length - 1 ? index + 1 : false );
}

export const getWizardPreviousStep = (type,step_id) => {
    const wizardType = getWizardType(type);
    const wizardStep = getWizardStep(type,step_id);
    const wizardStepIndex = wizardType.steps.indexOf(wizardStep);
    const wizardPreviousStep = wizardType.steps[wizardStepIndex - 1];
    return ( wizardPreviousStep ? wizardPreviousStep : false );
}

export const getWizardPreviousStepIndex = (type,step_id) => {
    const wizardType = getWizardType(type);
    const wizardStep = getWizardStep(type,step_id);
    const wizardStepIndex = wizardType.steps.indexOf(wizardStep);
    const wizardPreviousStepIndex = wizardStepIndex - 1;
    return ( wizardPreviousStepIndex >= 0 ? wizardPreviousStepIndex : false );
}

export const getWizardFormTypeData = (wizardData,step) => {
    const wizardType = ( wizardData && wizardData.type ) || '';
    switch( wizardType ) {
        // case 'article_body_content':
        //     return {
        //         title: {
        //             id: 'title',
        //             text: ( wizardData && wizardData.selected_content && wizardData.selected_content && wizardData && wizardData.selected_content && wizardData.selected_content.title ) || ''
        //         },
        //         outlines: {
        //             id: 'outlines',
        //             text: ( wizardData && wizardData.selected_content && wizardData.selected_content && wizardData && wizardData.selected_content && wizardData.selected_content.outlines ) || ''
        //         },
        //         subheading: {
        //             id: 'subheading',
        //             text: ( wizardData && wizardData.selected_content && wizardData.selected_content && wizardData && wizardData.selected_content && wizardData.selected_content.subheading ) || ''
        //         }
        //     };
        default:
            return ( wizardData && step && step.id && wizardData.selected_content && wizardData.selected_content[step.id] && wizardData.selected_content[step.id].text && isObjectExists( wizardData.selected_content[step.id].text ) ? wizardData.selected_content[step.id].text : {
                [step.id]: ( wizardData && wizardData.selected_content && wizardData.selected_content[step.id] && wizardData.selected_content[step.id].text ? wizardData.selected_content[step.id].text : '' )
            } );
    }
}


export const getWizardSelectedContent = (wizardData,step) => {
    const selected = ( wizardData && wizardData.selected_content && step && wizardData.selected_content[step.id] ? wizardData.selected_content[step.id] : false );
    switch( step.type ) {
        case 'form':
            return getWizardFormTypeData(wizardData,step);
        default:
            return selected;
    }
}

export const reorganizeWizardResults = (results) => {
    let position = 0;
    const newResults = [];

    if( results && isArrayExists( results ) ) {
        // sort results by position
        results.sort((a,b) => {
            return a.position - b.position;
        })
        .forEach(result => {
            if ( result && result.content && isArrayExists( result.content ) ) {
                // sort content by position
                result.content.sort((a,b) => {
                    return a.position - b.position;
                })
                .forEach(content => {
                    if( content && content.id ) {
                        position++;
                        newResults.push({
                            id: result.id + '-' + content.id,
                            type: result.id,
                            label: result.label + ' #' + content.id,
                            heading: content.heading,
                            content: content.content,
                            ready: content.ready || false,
                            can_improve: content.can_improve || false,
                            selected_variant: content.selected_variant || '',
                            variants: ( content.variants || [] ),
                            position
                        });
                    }
                });
            } else {
                position++;
                newResults.push({ ...result, type: result.id, position });
            }
        });
    }
    return newResults;
}

export const getTotalWizardResults = (results) => {
    let count = 0;
    if( results && isArrayExists( results ) ) {
        // sort results by position
        results.forEach(result => {
            if ( result && result.content && isArrayExists( result.content ) ) {
                // sort content by position
                result.content.forEach(content => {
                    count++;
                });
            } else {
                count++;
            }
        });
    }
    return count;
}