// import { getFirestore, collection, query, where, getDocs, limit } from "firebase/firestore";

import { 
    GET_TEAMS,
    GET_TEAM,
    RESET_TEAMS,
    RESET_TEAM
} from './types';

import { callFunctionsAPI } from '../../../helpers/api';


export const getTeams = () => {
	return dispatch => {

        callFunctionsAPI({
            url: 'team',
            action: 'get_user_teams'
        })
        .then(list => {
            dispatch({
                type: GET_TEAMS,
                payload: {
                    list,
                }
            });
        })
        .catch(error => {
            dispatch({
                type: GET_TEAMS,
                payload: {
                    list: [],
                }
            });
        });

    }
};

export const getTeam = ({ project_id = false, team_id = false }) => {
    return dispatch => {

        callFunctionsAPI({
            url: 'team',
            action: 'get_team',
            formData: { project_id, id: team_id }
        })
        .then(team => {
            dispatch({
                type: GET_TEAM,
                payload: {
                    team,
                }
            });
        })
        .catch(error => {
            dispatch({
                type: GET_TEAM,
                payload: {
                    team: {},
                }
            });
        });

    }
}

export const resetTeams = () => {
    return dispatch => {
        setTimeout(() => {
            dispatch({
                type: RESET_TEAMS,
            });
        },150);
    }
}

export const resetTeam = () => {
    return dispatch => {
        setTimeout(() => {
            dispatch({
                type: RESET_TEAM,
            });
        },150);
    }
}