import React from 'react';
import { Provider as ReduxProvider } from "react-redux";
import { MantineProvider } from '@mantine/core';
import { ModalsProvider } from '@mantine/modals';
import { NotificationsProvider } from '@mantine/notifications';

import Structure from './structure';
import { FirebaseAppContent } from './firebase';
import store from './store';
import theme from './theme';

const App = ({
    fbApp
}) => {
  return (
    <ReduxProvider store={store}>
        <MantineProvider theme={theme}>
            <NotificationsProvider position='top-right' limit={3}>
                <ModalsProvider>
                  <FirebaseAppContent.Provider value={fbApp}>
                    <Structure />
                  </FirebaseAppContent.Provider>
                </ModalsProvider>
            </NotificationsProvider>
        </MantineProvider>
    </ReduxProvider>
  );
}

export default App;
