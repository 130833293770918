import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { Card, Skeleton, Title, Space, Box, Text, Group, Button, Badge } from '@mantine/core';

// import ButtonIconCircle from '../../components/ButtonIconCircle';

import { isArrayExists } from '../../helpers/validation';

const WidgetRecentDocs = () => {
    const authData = useSelector(state => state.auth.user);
    const projects = useSelector(state => state.projects.projects);
    const loaded = useSelector(state => state.documents.recent_documents_loaded);
    const list = useSelector(state => state.documents.recent_documents);
    const navigate = useNavigate();

    const handleOpen = (item) => (event) => {
        event.preventDefault();
        navigate(`/projects/${item.project_id}/docs/${item.id}`);
    }

    const getProjectName = (item) => {
        const selected = ( projects && isArrayExists( projects ) ? projects.find(project => project.id === item.project_id) : null );
        return selected ? selected.name : '---';
    }

    return (
    <Card
        id="dashboard-widget-recentdocs"
        p="md"
        withBorder
        shadow={"sm"}
        sx={(theme) => ({
            background: theme.colors.gray[1]
        })}>
        <Title order={4}>Most Recent Document(s):</Title>
        <Space h="sm" />
        { loaded ? (
        <Box>
            { list && isArrayExists(list) ? list.map(item => (
            <Card 
                p="sm"
                withBorder
                mb={"xs"}
                key={item.id}
                sx={(theme) => ({
                    transition: 'all 0.2s ease-in-out',
                    '&:hover': {
                        background: theme.colors.indigo[1],
                        borderColor: theme.colors.indigo[5],
                    }
                })}>
                <Text size="sm" weight={700} color="gray">{item.name}</Text>
                <Space h="xs" />
                <Group position='apart'>
                    <Badge size='xs' color="gray">{getProjectName(item)}</Badge>
                    <Button 
                        component={'a'}
                        href={`/projects/${item.project_id}/docs/${item.id}`}
                        variant="filled" 
                        color="indigo"
                        size="xs"
                        compact
                        onClick={handleOpen(item)}
                        >
                        Open
                    </Button>
                </Group>
            </Card>
            )) : <Text color="dimmed">No document(s) added yet.</Text> }
        </Box>   
        ) : (
        <Box>
            <Skeleton width="100%" height={8} />
            <Skeleton width="80%" height={8} mt={6} />
            <Skeleton width="60%" height={8} mt={6} />
        </Box>
        ) }
    </Card>
    )
}

export default WidgetRecentDocs;