import React, { useMemo, useState, useEffect } from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
// import { useParams } from "react-router-dom";
import { Box, Tabs, Tooltip } from '@mantine/core';
// import { useWindowScroll } from '@mantine/hooks';
import { Pencil1Icon, CubeIcon, MagicWandIcon, ReaderIcon, DownloadIcon, PlayIcon } from '@radix-ui/react-icons';

import PanelNotes from './panel_notes';
import WritingTools from './panel_writing_tools';
import Wizard from './panel_wizard';
import Export from './panel_export';

import Templates from '../../modules/TemplatesSelector';
import Playground from '../../modules/Playground';

import { useScreenSize } from '../../redux/global/hooks';

// import { isObjectExists } from '../../helpers/validation';

const SidePanel = ({
    editorRef = null,
    authData,
    navbarStatus,
    dispatch,
    drawerMode = false,
    document,
    onDrawerToggle = () => {},
    onUpdate = () => {}
}) => {
    const [ screenSize, mobileMode ] = useScreenSize();
    const [ activeTab, setActiveTab ] = useState(0)

    useEffect(() => {
        if ( mobileMode ) {
            setActiveTab(null);
        } else {
            setActiveTab(0);
        }
    }, [ screenSize ] );

    const tabs = useMemo(() => {
        const tabs = [
            {
                id: 'tools',
                label: 'Writing Tools',
                icon: <Pencil1Icon />,
                content: (
                <WritingTools
                    editorRef={editorRef}
                    document={document}
                    drawerMode={drawerMode}
                    onDrawerToggle={onDrawerToggle}
                    onUpdate={onUpdate} />
                )
            },
            {
                id: 'templates',
                label: 'Templates',
                icon: <CubeIcon />,
                content: (
                <Templates
                    editorRef={editorRef}
                    document={document}
                    drawerMode={drawerMode}
                    onDrawerToggle={onDrawerToggle}
                    onUpdate={onUpdate} />
                )
            },
            {
                id: 'playground',
                label: 'Playground',
                icon: <PlayIcon />,
                content: (
                <Playground
                    editorRef={editorRef}
                    document={document}
                    drawerMode={drawerMode}
                    onDrawerToggle={onDrawerToggle}
                    onUpdate={onUpdate} />
                )
            },
            {
                id: 'notes',
                label: 'Notes',
                icon: <ReaderIcon />,
                content: (
                <PanelNotes
                    editorRef={editorRef}
                    document={document}
                    drawerMode={drawerMode}
                    onDrawerToggle={onDrawerToggle}
                    onUpdate={onUpdate} />
                )
            },
            {
                id: 'wizard',
                label: 'Wizard',
                icon: <MagicWandIcon />,
                content: (
                <>
                    <Wizard
                        editorRef={editorRef}
                        drawerMode={drawerMode}
                        onDrawerToggle={onDrawerToggle}
                        onUpdate={onUpdate} />
                </>
                )
            },
            {
                id: 'export',
                label: 'Export',
                icon: <DownloadIcon />,
                content: (
                <>
                    <Export
                        editorRef={editorRef}
                        drawerMode={drawerMode}
                        onDrawerToggle={onDrawerToggle}
                        documentData={document} />
                </>
                )
            },
        ];
        
        return tabs;
    }, [ document, editorRef, drawerMode ] );

    return (
    <Box 
    sx={(theme) => ({
        padding: '0px',
        height: '100%'
    })}>
        <Tabs 
            active={activeTab}
            color="indigo" 
            tabPadding={ drawerMode ? "sm" : "lg" } 
            variant='outline'
            orientation="vertical"
            position='right'
            onTabChange={(newTab) => setActiveTab(newTab)}
            sx={(theme) => ({
                height: '100%',
                flexDirection: 'row-reverse',
                '& .mantine-Tabs-tabsListWrapper': {
                    borderLeft: '1px solid '+ theme.colors.gray[3],
                    borderRight: 'none',
                    '& .__mantine-ref-tabsList': {
                        marginLeft: '-1px',
                        marginRight: '0px',
                    },
                    '& button.__mantine-ref-tabActive': {
                        borderRadius: '0px',
                        borderColor: theme.colors.gray[0],
                        background: theme.colors.gray[0],
                    }
                },
                '& .mantine-Tabs-body': {
                    padding: drawerMode ? '20px 10px' : '20px',
                    width: '100%',
                    background: theme.colors.gray[0]
                }
            })}>
            {tabs.map(tab => (
            <Tabs.Tab 
                key={tab.id}
                label="" 
                icon={(
                <Tooltip
                    label={tab.label}
                    position="left"
                    withArrow>
                    {tab.icon}   
                </Tooltip>
                )}>
                {tab.content}
            </Tabs.Tab>
            ))}
        </Tabs>
    </Box>
    );
}

const mapStateToProps = state => {
    return {
        authData: ( state.auth && state.auth.user ) ? state.auth.user : null,
        navbarStatus: ( state.global && state.global.navbar ) ? state.global.navbar : false,
    };
};

export default compose(
    connect(mapStateToProps)
)(SidePanel);