import React, { useMemo } from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { Grid, Button, Group, Tooltip, Avatar, Affix, Transition, Box } from '@mantine/core';
import { useWindowScroll } from '@mantine/hooks';
import { ArrowLeftIcon, UpdateIcon, ExclamationTriangleIcon, FontBoldIcon, CheckCircledIcon } from '@radix-ui/react-icons';

// import { isObjectExists } from '../../helpers/validation';

import { useNavigation } from '../../redux/global/hooks';

// const EditorToolBar = () => {
//     return (
//     <>
//         <Button
//             variant='outline'
//             size='sm'
//             color={'gray'}
//             onClick={(event) => {
//                 event.preventDefault();

//             }}>
//             <FontBoldIcon />
//         </Button>
    
//     </>
//     );
// }

const TopBar = ({
    authData,
    dispatch,
    navbarStatus,
    project,
    document,
    changesMade = false,
    updating = false,
    onSave = () => {}
}) => {
    const { project_id, 
        // doc_id 
    } = useParams();
    const navigate = useNavigation();
    const [ scroll ] = useWindowScroll();

    const onScroll = useMemo(() => {
        return false;
        // return ( scroll.y > 100 ) ? true : false;
    }, [ scroll ] );

    const disabled = useMemo(() => {
        return ( updating ? true : false );
    }, [ updating ] );

    const Content = () => (
    <Grid justify={"center"} gutter="xs">
        <Grid.Col xs={6}>
            <Group>
                { !onScroll && <Button
                    component='a'
                    href={`/projects/${project_id}`}
                    variant="subtle"
                    color="gray"
                    disabled={disabled}
                    leftIcon={<ArrowLeftIcon />}
                    sx={(theme) => ({ 
                        padding: '0px',
                        '&:hover' : {
                            background: 'none',
                            color: theme.colors.dark[5],
                        } 
                    })}
                    onClick={(event) => {
                        event.preventDefault();
                        navigate(`/projects/${project_id}`);
                    }}>
                    Back
                </Button> }
            </Group>
        </Grid.Col>
        <Grid.Col xs={6}>
            <Group position='right'>
                { changesMade ? (
                <Tooltip
                    position='bottom'
                    label="You have unsaved changes"
                    withArrow>
                    <Avatar 
                        color="yellow"
                        radius="100%">
                        <ExclamationTriangleIcon style={{ width: 18, height: 18 }} />
                    </Avatar>
                </Tooltip>
                ) : (
                    <Tooltip
                    position='bottom'
                    label="Document saved"
                    withArrow>
                    <Avatar 
                        color="green" 
                        radius="100%">
                        <CheckCircledIcon style={{ width: 18, height: 18 }} />
                    </Avatar>
                </Tooltip>
                ) }
                <Button
                    color="indigo"
                    size="md"
                    leftIcon={ changesMade ? <UpdateIcon /> : null }
                    loading={updating}
                    disabled={ changesMade ? false : true }
                    onClick={onSave}>
                    { updating ? "Saving..." : ( changesMade ? "Save" : "Saved" ) }
                </Button>
            </Group>
        </Grid.Col>
    </Grid>
    );

    return onScroll ? (
    <Affix 
        position={{ top: 0, left: ( navbarStatus ? 300 : 60 ) }} 
        sx={(theme) => ({
            width: 'calc( 100% - '+( navbarStatus ? 300 : 60 )+'px )',
            backgroundColor: theme.colors.gray[0],
            boxShadow: '0px 0px 10px rgba(0,0,0,0.25)',
        })}>
        <Transition transition="slide-up" mounted={onScroll}>
          {(transitionStyles) => (
            <div style={transitionStyles}>
                <Box sx={(theme) => ({
                    padding: '10px 20px'
                })}>
                    <Content />
                </Box>
            </div>
          )}
        </Transition>
    </Affix>
    ) : <Content />
}

const mapStateToProps = state => {
    return {
        authData: ( state.auth && state.auth.user ) ? state.auth.user : null,
        navbarStatus: ( state.global && state.global.navbar ) ? state.global.navbar : false,
        changesMade: ( state.global && state.global.changes_made ) ? state.global.changes_made : false,
    };
};

export default compose(
    connect(mapStateToProps)
)(TopBar);