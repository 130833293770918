import React, { useMemo, useEffect } from 'react';
import { connect } from "react-redux";
import { AppShell, Box, Divider } from '@mantine/core';

import NavBar from './navbar';
import Header from './header';
import Footer from './footer';

import { useTemplates } from '../../pages/Templates/redux/hooks';
import { useProjects } from '../../pages/Projects/redux/hooks';
import { useRecentDocuments } from '../../pages/Documents/redux/hooks';
import { useTokens } from '../../redux/tokens/hooks';
import { useTeams } from '../../redux/auth/hooks';
import { useScreenSize } from '../../redux/global/hooks';
import { shrinkNavbar } from '../../redux/global/actions';

const MainAppShell = ({
    authData,
    currentTeam,
    dispatch,
    fullWidth = false,
    navbarStatus,
    showNavBar = true,
    showHeader = false,
    showFooter = true,
    children,
}) => {
    const [ screenSize, mobileMode ] = useScreenSize();
    // run core hooks
    useTemplates();
    useProjects();
    useTokens();
    useRecentDocuments({
        authData,
        team_id: ( currentTeam && currentTeam.id ) ? currentTeam.id : 'noteamid'
    });
    useTeams(authData);

    useEffect(() => {
        if ( mobileMode ) {
            // hide navbar
            if ( navbarStatus ) {
                dispatch(shrinkNavbar());
            }
        }
    },[ mobileMode ]);

    const navbarBaseWidth = useMemo(() => {
        return mobileMode ? 0 : ( navbarStatus ? 300 : 60 );
    }, [ navbarStatus, mobileMode ]);

    return (
    <AppShell
        // p={ fullWidth ? 'md' : "xl" }
        navbar={ !mobileMode && <NavBar />}
        header={ mobileMode ? <Header /> : null }
        // zIndex={300}
        styles={(theme) => ({
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[1],
            main: {
                marginLeft: navbarBaseWidth+'px', 
            },
        })}>
        <Box sx={(theme) => ({
            padding: fullWidth ? '0px' : theme.spacing.md,
            minHeight: '100vh'
        })}>
            { children }
        </Box>
        { showFooter ? (
        <>
            <Divider />
            <Footer authData={authData} />
        </>
        ) : null }
    </AppShell>
    )
}

const mapStateToProps = state => {
    return {
        authData: ( state.auth && state.auth.user ) ? state.auth.user : null,
        currentTeam: ( state.auth && state.auth.team ) ? state.auth.team : null,
        navbarStatus: ( state.global && state.global.navbar ) ? state.global.navbar : false,
    };
};

export default connect(mapStateToProps)(MainAppShell);