import React, { useState, useMemo } from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useMantineTheme, Button, Group, Text, Paper } from '@mantine/core';
import { useModals } from '@mantine/modals';
import { useNotifications } from '@mantine/notifications';
import { Pencil2Icon, TrashIcon, CheckIcon } from '@radix-ui/react-icons';

import ButtonIconCircle from '../../components/ButtonIconCircle';

import ProjectFormModal from './modal';

import { GET_PROJECTS } from './redux/types';
import { callFunctionsAPI, getAPIErrorMessage, delayedFunctionCall } from '../../helpers/api';

const Item = ({
    dispatch,
    id = '',
    name = '',
    desc = '',
    team_id = ''
}) => {
    const [ opened, setOpened ] = useState(false);
    const [ loading, setLoading ] = useState(false);
    const [ deleting, setDeleting ] = useState(false);
    const theme = useMantineTheme();
    const modals = useModals();
    const notifications = useNotifications();
    const navigate = useNavigate();

    const disabled = useMemo(() => {
        return loading || deleting ? true : false;
    },[ loading, deleting ]);

    const handleUpdate = (newData) => {
        let error = false;
        // do error check
        // check if name is empty
        if ( !newData.name ) {
            error = 'Name is required';
        }

        // check if errors are empty
        if ( error ) {
            notifications.showNotification({
                color: 'red',
                title: error,
            })
        } else {
            // no errors, update project
            setLoading(true);
            callFunctionsAPI({
                url: 'project',
                action: 'update',
                formData: newData
            })
            .then(results => {
                // get new list
                return delayedFunctionCall({
                    url: 'project',
                    action: 'get_projects',
                });
            })
            .then(list => {
                setLoading(false);
                setOpened(false);
                dispatch({
                    type: GET_PROJECTS,
                    payload: {
                        list,
                    }
                });
                notifications.showNotification({
                    color: 'teal',
                    icon: <CheckIcon />,
                    title: 'Project updated',
                }) 
            })
            .catch(err => {
                setLoading(false);
                notifications.showNotification({
                    color: 'red',
                    title: getAPIErrorMessage(err),
                })
            })
        }
    }

    const handleDelete = () => {
        setDeleting(true);
        notifications.showNotification({
            loading: true,
            disallowClose: true,
            title: 'Deleting project (' + name + ')',
            autoClose: false
        });

        callFunctionsAPI({
            url: 'project',
            action: 'delete',
            formData: { id }
        })
        .then(results => {
            // get new list
            return delayedFunctionCall({
                url: 'project',
                action: 'get_projects',
            });
        })
        .then(list => {
            setDeleting(false);
            dispatch({
                type: GET_PROJECTS,
                payload: {
                    list,
                }
            });
            notifications.clean();
            notifications.showNotification({
                color: 'teal',
                icon: <CheckIcon />,
                title: 'Project ('+name+') deleted',
            }) 
        })
        .catch(err => {
            setDeleting(false);
            notifications.clean();
            notifications.showNotification({
                color: 'red',
                title: getAPIErrorMessage(err),
            }) 
        });
    }

    const openDeleteModal = () => modals.openConfirmModal({
        title: 'Delete Project: ' + name,
        centered: true,
        children: (
        <>
            <Text size="sm">
                Are you sure you want to delete this project ({name})? 
            </Text>
            <Text size="sm" weight={"700"} color="red">
                This action cannot be undone.
            </Text>
        </>
        ),
        labels: { confirm: 'Delete Project', cancel: "No don't delete it" },
        confirmProps: { color: 'red' },
        onConfirm: handleDelete,
    });

    return (
    <>
        <Paper
            shadow="sm" 
            p="lg" 
            sx={(theme) => ({
                border: '1px solid' + theme.colors.gray[2],
                backgroundColor: theme.colors.gray[0],
                // padding: theme.spacing.lg,
                borderRadius: theme.radius.md,
                opacity: ( deleting ? 0.35 : 1 ),
            })}>
            <Group position='apart' grow>
                <div>
                    <Text size="lg" weight={700}>{name}</Text>
                    { desc && desc.length ? <Text size="sm">{desc || ''}</Text> : null }
                </div>
                <Group position='right'>
                    <Button
                        component={ disabled ? null : 'a' }
                        color="indigo"
                        href={`/projects/${id}`}
                        radius={"lg"}
                        size="sm"
                        uppercase
                        disabled={disabled}
                        onClick={(event) => {
                            event.preventDefault();
                            navigate(`/projects/${id}`);
                        }}
                    >
                        Open
                    </Button>
                    <ButtonIconCircle
                        label="Edit"
                        icon={<Pencil2Icon />}
                        btnProps={{
                            disabled
                        }}
                        color={theme.colors.lime[8]}
                        onClick={() => setOpened(true)} />
                    <ButtonIconCircle
                        label="Delete"
                        icon={<TrashIcon />}
                        btnProps={{
                            disabled
                        }}
                        color={theme.colors.red[8]}
                        onClick={openDeleteModal} />
                </Group>
            </Group>
        </Paper>

        <ProjectFormModal 
            opened={opened}
            loading={loading}
            disabled={disabled}
            title={'Edit Project: ' + name}
            actionLabel="Update"
            defaultFormData={{ id, name, desc, team_id }}
            onClose={() => setOpened(false)}
            doAction={handleUpdate} />
    </>
    )
}

export default compose(
    connect()
)(Item);