

// replace all keywords on a given string
export const replaceAll = function( string, search, replacement ) {
	return string.replace(new RegExp(search, 'g'), replacement);
};

// get selected value
export const getSelectedValue = (options,selected,key,returnKey,defaultVal) => {
    let selectedVal = ( options && options.length > 0 && selected && selected.length > 0 && key && key.length > 0 ? options.find(o => selected === o[key]) : false );
    return ( selectedVal && returnKey && returnKey.length > 0 && selectedVal[returnKey] ? selectedVal[returnKey] : ( defaultVal || null ) ); 
}