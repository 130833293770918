import React, { useState, useEffect, useMemo } from 'react';
import { Button, Box, InputWrapper, Input, Space, Textarea, Modal, Group, LoadingOverlay } from '@mantine/core';

const TemplateFormModal = ({
    opened = false,
    title = 'Add New Template',
    actionLabel = 'Add New',
    defaultFormData = {
        name: '',
        desc: '',
    },
    loading = false,
    onClose = () => {},
    doAction = () => {}
}) => {
    const [ formData, setFormData ] = useState({});

    useEffect(() => {
        if ( opened ) {
            setFormData(defaultFormData);
        } else {
            setFormData({});
        }
    },[opened]);

    const disabled = useMemo(() => {
        return loading ? true : false;
    }, [ loading ]);

    const handleFormUpdate = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value
        });
    }

    return (
    <Modal
        opened={opened}
        title={title}
        size='lg'
        centered={true}
        closeOnClickOutside={false}
        onClose={() => onClose()}
        >
        <>
            <LoadingOverlay 
                visible={loading} />
            <Box sx={(theme) => ({
                paddingTop: theme.spacing.xs,
                paddingBottom: theme.spacing.lg,
                borderTop: `1px solid ${theme.colors.gray[1]}`,
                // borderBottom: `1px solid ${theme.colors.gray[1]}`,
            })}>
                <InputWrapper
                    id="template-name"
                    required
                    label="Name"
                    // description="Please enter your credit card information, we need some money"
                    size="sm"
                    >
                    <Input id="template-name" disabled={disabled} placeholder="Ex: Article Introduction" value={( formData.name || '' )} name="name" onChange={handleFormUpdate} />
                </InputWrapper>
                <Space h="md" />
                <InputWrapper
                    id="template-desc"
                    label="Description"
                    size="sm"
                    >
                    <Textarea id="template-desc" disabled={disabled} placeholder="Ex: Write article introduction based on given topic" value={( formData.desc || '' )} name="desc" onChange={handleFormUpdate} />
                </InputWrapper>
            </Box>
            <Group position='right'>
                <Button
                    size='sm'
                    color="dark"
                    variant='outline'
                    disabled={disabled}
                    onClick={() => onClose()}
                    >
                    Cancel
                </Button>
                <Button
                    size='sm'
                    color="indigo"
                    onClick={() => doAction(formData)}
                    disabled={disabled}
                    >
                    {actionLabel}
                </Button>
            </Group>
        </>
    </Modal>
    )
}

export default TemplateFormModal;