
// import { isObjectExists } from '../../helpers/validation';

export const getSelectedText = (editorRef) => {
    const editor = editorRef.current;
    return editor.commands.getSelectedText();
}

export const getTextUntilCursor = (editorRef) => {
    const editor = editorRef.current;
    return editor.commands.getTextUntilCursor();
}

export const shouldAddExtraSpace = (editor) => {
    const { from, to } = editor.state.selection;
    const content = editor.commands.getNodeAtCursorPosition();
    const before = editor.state.doc.textBetween(( to > 0 ? to-1: 0 ), to, "\n\n");
    const after = editor.state.doc.textBetween(from, to+1, "\n\n");
    if ( content && content.text ) {
        if ( before.trim() === '' ) {
            return '';
        } else {
            return " ";
        }
    } else {
        if ( before.trim() === '' ) {
            return '';
        } else {
            return "\n\n";
        }
    }
}

// export const insertTextAfterCursor = (editorRef, text, adjustment = 0) => {
//     const editor = editorRef.current;
//     if ( editor ) {
//         const addExtraSpace = shouldAddExtraSpace(editor);
//         if ( adjustment ) {
//             const index = editor.commands.getCursorPosition();
//             editor.commands.insertContentAt( index+adjustment, addExtraSpace + text );
//         } else {
//             editor.commands.insertContent( addExtraSpace + text );
//         }
//     }
// }