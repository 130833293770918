import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Text, Button } from '@mantine/core';
import { ArrowLeftIcon } from '@radix-ui/react-icons';

const ItemNotFound = ({
    label = 'Item not found.',
    height = '350px',
    goBack = ''
}) => {
    return (
        <Box
            sx={{
                height,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}>
            <div style={{ textAlign: 'center' }}>
                <Text sx={(theme) => ({
                    paddingBottom: theme.spacing.lg
                })}>
                    {label}
                </Text>
                { goBack && goBack.length > 0 ? (
                <Button 
                    component={Link}
                    to={goBack}
                    color="indigo"
                    variant='subtle'
                    leftIcon={<ArrowLeftIcon />}>
                    Go Back
                </Button>
                ) : null }
            </div>
            
        </Box>
    );
}

export default ItemNotFound;