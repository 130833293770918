import React, { useState, useCallback, useEffect } from 'react';
import { compose } from "recompose";
import { useDispatch, useSelector } from "react-redux";
// import { useParams } from "react-router-dom";
import { Card, Space, Grid, Box } from '@mantine/core';

import './styles.css';
import Flow from './flow';


import { shrinkNavbar, expandNavbar, appChangesMade, appChangesReset } from '../../redux/global/actions';
import { useScreenSize } from '../../redux/global/hooks';

const WorkflowCanvas = ({
    height,
    workflow = {},
    onSaveChanges = () => {},
}) => {
    const changesMade = useSelector(state => state.global && state.global.changes_made);
    const [ screenSize, mobileMode ] = useScreenSize();
    const dispatch = useDispatch();
    const [ nodes, setNodes ] = useState([]);
    const [ edges, setEdges ] = useState([]);

    useEffect(() => {
        setNodes(workflow.nodes);
        setEdges(workflow.edges);
    },[ workflow ]);

    useEffect(() => {
        dispatch(shrinkNavbar());
        return () => {
            if ( !mobileMode ) {
                dispatch(expandNavbar());
            }
        }
    },[]);

    return (
    <Grid gutter={20}>
        <Grid.Col span={10}>
            <Box 
                sx={(theme) => ({
                    height,
                    width: '100%',
                    border: '2px solid ' + theme.colors.gray[7],
                    backgroundColor: theme.colors.gray[0],
                })}>
                <Flow
                    nodes={nodes}
                    onNodesChange={setNodes}
                    edges={edges}
                    onEdgesChange={setEdges}
                    />
            </Box>
        </Grid.Col>
        <Grid.Col span={2}>
            abc
        </Grid.Col>
    </Grid>
    );
}

export default WorkflowCanvas;