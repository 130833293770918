import React, { useState, useMemo } from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
// import { useParams } from "react-router-dom";
import { Box, Textarea, Text, Avatar, Group, Space, Divider, Button, SegmentedControl } from '@mantine/core';
// import { useHotkeys } from '@mantine/hooks';
// import { useNotifications } from '@mantine/notifications';
import { ReaderIcon } from '@radix-ui/react-icons';

// import AIModelSelector from '../../modules/AIModelSelector';

// import { toggleGlobalDisabled } from '../../redux/global/actions';


const PanelNotes = ({
    editorRef = null,
    authData,
    navbarStatus,
    dispatch,
    document,
    onUpdate = () => {}
}) => {

    return (
    <Box sx={(theme) => ({
        padding: '0px'
    })}>
        <Group position='left' spacing={"xs"}>
            <Avatar radius={"md"} sx={(theme) => ({
                '& .mantine-Avatar-placeholder': {
                    backgroundColor: theme.colors.indigo[5],
                }
            })}><ReaderIcon size={24} color="#fff" /></Avatar>
            <div>
                <Text size="md" weight='700'>Notes</Text>
                <Text size="xs" color="gray">For capturing quick thought, or sketch ideas.</Text>
            </div>
        </Group>
        <Space h="xs" />
        <Textarea
            value={( document && document.notes || '' )}
            // minRows={35}
            // maxRows={35}
            sx={(theme) => ({
                '& .mantine-Textarea-input': {
                    height: 'calc( 100vh - 120px )' 
                }
            })}
            onChange={(e) => {
                onUpdate({ ...document, notes: e.target.value });
            }}
            />
    </Box>
    );
}

const mapStateToProps = state => {
    return {
        authData: ( state.auth && state.auth.user ) ? state.auth.user : null,
        navbarStatus: ( state.global && state.global.navbar ) ? state.global.navbar : false,
    };
};

export default compose(
    connect(mapStateToProps)
)(PanelNotes);