import React, { useMemo, useState, forwardRef, useEffect } from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
// import { useParams } from "react-router-dom";
import { useModals } from '@mantine/modals';
import { useNotifications } from '@mantine/notifications';
// import { useDidUpdate } from '@mantine/hooks';
import { useMantineTheme, Box, Button, Space, Select, Group, Text, Card, Collapse, Grid } from '@mantine/core';
import { TrashIcon, OpenInNewWindowIcon, CheckIcon, PlusIcon, MinusIcon } from '@radix-ui/react-icons';

import Wizard from '../../modules/Wizard';
import { getDefaultWizardTypeID, getWizardTypeOptions } from '../../modules/Wizard/helpers';

import AIModelSelector from '../../modules/AIModelSelector';

import ButtonIconCircle from '../../components/ButtonIconCircle';

import { isArrayExists } from '../../helpers/validation';
import { callFunctionsAPI, getAPIErrorMessage } from '../../helpers/api';

const PanelWizard = ({
    editorRef = null,
    authData,
    navbarStatus,
    dispatch,
    document,
    team,
    onUpdate = () => {}
}) => {
    const theme = useMantineTheme();
    const modals = useModals();
    const notifications = useNotifications();
    const [ opened, setOpened ] = useState(false);
    const [ type, setType ] = useState( getDefaultWizardTypeID() );
    const [ selectedWizard, setSelectedWizard ] = useState({});
    const [ deleting, setDeleting ] = useState(false);
    const [ showAdvancedSettings, setShowAdvancedSettings ] = useState(false);
    const [ selectedModel, setSelectedModel ] = useState('');

    useEffect(() => {
        if ( document && document.model ) {
            setSelectedModel( document.model );
        }
    },[ document ]);

    const existingWizards = useMemo(() => {
        return document && document.wizards && isArrayExists( document.wizards ) ? document.wizards.filter(item => item.type === type ) : [];
    }, [type, document]);

    const handleStartNewWizard = () => {
        // make sure type is not empty
        if ( type && type.length ) {
            let wizard = {};
            // set model
            if ( selectedModel && selectedModel.length ) {
                wizard.model = selectedModel;
            }
            setOpened(true);
            setSelectedWizard({ ...wizard });
            setSelectedModel(( document && document.model ) ? document.model : '');
            setShowAdvancedSettings(false);
        } else {
            notifications.showNotification({
                message: 'Please select a wizard type.',
                color: 'red'
            });
        }
    }

    const handleContinueWizard = (wizard) => () => {
        setOpened(true);
        setSelectedWizard({ ...wizard });
    }

    const handleDeleteWizard = (wizard) => {
        setDeleting(wizard);
        notifications.showNotification({
            loading: true,
            disallowClose: true,
            title: 'Deleting wizard (' + ( wizard.name || '' ) + ')',
            autoClose: false
        });

        callFunctionsAPI({
            url: 'wizard',
            action: 'delete',
            formData: { wizard_id: wizard.id, document_id: document.id }
        })
        .then(list => {
            setDeleting(false);
            notifications.clean();
            notifications.showNotification({
                color: 'teal',
                icon: <CheckIcon />,
                title: 'Wizard ('+( wizard.name || '' ) +') deleted',
            }) 
        })
        .catch(err => {
            setDeleting(false);
            notifications.clean();
            notifications.showNotification({
                color: 'red',
                title: getAPIErrorMessage(err),
            }) 
        });
    }

    const handleCloseWizard = () => {
        setOpened(false);
    }

    const openDeletWizardModal = (wizard) => () => modals.openConfirmModal({
        title: 'Delete Wizard: ' + wizard.name || '',
        centered: true,
        children: (
        <>
            <Text size="sm">
                Are you sure you want to delete this wizard ({wizard.name || ''})? 
            </Text>
            <Text size="sm" weight={"700"} color="red">
                This action cannot be undone.
            </Text>
        </>
        ),
        labels: { confirm: 'Delete Wizard', cancel: "No don't delete it" },
        confirmProps: { color: 'red' },
        onConfirm: () => handleDeleteWizard(wizard),
    });

    const isWizardDisabled = (item) => {
        return ( item && deleting && item.id === deleting.id ) ? true : false;
    }

    return (
    <Box sx={(theme) => ({
        padding: '0px'
    })}>

        <Select
            label="Choose a Wizard"
            placeholder="Pick one"
            value={type}
            itemComponent={forwardRef(({ label, desc, ...others }, ref) => (
            <div ref={ref} {...others}>
                <Group noWrap>
                    <div>
                    <Text size="sm">{label}</Text>
                    <Text size="xs" color="dimmed">
                        {desc}
                    </Text>
                    </div>
                </Group>
            </div>
            ))}
            data={getWizardTypeOptions()}
            // searchable
            // clearable
            maxDropdownHeight={400}
            nothingFound="No wizard found."
            filter={(value, item) =>
                item.label.toLowerCase().includes(value.toLowerCase().trim()) 
                || item.desc.toLowerCase().includes(value.toLowerCase().trim())
            }
            onChange={(newValue) => setType(newValue)}
            />
        <Space h="sm" />
        
        <Button 
            variant='subtle'
            size="xs"
            compact
            leftIcon={ showAdvancedSettings ? <MinusIcon /> : <PlusIcon />}
            onClick={() => setShowAdvancedSettings( !showAdvancedSettings ) }>
                { showAdvancedSettings ? "Hide Advanced Settings" : "Advanced Settings" }
            </Button>

        <Collapse 
            in={showAdvancedSettings}>
            <Space h="sm" />
            <Card
                p="md"
                withBorder>
                <Grid gutter={20} align="center">
                    <Grid.Col span={3}>
                        <Text size="sm">Model</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                        <AIModelSelector
                            noDivider
                            noLabel
                            team={team}
                            value={selectedModel}
                            onUpdate={(newValue) => {
                                setSelectedModel(newValue);
                            }} />
                    </Grid.Col>
                </Grid>

            </Card>
        </Collapse>

        <Space h="md" />

        <Button 
            color="indigo" 
            fullWidth
            onClick={handleStartNewWizard}>
            Start New Wizard</Button>

        <Space h="sm" />

        { existingWizards && isArrayExists( existingWizards ) && (
        <>
            <Space h="sm" />
            <Card
                p="lg"
                radius={"md"}
                withBorder>
                <Text size="md" weight="bold">
                    Existing Wizard(s)
                </Text>
                { existingWizards.map( (wizard, index) => (
                <Group 
                    key={wizard.id}
                    position='apart'
                    align='flex-start'
                    noWrap 
                    spacing={"lg"}
                    sx={(theme) => ({
                        borderTop: '1px solid ' + theme.colors.gray[3],
                        paddingTop: theme.spacing.sm,
                        marginTop: theme.spacing.sm,
                        opacity: isWizardDisabled(wizard) ? 0.5 : 1
                    })}>
                    <Box
                        sx={(theme) => ({
                            paddingRight: theme.spacing.xs,
                            maxWidth: '70%'
                        })}>
                        <Text size="sm">{wizard.name || ''}</Text>
                        <Text size="xs" color="dimmed">ID: {wizard.id || ''}</Text>
                    </Box>
                    <Group
                        pt="5px"
                        position='right'
                        spacing={"2px"}>
                        <ButtonIconCircle
                            icon={<OpenInNewWindowIcon />}
                            label="Continue"
                            btnProps={{
                                variant: "filled",
                                color: 'indigo',
                                radius: 'md',
                                size: 'xs',
                                disabled: isWizardDisabled(wizard),
                            }}
                            color={theme.colors.indigo[4]}
                            onClick={handleContinueWizard(wizard)}
                        />
                        <ButtonIconCircle
                            icon={<TrashIcon />}
                            label="Remove"
                            btnProps={{
                                variant: "subtle",
                                color: 'red',
                                radius: 'md',
                                size: 'xs',
                                disabled: isWizardDisabled(wizard),
                            }}
                            color={theme.colors.red[8]}
                            onClick={openDeletWizardModal(wizard)}
                        /> 
                    </Group>
                </Group>
                ))}
            </Card>
        </>
        )}

        <Wizard
            opened={opened}
            editorRef={editorRef}
            type={type}
            document={document}
            selectedWizard={selectedWizard}
            onUpdate={onUpdate}
            onClose={handleCloseWizard}
            />
    </Box>
    );
}

const mapStateToProps = state => {
    return {
        authData: ( state.auth && state.auth.user ) ? state.auth.user : null,
        navbarStatus: ( state.global && state.global.navbar ) ? state.global.navbar : false,
        document: ( state.documents && state.documents.document ) ? state.documents.document : null,
        team: ( state.teams && state.teams.team ) ? state.teams.team : null,
    };
};

export default compose(
    connect(mapStateToProps)
)(PanelWizard);