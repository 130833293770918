import React, { useMemo, useEffect } from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
// import { useParams } from "react-router-dom";
import { Box, Button, Group, Tooltip, LoadingOverlay } from '@mantine/core';
import { useWindowScroll, useResizeObserver } from '@mantine/hooks';
import { FontBoldIcon, FontItalicIcon, StrikethroughIcon, Link2Icon, CodeIcon, QuoteIcon, DividerHorizontalIcon, UnderlineIcon, TextAlignLeftIcon, TextAlignCenterIcon, TextAlignRightIcon, TextAlignJustifyIcon } from '@radix-ui/react-icons';
import { useEditor, EditorContent, Extension } from '@tiptap/react';
import Document from '@tiptap/extension-document';
import Paragraph from '@tiptap/extension-paragraph';
import Text from '@tiptap/extension-text';
import Link from '@tiptap/extension-link';
import Underline from '@tiptap/extension-underline';
import TextAlign from '@tiptap/extension-text-align';
import CodeBlockLowlight from '@tiptap/extension-code-block-lowlight'
import StarterKit from '@tiptap/starter-kit';
import { lowlight } from 'lowlight';

// import { isObjectExists } from '../../helpers/validation';
import { tiptapGetMarkDownBySelection } from '../../helpers/tiptap';

import { useScreenSize } from '../../redux/global/hooks';

const getExtensions = (minimal) => {
    let extensions = [
        Extension.create({
            addCommands: () => {
                return {
                    getSelection: (format = 'text') => ({ editor }) => {
                        const { from, to, empty } = editor.state.selection
        
                        if (empty) {
                            return null
                        }
        
                        return { from, to };
                    },
                    getSelectedText: (format = 'text') => ({ editor }) => {
                        const { from, to, empty } = editor.state.selection
        
                        if (empty) {
                            return null
                        }
        
                        return editor.state.doc.textBetween(from, to, "\n\n")
                    },
                    getTextUntilCursor: (format = 'text') => ({ editor }) => {
                        const { to } = editor.state.selection;
                        const { node, index, offset } = editor.state.doc.childAfter(to);
                        switch( format ) {
                            case 'markdown':
                                return tiptapGetMarkDownBySelection({
                                   start: 0, 
                                   until: index, 
                                   offset, 
                                   editor, 
                                   node
                                });
                            default:
                                return editor.state.doc.textBetween(0, to, "\n\n")
                        }
                    },
                    getCursorPosition: () => ({ editor }) => {
                        const { to } = editor.state.selection
                        return to || 0;
                    },
                    getNodeAtCursorPosition: () => ({ editor }) => {
                        const { to } = editor.state.selection
                        return editor.state.doc.nodeAt(to)
                    },
                    getContentLength: () => ({ editor }) => {
                        return editor.state.doc.nodeSize
                    }
                }
            },
        })
    ]; 

    if( minimal ) {
        extensions = [ 
            ...extensions,
            Document,
            Paragraph,
            Text,
            CodeBlockLowlight.configure({
                lowlight,
                HTMLAttributes: {
                    class: 'code-block-highlight',
                }
            }),
        ];
    } else {
        extensions = [ 
            ...extensions,  
            StarterKit.configure({
                codeBlock: false,
                code: {
                    HTMLAttributes: {
                        class: 'code-hightlight',
                    },
                }
            }),
            Link.configure({
                autolink: true,
                openOnClick: false,
            }),
            Underline,
            TextAlign.configure({
                types: ['heading', 'paragraph']
            }),
            CodeBlockLowlight.configure({
                lowlight,
                HTMLAttributes: {
                    class: 'code-block-highlight',
                }
            }),
        ];
    } // end - minimal

    return extensions; 
};

const Editor = ({
    editorRef = null,
    authData,
    editorDisabled = false,
    navbarStatus,
    dispatch,
    documentData,
    updating = false,
    onUpdate = () => {}
}) => {
    const [ scroll ] = useWindowScroll();
    const [ menuRef, menuRect] = useResizeObserver();
    const [ screenSize, mobileMode ] = useScreenSize();

    const isCodexModel = useMemo(() => {
        // return documentData && ( documentData.model === 'code-davinci-002' );
        return false;
    }, [ documentData ]);

    const menuScrollStyles = useMemo(() => {
        return scroll.y > 160 ? {
            position: 'fixed',
            top: '0px',
            left: '78px',
            width: menuRect.width,
            zIndex: 2,
        } : {};
    }, [ scroll, menuRect ] );

    const editor = useEditor({
        content: documentData.content || '',
        extensions: getExtensions( isCodexModel )
    }, [ isCodexModel ]);

    useEffect(() => {
        if ( editor ) {
            editor.off("update");
            editor.on("update", ({ editor: updatedEditor }) => onUpdate({ ...documentData, content: updatedEditor.getHTML() }));
        }
    }, [ editor, onUpdate ]);

    const menuItems = useMemo(() => {
        return editor ? isCodexModel ? [
            {
                id: 'misc',
                items: [
                    {
                        id: 'code',
                        label: 'Code',
                        icon: <i className="ri-code-s-fill"></i>,
                        onClick: () => editor.chain().focus().toggleCode().run()
                    },
                    {
                        id: 'codeBlock',
                        label: 'Code block',
                        icon: <i className="ri-code-s-slash-line"></i>,
                        onClick: () => editor.chain().focus().toggleCodeBlock().run()
                    },
                ]
            },
        ] : [
            {
                id: 'font',
                items: [
                    {
                        id: 'bold',
                        label: 'Bold',
                        icon: <FontBoldIcon />,
                        onClick: () => editor.chain().focus().toggleBold().run()
                    },
                    {
                        id: 'italic',
                        label: 'Italic',
                        icon: <FontItalicIcon />,
                        onClick: () => editor.chain().focus().toggleItalic().run()
                    },
                    {
                        id: 'strike',
                        label: 'Strike',
                        icon: <StrikethroughIcon />,
                        onClick: () => editor.chain().focus().toggleStrike().run()
                    },
                    {
                        id: 'underline',
                        label: 'Underline',
                        icon: <UnderlineIcon />,
                        onClick: () => editor.chain().focus().toggleUnderline().run()
                    }
                ]
            },
            {
                id: 'typograhpy',
                items: [
                    {
                        id: 'h1',
                        label: 'H1',
                        icon: <span>H1</span>,
                        onClick: () => editor.chain().focus().toggleHeading({ level: 1 }).run()
                    },
                    {
                        id: 'h2',
                        label: 'H2',
                        icon: <span>H2</span>,
                        onClick: () => editor.chain().focus().toggleHeading({ level: 2 }).run()
                    },
                    {
                        id: 'h3',
                        label: 'H3',
                        icon: <span>H3</span>,
                        onClick: () => editor.chain().focus().toggleHeading({ level: 3 }).run()
                    },
                    {
                        id: 'h4',
                        label: 'H4',
                        icon: <span>H4</span>,
                        onClick: () => editor.chain().focus().toggleHeading({ level: 4 }).run()
                    }
                ]
            },
            {
                id: 'lists',
                items: [
                    {
                        id: 'bulletList',
                        label: 'Bullet list',
                        icon: <i className="ri-list-unordered"></i>,
                        onClick: () => editor.chain().focus().toggleBulletList().run()
                    },
                    {
                        id: 'orderedList',
                        label: 'Ordered list',
                        icon: <i className="ri-list-ordered"></i>,
                        onClick: () => editor.chain().focus().toggleOrderedList().run()
                    },
                ]
            },
            {
                id: 'align',
                items: [
                    {
                        id: 'left',
                        label: 'Align Left',
                        icon: <TextAlignLeftIcon />,
                        onClick: () => editor.chain().focus().setTextAlign('left').run()
                    },
                    {
                        id: 'center',
                        label: 'Align Center',
                        icon: <TextAlignCenterIcon />,
                        onClick: () => editor.chain().focus().setTextAlign('center').run()
                    },
                    {
                        id: 'right',
                        label: 'Align Right',
                        icon: <TextAlignRightIcon />,
                        onClick: () => editor.chain().focus().setTextAlign('right').run()
                    },
                    {
                        id: 'justify',
                        label: 'Align Justify',
                        icon: <TextAlignJustifyIcon />,
                        onClick: () => editor.chain().focus().setTextAlign('justify').run()
                    }
                ]
            },
            {
                id: 'misc',
                items: [
                    // {
                    //     id: 'link',
                    //     label: 'Link',
                    //     icon: <Link2Icon />,
                    //     onClick: () => {
    
                    //     }
                    // },
                    {
                        id: 'code',
                        label: 'Code',
                        icon: <i className="ri-code-s-fill"></i>,
                        onClick: () => editor.chain().focus().toggleCode().run()
                    },
                    {
                        id: 'codeBlock',
                        label: 'Code block',
                        icon: <i className="ri-code-s-slash-line"></i>,
                        onClick: () => editor.chain().focus().toggleCodeBlock().run()
                    },
                    {
                        id: 'blockquote',
                        label: 'Blockquote',
                        icon: <QuoteIcon />,
                        onClick: () => editor.chain().focus().toggleBlockquote().run()
                    },
                    {
                        id: 'horizontalRule',
                        label: 'Horizontal rule',
                        icon: <DividerHorizontalIcon />,
                        onClick: () => editor.chain().focus().setHorizontalRule().run()
                    },
                    // {
                    //     id: 'hardBreak',
                    //     label: 'Hard break',
                    //     icon: '',
                    //     onClick: () => editor.chain().focus().setHardBreak().run()
                    // },
                ]
            },
            {
                id: 'edit',
                items: [
                    {
                        id: 'undo',
                        label: 'Undo',
                        disabled: ( editor.can && editor.can().undo ? editor.can().undo() ? false : true : true ),
                        icon: <i className="ri-arrow-go-back-line"></i>,
                        onClick: () => editor.chain().focus().undo().run()
                    },
                    {
                        id: 'redo',
                        label: 'Redo',
                        disabled: ( editor.can && editor.can().redo ? editor.can().redo() ? false : true : true ),
                        icon: <i className="ri-arrow-go-forward-line"></i>,
                        onClick: () => editor.chain().focus().redo().run()
                    }
                ]
            }
        ] : null;
    }, [ editor, isCodexModel ] );

    const disabled = useMemo(() => {
        return editorDisabled ? true : false;
    }, [ editorDisabled ] );

    if ( !editor )
        return null;


    const isActive = (id) => {
        switch( id ) {
            case 'h1': return editor.isActive( 'heading', { level: 1 } );
            case 'h2': return editor.isActive( 'heading', { level: 2 } );
            case 'h3': return editor.isActive( 'heading', { level: 3 } );
            case 'h4': return editor.isActive( 'heading', { level: 4 } );
            default:
                return editor.isActive(id);
        }
    }

    editorRef.current = editor;

    return (
    <Box>
        { editor && !mobileMode && (
        <Box 
            ref={menuRef}
            sx={(theme) => ({ 
                backgroundColor: theme.colors.gray[1], 
                padding: theme.spacing.md,
                borderBottom: '1px solid ' + theme.colors.gray[5],
                ...menuScrollStyles
            })}>
            <Group 
                spacing={"10px"}>
                { menuItems.map( group => (
                <Box
                    key={group.id}>
                    <Group spacing={"2px"}>
                    { group.items.map( item => (
                    <Tooltip
                        key={item.id}
                        disabled={item.disabled ? true : false }
                        position="top"
                        withArrow
                        label={item.label || ""}
                        >
                        <Button 
                            radius="sm" 
                            size="sm"
                            p="xs"
                            color="gray"
                            variant={ isActive(item.id) ? 'filled' : 'outline' }
                            // variant='filled'
                            disabled={item.disabled ? true : false }
                            sx={(theme) => ({
                                // height: '20px',
                                // padding: '4px',
                            })}
                            onClick={item.onClick}>
                            { item.icon }
                        </Button>
                    </Tooltip>
                    ))}
                    </Group>
                </Box>
                )) }
            </Group>
        </Box>
        ) }
        <Box
            className='main-editor-wrapper' 
            sx={(theme) => ({
                overflow: 'hidden',
                paddingTop: theme.spacing.xl,
                paddingBottom: theme.spacing.xl,
                paddingLeft: mobileMode ? '0px' : '50px',
                paddingRight: mobileMode ? '0px' : '50px',
                minHeight: '100vh',
                '& div.ProseMirror': {
                    opacity: ( disabled ? 0.5 : 1 ),
                    '& h1': { fontSize: '32px' },
                    '& h2': { fontSize: '24px' },
                    '& h3': { fontSize: '18px' },
                    '& h4': { fontSize: '16px' },
                    '& h5': { fontSize: '14px' },
                    '& h6': { fontSize: '12px' },
                    '& h1, & h2, & h3, & h4, & h5, & h6': {
                        margin: "30px 0 15px 0",
                        fontWeight: "bold"
                    },
                    'p, ul, ol, blockquote': {
                        marginBottom: '15px'
                    },
                    '&.ProseMirror-focused': {
                        outline: 'none',
                        boxShadow: 'none'
                    }
                }
            })}
            onClick={() => {
                editor.commands.focus();
            }}>
            <EditorContent 
                editor={editor} />
        </Box>
    </Box>
    );
}

const mapStateToProps = state => {
    return {
        authData: ( state.auth && state.auth.user ) ? state.auth.user : null,
        editorDisabled: ( state.global && state.global.editor_disabled ) ? state.global.editor_disabled : false,
        navbarStatus: ( state.global && state.global.navbar ) ? state.global.navbar : false,
    };
};

export default compose(
    connect(mapStateToProps)
)(Editor);