import React, { useMemo } from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { Grid, Button, Group, Tooltip, Avatar } from '@mantine/core';
// import { useWindowScroll } from '@mantine/hooks';
import { ArrowLeftIcon, UpdateIcon, ExclamationTriangleIcon, CheckCircledIcon } from '@radix-ui/react-icons';

import { useNavigation } from '../../redux/global/hooks';

const TopBar = ({
    authData,
    dispatch,
    navbarStatus,
    changesMade = false,
    updating = false,
    onSave = () => {}
}) => {
    // const { 
    //     template_id, 
    // } = useParams();
    const navigate = useNavigation();

    const disabled = useMemo(() => {
        return ( updating ? true : false );
    }, [ updating ] );

    const Content = () => (
    <Grid justify={"center"} gutter="xs">
        <Grid.Col xs={12} sm={6}>
            <Group>
                <Button
                    component='a'
                    href={`/templates`}
                    variant="subtle"
                    color="gray"
                    disabled={disabled}
                    leftIcon={<ArrowLeftIcon />}
                    sx={(theme) => ({ 
                        padding: '0px',
                        '&:hover' : {
                            background: 'none',
                            color: theme.colors.dark[5],
                        } 
                    })}
                    onClick={(event) => {
                        event.preventDefault();
                        navigate(`/templates`);
                    }}>
                    Back
                </Button>
            </Group>
        </Grid.Col>
        <Grid.Col xs={12} sm={6}>
            <Group position='right'>
                { changesMade ? (
                <Tooltip
                    position='bottom'
                    label="You have unsaved changes"
                    withArrow>
                    <Avatar 
                        color="yellow"
                        radius="100%">
                        <ExclamationTriangleIcon style={{ width: 18, height: 18 }} />
                    </Avatar>
                </Tooltip>
                ) : (
                    <Tooltip
                    position='bottom'
                    label="Document saved"
                    withArrow>
                    <Avatar 
                        color="green" 
                        radius="100%">
                        <CheckCircledIcon style={{ width: 18, height: 18 }} />
                    </Avatar>
                </Tooltip>
                ) }
                <Button
                    color="indigo"
                    size="md"
                    leftIcon={ changesMade ? <UpdateIcon /> : null }
                    loading={updating}
                    disabled={ changesMade ? false : true }
                    onClick={onSave}>
                    { updating ? "Saving..." : ( changesMade ? "Save" : "Saved" ) }
                </Button>
            </Group>
        </Grid.Col>
    </Grid>
    );

    return <Content />
}

const mapStateToProps = state => {
    return {
        authData: ( state.auth && state.auth.user ) ? state.auth.user : null,
        navbarStatus: ( state.global && state.global.navbar ) ? state.global.navbar : false,
        changesMade: ( state.global && state.global.changes_made ) ? state.global.changes_made : false,
    };
};

export default compose(
    connect(mapStateToProps)
)(TopBar);