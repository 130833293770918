import React, { useMemo, useState, forwardRef, useRef, useEffect } from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
// import { useParams } from "react-router-dom";
import { Box, Card, Group, Text, Space, LoadingOverlay, Button, ScrollArea, Textarea, Spoiler, Avatar, Popover } from '@mantine/core';
import { useModals } from '@mantine/modals';
import { useNotifications } from '@mantine/notifications';
import { useDidUpdate, useViewportSize } from '@mantine/hooks';
import { GearIcon, TrashIcon, ReaderIcon, PlusIcon, MinusIcon } from '@radix-ui/react-icons';

import FormSettings from '../../pages/Template/form_settings';
import Result from '../TemplatesSelector/result';
import { getBaseTemplateOptions, getBaseTemplateFields, getBaseTemplateData } from '../TemplatesSelector/helpers';

// import Loader from '../../components/Loader';

import { isArrayExists, isObjectExists } from '../../helpers/validation';
import { callFunctionsAPI, getAPIErrorMessage } from '../../helpers/api';

import { toggleGlobalDisabled } from '../../redux/global/actions';

import { UPDATE_DOCUMENT_SETTINGS } from '../../pages/Documents/redux/types';

const Playground = ({
    editorRef = null,
    authData,
    globalDisabled,
    dispatch,
    drawerMode = false,
    document,
    documentSettings = {},
    team = {},
    onUpdate = () => {}, 
}) => {
    const viewport = useRef();
    const modals = useModals();
    const notifications = useNotifications();
    const { height } = useViewportSize();
    const [ loading, setLoading ] = useState(false);
    const [ formData, setFormData ] = useState({});
    const [ results, setResults ] = useState([]);
    const [ settingsOpened, setSettingsOpened ] = useState(false);

    useEffect(() => {
        if ( results && isArrayExists( results ) ) {
            viewport.current.scrollTo({ 
                top: ( formData.mode && formData.mode === 'edits' ) ? 500 : 400, 
                behavior: 'smooth' 
            });
        }
    }, [ results ] );

    useEffect(() => {
        let mounted = true;
        if ( mounted ) {
            setFormData(documentSettings && documentSettings.playground_form_data ? documentSettings.playground_form_data : {
                model: 'text-curie-001',
                engine: 'openai',
                mode: 'completions',
                prompt: '',
                instruction: '',
                max_tokens: 256,
                n: 1,
                stop: [],
                temperature: 0.9,
                top_p: 1,
                presence_penalty: 0,
                frequency_penalty: 0,
            });
            setResults(documentSettings && documentSettings.playground_results ? documentSettings.playground_results : []);
        }
        return () => {  
            mounted = false;
        }
    },[]);

    // update to redux
    useDidUpdate(() => {
        dispatch({
            type: UPDATE_DOCUMENT_SETTINGS,
            payload: {
                ...documentSettings,
                playground_form_data: formData,
                playground_results: results,
            }
        })
    },[ formData, results ]);

    const displayResults = useMemo(() => {
        // only display results for the selected template
        return results.filter(result => result.template === 'playground');
    }, [ results ] );

    const handleGenerate = () => {
        let error = false

        // do error check
        if ( !( formData.prompt && formData.prompt !== '' ) )
            error = "Please enter a prompt.";

        if ( formData.mode && formData.mode === 'inserts' ) {
            if ( !( formData.prompt && formData.prompt.indexOf("[insert]") >= 0 ) )
                error = "Please enter an [insert] tag.";
        }

        if ( formData.mode && formData.mode === 'edits' ) {
            if ( !( formData.instruction && formData.instruction !== '' ) )
                error = "Please enter an instruction.";
        }

        if ( error ) {
            notifications.showNotification({
                color: 'red',
                title: error,
            }) 
        } else {
            setLoading(true);
            setSettingsOpened(false);
            dispatch(toggleGlobalDisabled(true));
            callFunctionsAPI({
                url: 'template',
                action: 'playground',
                formData: {
                    team_id: ( team && team.id ) || '',
                    document_id: document.id,
                    ...formData
                }
            })
            .then( newResults => {
                setLoading(false);
                dispatch(toggleGlobalDisabled(false));
                setResults([ 
                    ...newResults, 
                    ...results,
                ]);
            })
            .catch( error => {
                setLoading(false);
                dispatch(toggleGlobalDisabled(false));
                notifications.showNotification({
                    message: getAPIErrorMessage( error ),
                    color: 'red'
                })
            });
        } // end - error
    }

    const handleClearAll = () => {
        // remove all the results for this template
        setResults([ ...results.filter( result => result.template !== 'playground' ) ]);
    }

    const handleResultAdd = (result) => {
        const editor = editorRef.current;
        const content = editor.commands.getNodeAtCursorPosition();
        if ( content && content.text && content.text !== '' ) {
            // prepend a blank space to the new result
            editor.commands.insertContent( ' ' + ( result.text || '' ) );
        } else {
            editor.chain().enter().insertContent( ( result.text || '' ) ).run();
        }
    }

    const handleResultReplace = (result) => {
        const editor = editorRef.current;
        const selection = editor.commands.getSelection();
        if ( selection ) {
            // delete existing selection
            editor.chain().deleteSelection().insertContent(result.text || '').run();
        } else {
            notifications.showNotification({
                message: 'Please select the text you want to replace',
                color: 'red'
            })
        }
    }

    const handleResultAddToNotes = (result) => {
        const newNotes = ( document && document.notes || '' );
        onUpdate({ ...document, notes: newNotes + '\n\n' + result.text });
    }

    const handleResultDelete = (result) => {
        setResults([ ...results.filter( item => item.id !== result.id ) ]);
    }

    const openClearAllResults = () => modals.openConfirmModal({
        title: `Clear all the results`,
        centered: true,
        children: (
        <>
            <Text size="sm">
                Are you sure you want to clear all the results? 
            </Text>
            {/* <Text size="sm" weight={"700"} color="red">
                This action cannot be undone.
            </Text> */}
        </>
        ),
        labels: { confirm: 'Clear All', cancel: "Cancel" },
        confirmProps: { color: 'red' },
        onConfirm: () => handleClearAll(),
    });

    return (
    <Box sx={(theme) => ({
        paddingBottom: (theme.spacing.lg*2)+'px',
        height: height - 36
        })}>
        <ScrollArea 
            viewportRef={viewport}
            sx={(theme) => ({
                marginTop: '-' + theme.spacing.lg + 'px',
                marginLeft: '-' + theme.spacing.lg + 'px',
                marginRight: '-' + theme.spacing.lg + 'px',
                padding: theme.spacing.lg,
                height: 'calc( 100% - 20px )',
            })}> 

            <Group position='left' spacing={"xs"}>
                <Avatar radius={"md"} sx={(theme) => ({
                    '& .mantine-Avatar-placeholder': {
                        backgroundColor: theme.colors.indigo[5],
                    }
                })}><ReaderIcon size={24} color="#fff" /></Avatar>
                <div>
                    <Text size="md" weight='700'>Playground</Text>
                    <Text size="xs" color="gray">For generating quick result(s).</Text>
                </div>
            </Group>

            <Space h="sm" />

            <Card
                pt="sm"
                pb="sm"
                pl="md"
                pr="md"
                radius={"md"}
                // shadow={"md"}
                withBorder
                sx={(theme) => ({
                    // minHeight: '100%'
                })}>
                <LoadingOverlay visible={loading} />

                <Textarea
                    label={ formData.mode && formData.mode === 'edits' ? 'Inputs' : 'Prompts' }
                    value={ formData.prompt || '' }
                    required
                    minRows={10}
                    maxRows={30}
                    onChange={(e) => setFormData({ ...formData, prompt: e.target.value })}
                    />

                { formData.mode && formData.mode === 'edits' && (
                <>
                    <Space h="sm" />
                    <Textarea
                        label="Instructions"
                        value={ formData.instruction || '' }
                        minRows={2}
                        onChange={(e) => setFormData({ ...formData, instruction: e.target.value })}
                        />
                </>
                ) }

                <Space h="sm" />

                <Popover
                    opened={settingsOpened}
                    onClose={() => setSettingsOpened(false)}
                    target={(
                    <Button 
                        variant='subtle'
                        size="sm"
                        color="blue"
                        compact
                        leftIcon={ settingsOpened ? <MinusIcon /> : <PlusIcon /> }
                        onClick={() => setSettingsOpened((m) => !m)}>
                        { settingsOpened ? 'Close' : 'Open' } Settings
                    </Button>
                    )}
                    withArrow
                    withCloseButton
                    placement="center"
                    position={ drawerMode ? 'top' : 'left' }
                    shadow={"lg"}
                    closeOnClickOutside={false}
                    width={ drawerMode ? '80vw' : 350 }
                    >
                    { drawerMode ? (
                    <ScrollArea
                        style={{
                            height: height/3,
                        }}>
                        <FormSettings
                            authData={authData}
                            formData={formData}
                            currentTeam={team}
                            onUpdate={(newValue) => setFormData(newValue)}
                            />
                    </ScrollArea>
                    ) : (
                    <FormSettings
                        authData={authData}
                        formData={formData}
                        currentTeam={team}
                        onUpdate={(newValue) => setFormData(newValue)}
                        />
                    ) }
                </Popover>

                <Space h="sm" />

                <Button
                    color="indigo"
                    radius={"lg"}
                    leftIcon={<GearIcon />}
                    fullWidth
                    loading={loading}
                    onClick={handleGenerate}>
                    Generate
                </Button>

                <Space h="sm" />
                
            </Card>

            { displayResults && isArrayExists( displayResults ) && (
            <>
                <Space h="lg" />
                <Group 
                    position='right'>
                    <Button
                        variant='light'
                        color="red"
                        leftIcon={<TrashIcon />}
                        onClick={openClearAllResults}>
                        Clear All
                    </Button>
                </Group>
                
                <Space h="sm" />

                <Box
                    sx={(theme) => ({
                        // padding: '0 15px 0 0',
                        '& .mantine-Card-root': {
                            marginBottom: theme.spacing.sm,
                            '&:last-child': {
                                marginBottom: 0,
                            },
                        }
                    })}>
                    { results.map( ( result, index ) => (
                    <Result
                        key={result.id}
                        result={result}
                        onAdd={handleResultAdd}
                        onReplace={handleResultReplace}
                        onAddToNotes={handleResultAddToNotes}
                        onDelete={handleResultDelete} />
                    ))}
                </Box>
            </>   
            )}
        </ScrollArea>
    </Box>
    );
}

const mapStateToProps = state => {
    return {
        authData: ( state.auth && state.auth.user ) ? state.auth.user : null,
        globalDisabled: ( state.global && state.global.disabled ) ? state.global.disabled : false,
        documentSettings: ( state.documents && state.documents.document_settings ) ? state.documents.document_settings : null,
        team: ( state.teams && state.teams.team ) ? state.teams.team : null,
    };
};

export default compose(
    connect(mapStateToProps)
)(Playground);