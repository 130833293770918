import React, { useState, useMemo } from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
// import { useParams } from "react-router-dom";
import { Card, Space, Grid, Box, TextInput, Textarea, Avatar, Group, Text, Divider, SegmentedControl, Checkbox, CheckboxGroup } from '@mantine/core';
// import { useDidUpdate, useDebouncedValue } from '@mantine/hooks';
// import { useNotifications } from '@mantine/notifications';
import { GearIcon, CodeIcon } from '@radix-ui/react-icons';

import FormSettings from './form_settings';

// import ButtonIconCircle from '../../components/ButtonIconCircle';
import FormRangeInput from '../../components/FormRangeInput';
import FormItems from '../../components/FormItems';
import AIModelSelector from '../../modules/AIModelSelector';

import { isArrayExists } from '../../helpers/validation';
import { getCustomModel } from '../../helpers/ai';

// import { useTemplate } from '../Templates/redux/hooks';

const TemplateForm = ({
    authData,
    currentTeam,
    projectsList = [],
    dispatch,
    formData = {},
    onUpdate = () => {}
}) => {

    const templateEngine = useMemo(() => {
        return getCustomModel( ( formData.model || '' ), currentTeam, 'engine' );
    }, [ formData, currentTeam ] );

    return (
    <Grid gutter="20px">
        <Grid.Col sm={12} md={7}>
            <Box
                sx={(theme) => ({ padding: theme.spacing.lg })}>
                <TextInput
                    label="Name"
                    required
                    value={formData.name}
                    onChange={(e) => {
                        onUpdate({ ...formData, name: e.target.value });
                    }} />
                <Space h="md" />
                <Textarea
                    label="Description"
                    value={formData.desc}
                    minRows={2}
                    onChange={(e) => {
                        onUpdate({ ...formData, desc: e.target.value });
                    }} />
                <Space h="md" />
                <Textarea
                    label="Prompt"
                    placeholder='Write an article introduction on [INPUT]:'
                    required
                    value={formData.prompt}
                    minRows={20}
                    maxRows={20}
                    onChange={(e) => {
                        onUpdate({ ...formData, prompt: e.target.value });
                    }} />
                { formData.mode && formData.mode === 'edits' && (
                <>
                    <Space h="sm" />
                    <Textarea
                        label="instruction"
                        placeholder='fix the grammer'
                        required
                        value={formData.instruction}
                        minRows={3}
                        maxRows={5}
                        onChange={(e) => {
                            onUpdate({ ...formData, instruction: e.target.value });
                        }} />
                </>
                ) }
                <Space h="md" />
                <FormItems
                    label="Inputs"
                    itemLabel="input"
                    newItemProps={{
                        label: '',
                        code: ''
                    }}
                    fields={[
                        { 
                            id: 'label', 
                            label: 'Label', 
                            type: 'text', 
                            required: true, 
                            unique: false,
                            disabled: false,
                            filter: (newValue) => {
                                // only allow alpha-numeric, space, dash, underscore
                                return (newValue.trim()).replace(/[^a-zA-Z0-9\s-_]/g, '');
                            },
                            modifier: (newData,action) => {
                                // auto-populate code based on label
                                return ( action === 'add' ? { ...newData, code: ( newData.label ? (newData.label.replace(/[^a-zA-Z0-9]/g, '').trim()).toUpperCase() : '' )} : { ...newData } );
                            }
                        },
                        { 
                            id: 'code', 
                            label: 'Shortcode', 
                            type: 'text', 
                            required: true, 
                            unique: 'Shortcode is already taken',
                            disabled: [ 'add' ],
                            filter: (newValue) => {
                                // only allow alpha-numeric
                                return (newValue.replace(/[^a-zA-Z0-9]/g, '').trim()).toUpperCase();
                            },
                            modifier: (newData,action) => {
                                // auto-populate code based on label
                                return ( action === 'edit' ? { ...newData, code: ( newData.code ? (newData.code.replace(/[^a-zA-Z0-9]/g, '').trim()).toUpperCase() : '' )} : { ...newData } );
                            }
                        },
                    ]}
                    showCopy={true}
                    copyProps={{
                        label: 'Get Shortcode',
                        success_label: 'Shortcode copied to clipboard',
                        key: 'code',
                        icon: <CodeIcon />,
                        filter: (value) => {
                            return '[' + value + ']';
                        }
                    }}
                    value={formData.inputs}
                    onUpdate={(newValue) => {
                        onUpdate({ ...formData, inputs: newValue });
                    }} />
            </Box>
        </Grid.Col>
        <Grid.Col sm={12} md={5}>
            <Card
                p="md"
                m="sm"
                mt="xl"
                radius={"md"}
                shadow={"none"}
                withBorder>

                <Group position='left' spacing={"xs"}>
                    <Avatar radius={"md"} sx={(theme) => ({
                        '& .mantine-Avatar-placeholder': {
                            backgroundColor: theme.colors.indigo[5],
                        }
                    })}><GearIcon size={24} color="#fff" /></Avatar>
                    <div>
                        <Text size="md" weight='700'>Settings</Text>
                        <Text size="xs" color="gray">Prompt Settings for this template</Text>
                    </div>
                </Group>

                <Space h="sm" />

                <FormSettings
                    authData={authData}
                    currentTeam={currentTeam}
                    formData={formData}
                    onUpdate={onUpdate} />
                
                <Space h="md" />
                <Divider />
                <Space h="md" />

                <Group position='left' spacing={"xs"}>
                    <Avatar radius={"md"} sx={(theme) => ({
                        '& .mantine-Avatar-placeholder': {
                            backgroundColor: theme.colors.indigo[5],
                        }
                    })}><GearIcon size={24} color="#fff" /></Avatar>
                    <div>
                        <Text size="md" weight='700'>Projects</Text>
                        <Text size="xs" color="gray">You can assign this template to certain projects only</Text>
                    </div>
                </Group>

                <Space h="xs" />

                <SegmentedControl
                    color={"dark"}
                    radius="xl"
                    size='sm'
                    fullWidth
                    data={[
                        { label: 'All Projects', value: 'all' },
                        { label: 'Selected Projects', value: 'selected' },
                    ]}
                    value={formData.project_visibility || 'all'}
                    onChange={(newValue) => {
                        let newFormData = { ...formData, project_visibility: newValue };
                        if ( newValue === 'all' ) {
                            // reset projects to default
                            newFormData.projects = ['all'];
                        } else if ( newValue === 'selected' ) {
                            // remove "all" from projects
                            newFormData.projects = ( formData.projects && isArrayExists(formData.projects) ? [ ...formData.projects ].filter( (p) => p !== 'all' ) : [] );
                        }
                        onUpdate(newFormData);
                    }} />

                { formData.project_visibility && formData.project_visibility === 'selected' && (
                <Box
                    sx={(theme) => ({
                        '& .mantine-Group-root': {
                            gap: '0px'
                        },
                        '& .mantine-CheckboxGroup-root.mantine-Group-child': {
                            width: '50%',
                            padding: '5px 3px',
                        }
                    })}>
                
                    <Space h="xs" />
                    { projectsList && isArrayExists( projectsList ) && (
                    <CheckboxGroup
                        value={formData.projects || []}
                        label="Select the projects you want to assign this template to"
                        // description="This is anonymous"
                        onChange={(newValue) => {
                            onUpdate({ ...formData, projects: ( newValue && isArrayExists(newValue) ? newValue : [] ) });
                        }}
                    >
                        { projectsList.sort((a, b) => {
                                return a.name.localeCompare(b.name);
                            }).map( (project) => (
                            <Checkbox
                                key={project.id}
                                value={project.id}
                                label={project.name} />
                        ))}
                    </CheckboxGroup>
                    ) }
                </Box>
                ) }

            </Card>
        </Grid.Col>
    </Grid>
    );
}

const mapStateToProps = state => {
    return {
        authData: ( state.auth && state.auth.user ) ? state.auth.user : null,
        currentTeam: ( state.auth && state.auth.team ) ? state.auth.team : null,
        projectsList: ( state.projects && state.projects.projects ) ? state.projects.projects : null,
    };
};

export default compose(
    connect(mapStateToProps)
)(TemplateForm);