import React, { useEffect, useState, useRef, useMemo, forwardRef } from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { getFirestore, doc, onSnapshot } from "firebase/firestore";
import { Card, Space, Grid, Box, Drawer, Button, Group } from '@mantine/core';
import { useDidUpdate, useDebouncedValue, useWindowScroll, useResizeObserver, useViewportSize } from '@mantine/hooks';
import { useNotifications } from '@mantine/notifications';
import { GearIcon } from '@radix-ui/react-icons';

import TopBar from './topbar';
import TitleBar from './title';
import Editor from './editor';
import SidePanel from './panel';

import Loader from '../../components/Loader';
import ItemNotFound from '../../components/ItemNotFound';

import { isObjectExists } from '../../helpers/validation';

import { useProject } from '../Projects/redux/hooks';
import { useDocument } from '../Documents/redux/hooks';
import { useTeam } from '../Teams/redux/hooks';

import { shrinkNavbar, expandNavbar, appChangesMade, appChangesReset } from '../../redux/global/actions';
import { useScreenSize } from '../../redux/global/hooks';

import { callFunctionsAPI, getAPIErrorMessage } from '../../helpers/api';

const Document = ({
    authData,
    dispatch,
    changesMade
}) => {
    const db = getFirestore();
    const editorRef = useRef(null);
    const [ screenSize, mobileMode ] = useScreenSize();
    const { project_id, doc_id = 'emptyid' } = useParams();
    const [ teamLoaded, team ] = useTeam({ project_id });
    const [ projectLoaded, project ] = useProject({ project_id });
    const [ documentLoaded, document ] = useDocument({ doc_id });
    const [ updating, setUpdating ] = useState(false); 
    const [ formData, setFormData ] = useState({});
    const [ dataLoaded, setDataLoaded ] = useState(false);
    const [ debouncedFormData ] = useDebouncedValue(formData, 2000);
    const [ scroll ] = useWindowScroll();
    const [ sidePanelRef, sidePanelRect] = useResizeObserver();
    const [ sidePanelOpened, setSidePanelOpened ] = useState(false);
    const { height } = useViewportSize();
    const notifications = useNotifications();

    useEffect(() => {
        dispatch(shrinkNavbar());
        return () => {
            if ( !mobileMode ) {
                dispatch(expandNavbar());
            }
        }
    },[]);

    const loaded = useMemo(() => {
        return teamLoaded && projectLoaded && documentLoaded ? true : false;
    }, [ teamLoaded, projectLoaded, documentLoaded ] );

    useDidUpdate(() => {
        let mounted = true;
        if ( mounted && !dataLoaded ) {
            setFormData({ ...document });
            setDataLoaded(true);
        }
        return () => {
            mounted = false;
        }
    }, [ document ]);

    useDidUpdate(() => {
        // only do update if changes made
        if ( changesMade && loaded ) {
            doUpdate(debouncedFormData);
        }
    }, [ debouncedFormData ]);

    const sidePanelScrollStyles = useMemo(() => {
        return mobileMode ? {
            position: 'fixed',
            top: 'auto',
            bottom: '0px',
            right: '0px',
            width: '100%',
            height: (height*55/100),
            paddingLeft: '0px',
            boxShadow: 'none'
        } : ( scroll.y > 160 ? {
            position: 'fixed',
            top: '0px',
            right: '17px',
            width: sidePanelRect.width,
            height: height - 18,
            zIndex: 3
        } : {} );
    }, [ scroll, sidePanelRect, mobileMode ] );

    const doUpdate = (debouncedFormData) => {
        setUpdating(true);
        callFunctionsAPI({
            url: 'document',
            action: 'update',
            formData: debouncedFormData
        })
        .then(results => {
            setUpdating(false);
            dispatch(appChangesReset());
        })
        .catch(err => {
            setUpdating(false);
            notifications.showNotification({
                color: 'red',
                title: getAPIErrorMessage(err),
            })
        })
    }

    const handleSaveChanges = () => {
        doUpdate(formData);
    }

    const handleFormUpdate = (newValue) => {
        setFormData({ ...newValue });
        dispatch(appChangesMade());
    }

    return loaded ? ( document && isObjectExists( document ) ? (
    <>
        <TopBar
            project={project}
            document={formData}
            updating={updating}
            onSave={handleSaveChanges} />
        
        <Space h="md" />
        
        <Card
            p="lg"
            shadow={"sm"}
            radius="md"
            sx={(theme) => ({
                height: '100%',
                border: '1px solid '+ theme.colors.gray[3],
                minHeight: '95vh'
            })}>
            
            <Card.Section>
                <TitleBar
                    editorRef={editorRef}
                    document={formData}
                    updating={updating}
                    onUpdate={handleFormUpdate} />
            </Card.Section>

            <Card.Section>
                <Grid gutter="0px">
                    <Grid.Col sm={12} md={8}
                        sx={(theme) => ({
                            paddingTop: theme.spacing.lg,
                            paddingBottom: ( mobileMode ? '100px' : theme.spacing.lg ),
                            paddingLeft: theme.spacing.lg,
                            paddingRight: theme.spacing.lg,
                        })}>
                        <Editor
                            editorRef={editorRef}
                            documentData={formData}
                            updating={updating}
                            onUpdate={handleFormUpdate}
                            />
                    </Grid.Col>
                    { mobileMode ? (
                    <>
                        <Drawer
                            opened={true}
                            position="right"
                            size={ sidePanelOpened ? "85vw" : '0px' }
                            withCloseButton={false}
                            withOverlay={ sidePanelOpened }
                            onClose={() => {
                                if ( sidePanelOpened ) {
                                    setSidePanelOpened(false)
                                }
                            }}
                            sx={(theme) => ({

                            })}
                            >
                            <Box
                                sx={() => ({
                                    position: 'relative',
                                })}>
                                <Button
                                    size="sm"
                                    color="indigo"
                                    sx={() => ({
                                        position: 'absolute',
                                        top: '65vh',
                                        right: sidePanelOpened ? 'calc(85vw + 0px)' : '0px',
                                        // zIndex: 3
                                        borderRadius: '6px 0px 0px 6px',
                                        height: '36px',
                                        width: '36px',
                                        padding: '0px'
                                    })}
                                    onClick={() => setSidePanelOpened( !sidePanelOpened )}>
                                    <GearIcon />
                                </Button>
                                <Box
                                    sx={() => ({
                                        visibility: sidePanelOpened ? 'visible' : 'hidden',
                                    })}>
                                    <SidePanel
                                        editorRef={editorRef}
                                        document={formData}
                                        drawerMode={true}
                                        onDrawerToggle={(newValue) => setSidePanelOpened( newValue )}
                                        onUpdate={handleFormUpdate} /> 
                                </Box>
                            </Box>
                        </Drawer> 
                    </>
                    ) : (
                    <Grid.Col sm={12} md={4}>
                        <Box 
                            ref={sidePanelRef}
                            sx={(theme) => ({
                                height: '100%',
                                maxHeight: '100vh',
                                backgroundColor: theme.colors.gray[2],
                                boxShadow: 'inset 0 0 0 1px rgba(0,0,0,0.1)',
                                paddingLeft: '1px',
                                ...sidePanelScrollStyles
                            })}>
                            <SidePanel
                                editorRef={editorRef}
                                document={formData}
                                onUpdate={handleFormUpdate} /> 
                        </Box>
                    </Grid.Col>
                    ) }
                </Grid>
            </Card.Section>

        </Card>
    </>
    ) : <ItemNotFound 
            label="Sorry. This document is either doesn't exist, or you don't have access to it."
            goBack="/projects" /> ) : <Loader height='70vh' /> ;
}

const mapStateToProps = state => {
    return {
        authData: ( state.auth && state.auth.user ) ? state.auth.user : null,
        changesMade: ( state.global && state.global.changes_made ) ? state.global.changes_made : false,
    };
};

export default compose(
    connect(mapStateToProps)
)(Document);