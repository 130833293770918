import React, { useEffect, useState } from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
// import { useDidUpdate } from '@mantine/hooks';
// import { useNavigate } from "react-router-dom";
import { Box, Text } from '@mantine/core';
// import { useModals } from '@mantine/modals';
// import { useNotifications } from '@mantine/notifications';
// import { Pencil2Icon, TrashIcon } from '@radix-ui/react-icons';

import Loader from '../../components/Loader';
import Item from './item';

const TemplatesList = ({
    authData,
    dispatch,
    templates,
    templatesRand
}) => {
    // const navigate = useNavigate();
    const [ loaded, setLoaded ] = useState(false);

    useEffect(() => {
        if ( templatesRand ) {
            setLoaded(true);
        }
    },[templatesRand]);

    return loaded ? (
    <Box
        sx={(theme) => ({
            paddingTop: theme.spacing.xl,
            paddingBottom: theme.spacing.xl,
        })}>

        { templates && templates.length ? 
        (
        <Box
            sx={(theme) => ({
                '& .mantine-Paper-root': {
                    marginBottom: theme.spacing.md,
                    '&:last-child': {
                        marginBottom: 0
                    }
                }
            })}>
            {templates.map(item => (
            <Item key={item.id} {...item} />
            ))}
        </Box>
        )
        : <Box sx={(theme) => ({ paddingTop: theme.spacing.lg })}><Text>No template(s) added yet</Text></Box> }
    
    </Box>
    ) : <Loader height="70vh" /> ;
}

const mapStateToProps = state => {
    return {
        authData: ( state.auth && state.auth.user ) ? state.auth.user : null,
        templates: ( state.templates && state.templates.templates ) ? state.templates.templates : null,
        templatesRand: ( state.templates && state.templates.rand ) ? state.templates.rand : null,
    };
};

export default compose(
    connect(mapStateToProps)
)(TemplatesList);