import React, { useState } from 'react';
import { connect } from "react-redux";
import { Header, Button, Drawer, Group, Avatar, Text } from '@mantine/core';
import { useWindowScroll } from '@mantine/hooks';
import { HamburgerMenuIcon, LightningBoltIcon } from '@radix-ui/react-icons';

import NavBar from './navbar';

import { useNavigation } from '../../redux/global/hooks';

import { PLATFORM_NAME_LONG, PLATFORM_ORG_LOGO } from '../../constants';

const MainHeader = ({
    authData
}) => {
    const [ drawerOpened, setDrawerOpened ] = useState(false);
    const [ scroll ] = useWindowScroll();
    const navigate = useNavigation();

    return (
    <>

        <Header 
            fixed={ scroll.y > 100 ? true : false }
            height={60} 
            sx={(theme) => ({
                backgroundColor: theme.colors.brand[0],
            })}
            >
            <Group
                p="xs"
                pr="xl" 
                position='apart'>
                <Button
                    color="indigo" 
                    onClick={() => setDrawerOpened(!drawerOpened)}
                    >
                    <HamburgerMenuIcon />
                </Button>
                <Group position='right'>
                    <Text weight={700} sx={() => ({ color: '#fff', textTransform: 'uppercase' })}>{PLATFORM_NAME_LONG}</Text>
                    <Avatar
                        component="a"
                        href="/" 
                        // src={PLATFORM_ORG_LOGO}
                        color="dark" 
                        radius="sm"
                        sx={() => ({ border: '2px solid #999' })}
                        onClick={(event) => {
                            event.preventDefault();
                            navigate('/');
                        }}>
                        <LightningBoltIcon />
                    </Avatar>
                </Group>
            </Group>
        </Header>

        <Drawer
            opened={drawerOpened}
            onClose={() => setDrawerOpened(false)}
            withCloseButton={false}
            sx={(theme) => ({
                '& .mantine-Drawer-drawer': {
                    width: '300px'
                },
                '& .mantine-Navbar-root': {
                    borderRight: '0px'
                } 
            })}
        >
            <NavBar
                drawerMode={true}
                onDrawerToggle={() => setDrawerOpened(false)} />
        </Drawer>
    
    </>
    )
}

const mapStateToProps = state => {
    return {
        authData: ( state.auth && state.auth.user ) ? state.auth.user : null,
    };
};

export default connect(mapStateToProps)(MainHeader);