import { useState } from 'react';
import { Textarea, Group, Button } from '@mantine/core';


const TextInput = ({
    disabled = false,
    loading = false,
    onSend = () => {},
}) => {
    const [ text, setText ] = useState('');



    return (
    <Group
        position="apart"
        align={"center"}
        gap={3}
        >
        <Textarea
            placeholder="Type your question here"
            maxRows={2}
            minRows={2}
            value={text}
            sx={() => ({
                width: 'calc( 100% - 100px )',
            })}
            onChange={(event) => {
                setText(event.currentTarget.value);
            }}
            onSubmit={(event) => {
                if ( disabled || loading || text.length === 0 ) {
                    event.preventDefault();
                    return;
                }
                onSend(text);
                setText('');
            }}
            />
        <Button
            color="blue"
            variant="filled"
            disabled={disabled || loading || text.length === 0}
            onClick={() => {
                onSend(text);
                setText('');
            }}
            >
            Send
        </Button>
    </Group>
    )
}

export default TextInput;