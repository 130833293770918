import { useState } from 'react';
import { useMantineTheme, Card, Loader, Group, Avatar, Box, Textarea } from '@mantine/core';
import { useClipboard } from '@mantine/hooks';
import { CopyIcon, GearIcon, Pencil1Icon, CheckIcon, Cross1Icon } from '@radix-ui/react-icons';
import { TbRobot } from 'react-icons/tb';
import { FiUser } from 'react-icons/fi';

import ButtonIconCircle from '../../../components/ButtonIconCircle';

const ChatBubble = ({
    message = {},
    onEdit = () => {},
}) => {
    const theme = useMantineTheme();
    const clipboard = useClipboard({ timeout: 1000 });
    const [ editMode, setEditMode ] = useState(false);
    const [ input, setInput ] = useState('');

    const handleUpdate = () => {
        onEdit(input);
        setEditMode(false);
        setInput('');
    }

    return (
    <Group
        position={message.role === 'user' ? 'right' : 'left'}
        align="top"
        >
        {message.role === 'assistant' && (
            <Avatar
                mt="md"
                >
                <TbRobot
                    size={30}
                    />
            </Avatar>
        ) }
        {message.role === 'system' && (
            <Avatar
                mt="md"
                color={"green"}
                >
                <GearIcon
                    color='green'
                    size={30}
                    />
            </Avatar>
        ) }
        <Card
            shadow="sm"
            radius="md"
            withBorder
            sx={(theme) => ({
                width: '80%',
                maxWidth: '80%',
                margin: '10px 0px',
                alignSelf: message.role === 'user' ? 'flex-end' : ( message.role === 'system' ? 'center' : 'flex-start' ),
                background: message.role === 'user' ? theme.colors.blue[0] : ( message.role === 'system' ? theme.colors.green[0] : theme.colors.gray[0] ),
            })}
            >
            <Card.Section
                pl="md"
                pr="md"
                >
                {message.loaded ? (
                <Box
                    sx={(theme) => ({
                        '& p': {
                            color: message.error ? theme.colors.red[9] : theme.colors.gray[9],
                            '&:first-of-type': {
                                marginTop: ( message.role === 'assistant' || message.role === 'system' ? '0px' : '10px' ),
                            },
                        }
                    })}>
                    { !message.error && message.role === 'assistant' && (
                    <Group
                        pt="5px"
                        pb="3px"
                        position='right'
                        spacing="3px"
                        >
                        <ButtonIconCircle
                            icon={<CopyIcon />}
                            label={ clipboard.copied ? 'Copied' : 'Copy' }
                            useClipboard={true}
                            btnProps={{
                                variant: "light",
                                color: 'gray',
                                radius: 'md',
                                size: 'xs'
                            }}
                            color={theme.colors.indigo[8]}
                            onClick={() => clipboard.copy( message.content )}
                        />
                    </Group>
                    ) }
                    { !message.error && message.role === 'system' && (
                    <Group
                        pt="5px"
                        pb="3px"
                        position='right'
                        spacing="3px"
                        >
                        { editMode ? (
                        <>
                            <ButtonIconCircle
                                icon={<CheckIcon />}
                                label={'Done'}
                                btnProps={{
                                    variant: "light",
                                    color: 'gray',
                                    radius: 'md',
                                    size: 'xs'
                                }}
                                color={theme.colors.green[8]}
                                onClick={handleUpdate}
                            />
                            <ButtonIconCircle
                                icon={<Cross1Icon />}
                                label={'Cancel'}
                                btnProps={{
                                    variant: "light",
                                    color: 'gray',
                                    radius: 'md',
                                    size: 'xs'
                                }}
                                color={theme.colors.dark[8]}
                                onClick={() => {
                                    setEditMode(false);
                                    setInput('');
                                }}
                            />
                        </>
                        ) : (
                        <ButtonIconCircle
                            icon={<Pencil1Icon />}
                            label={'Edit'}
                            btnProps={{
                                variant: "light",
                                color: 'gray',
                                radius: 'md',
                                size: 'xs'
                            }}
                            color={theme.colors.indigo[8]}
                            onClick={() => {
                                setEditMode(true);
                                setInput(message.content);
                            }}
                        />
                        ) }
                    </Group>
                    ) }
                    { editMode ? (
                    <Box sx={() => ({
                            minHeight: '80px',
                        })}>
                        <Textarea
                            value={input}
                            onChange={(e) => setInput(e.currentTarget.value)}
                            rows={3}
                            />
                    </Box>
                    ) : message.content.split('\n').map((text, index) => {
                        return (
                            <p key={index}>{text}</p>
                        )
                    }) }
                </Box>
                ) : (
                <Box
                    sx={(theme) => ({
                        padding: theme.spacing.md
                    })}>
                    <Loader
                        size={30}
                        color="blue"
                        variant='dots' />
                </Box>
                )}
            </Card.Section>
        </Card>
        {message.role === 'user' && (
            <Avatar
                mt="md"
                color={"blue"}
                >
                <FiUser
                    size={30}
                    />
            </Avatar>
        ) }
    </Group>
    )
}

export default ChatBubble;