import React, { useState, useMemo } from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button } from '@mantine/core';
// import { useModals } from '@mantine/modals';
import { useNotifications } from '@mantine/notifications';
import { PlusIcon } from '@radix-ui/react-icons';

import FormModal from './modal';

// import { GET_DOCUMENTS } from './redux/types';
import { callFunctionsAPI, getAPIErrorMessage, delayedFunctionCall } from '../../helpers/api';

const DocumentAddNew = ({
    authData,
    project = {},
    globalDisabled,
    dispatch
}) => {
    // const modals = useModals();
    const navigate = useNavigate();
    const notifications = useNotifications();
    const [ opened, setOpened ] = useState(false);
    const [ loading, setLoading ] = useState(false);

    // const disabled = useMemo(() => {
    //     return loading ? true : false;
    // },[ loading ]);

    const doModalClose = () => {
        // reset everything
        setLoading(false);
        setOpened(false);
    }

    const handleAddNew = (formData) => {
        let error = false;
        // do error check
        // check if name is empty
        if ( !formData.name ) {
            error = 'Name is required';
        }

        // check if errors are empty
        if ( error ) {
            notifications.showNotification({
                color: 'red',
                title: error,
            })
        } else {
            // no errors, add new document
            setLoading(true);
            callFunctionsAPI({
                url: 'document',
                action: 'add',
                formData: formData
            })
            .then(({ new_document_id }) => {
                navigate(`/projects/${project.id}/docs/${new_document_id}`);
            })
            .catch(error => {
                setLoading(false);
                notifications.showNotification({
                    color: 'red',
                    title: getAPIErrorMessage( error ),
                }) 
            });
        }
    }

    return (
    <>

        <Button
            size='md'
            color={"indigo"}
            leftIcon={<PlusIcon />}
            disabled={globalDisabled}
            onClick={() => setOpened(true)}>
            Add New Document
        </Button>

        <FormModal 
            opened={opened}
            loading={loading}
            defaultFormData={{
                name: '',
                project_id: project && project.id || ''
            }}
            onClose={doModalClose}
            doAction={handleAddNew} />
    
    </>
    )
}

const mapStateToProps = state => {
    return {
        authData: ( state.auth && state.auth.user ) ? state.auth.user : null,
        globalDisabled: ( state.global && state.global.disabled ) ? state.global.disabled : false,
    };
};

export default compose(
    connect(mapStateToProps)
)(DocumentAddNew);