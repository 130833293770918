import React, { useMemo } from 'react';
import { Text, Avatar, Group, Space, Divider, Select } from '@mantine/core';
import { TextAlignLeftIcon } from '@radix-ui/react-icons';

import { isArrayExists } from '../../helpers/validation';

const AIModelSelector = ({
    noDivider = false,
    noLabel = false,
    dividerPosition = 'top',
    team = {},
    value = '',
    onUpdate = () => {},
}) => {

    const modalOptions = useMemo(() => {
        let options = [];
        if ( team && team.custom_engines && isArrayExists( team.custom_engines ) ) {
            // add default model
            options.push({ value: '', label: 'Default Model' });
            team.custom_engines.forEach(item => {
                options.push({
                    value: item.id,
                    label: item.label
                });
            });
        }
        return isArrayExists( options ) ? options : false;
    }, [ team ]);

    return modalOptions && (
    <>
        { !noDivider && dividerPosition === 'top' && (
        <>
            <Space h="md" />
            <Divider />
            <Space h="md" />
        </>
        ) }
        
        { !noLabel && (
        <>
            <Group position='left' spacing={"xs"}>
                <Avatar radius={"md"} sx={(theme) => ({
                    '& .mantine-Avatar-placeholder': {
                        backgroundColor: theme.colors.indigo[5],
                    }
                })}><TextAlignLeftIcon size={24} color="#fff" /></Avatar>
                <div>
                    <Text size="md" weight='700'>Model</Text>
                    <Text size="xs" color="gray">Pick a model you want to use</Text>
                </div>
            </Group>
            <Space h="xs" />
        </>
        )}

        <Select
            placeholder="Pick one model"
            value={value || ''}
            data={[ ...modalOptions ]}
            onChange={(newValue) => {
                let selectedModel = modalOptions.find(item => item.value === newValue);
                if ( selectedModel ) {
                    onUpdate(selectedModel.value);
                }
            }}
            />

        { !noDivider && dividerPosition === 'bottom' && (
        <>
            <Space h="md" />
            <Divider />
            <Space h="md" />
        </>
        ) }
    </>
    );
}

export default AIModelSelector;