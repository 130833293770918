import React, { useState, useMemo } from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { Box, Text, Grid, Card, Button, Group, Space, useMantineTheme } from '@mantine/core';
import { TrashIcon, CheckIcon } from '@radix-ui/react-icons';
import { useModals } from '@mantine/modals';
import { useNotifications } from '@mantine/notifications';

import ButtonIconCircle from '../../components/ButtonIconCircle';

import { isArrayExists } from '../../helpers/validation';

import { GET_DOCUMENTS } from './redux/types';
import { callFunctionsAPI, getAPIErrorMessage, delayedFunctionCall } from '../../helpers/api';

const DocumentsList = ({
    authData,
    dispatch,
    project = {},
    documents = []
}) => {
    // const [ loading, setLoading ] = useState(false);
    const [ deleting, setDeleting ] = useState(false);
    const navigate = useNavigate();
    const modals = useModals();
    const theme = useMantineTheme();
    const notifications = useNotifications();

    const handleOpen = (item) => (event) => {
        event.preventDefault();
        navigate(`/projects/${project.id}/docs/${item.id}`);
    }

    const handleDelete = (item) => {
        setDeleting(item);
        notifications.showNotification({
            loading: true,
            disallowClose: true,
            title: 'Deleting document (' + ( item.name || '' ) + ')',
            autoClose: false
        });

        callFunctionsAPI({
            url: 'document',
            action: 'delete',
            formData: { id: item.id }
        })
        .then(results => {
            // get new list
            return delayedFunctionCall({
                url: 'document',
                action: 'get_documents',
                formData: { project_id: project.id }
            });
        })
        .then(list => {
            setDeleting(false);
            dispatch({
                type: GET_DOCUMENTS,
                payload: {
                    list,
                }
            });
            notifications.clean();
            notifications.showNotification({
                color: 'teal',
                icon: <CheckIcon />,
                title: 'Document ('+( item.name || '' ) +') deleted',
            }) 
        })
        .catch(err => {
            setDeleting(false);
            notifications.clean();
            notifications.showNotification({
                color: 'red',
                title: getAPIErrorMessage(err),
            }) 
        });
    }

    const openDeleteModal = (item) => () => modals.openConfirmModal({
        title: 'Delete Document: ' + item.name || '',
        centered: true,
        children: (
        <>
            <Text size="sm">
                Are you sure you want to delete this document ({item.name || ''})? 
            </Text>
            <Text size="sm" weight={"700"} color="red">
                This action cannot be undone.
            </Text>
        </>
        ),
        labels: { confirm: 'Delete Document', cancel: "No don't delete it" },
        confirmProps: { color: 'red' },
        onConfirm: () => handleDelete(item),
    });

    const isCardDisabled = (item) => {
        return ( item && deleting && item.id === deleting.id ) ? true : false;
    }

    return isArrayExists( documents ) ? (
    <Box sx={(theme) => ({
        paddingTop: theme.spacing.xl,
        paddingBottom: theme.spacing.xl,
    })}>
        <Grid gutter="md">
            { documents.map(item => (
            <Grid.Col 
                xs={12} sm={6} md={4} lg={3} 
                key={item.id}> 
                <Card
                    shadow={"md"}
                    radius="md"
                    p="lg"
                    pb="70px"
                    sx={(theme) => ({
                        height: '100%',
                        opacity: ( isCardDisabled(item) ? 0.35 : 1 ),
                    })}>
                    <Text weight={"700"}>{item.name}</Text>
                    <Space h="sm" />
                    <Text size="xs" sx={(theme) => ({ 
                        color: theme.colors.dark[1], 
                        lineHeight: 1.5 
                    })}>
                        {item.excerpt}
                    </Text>

                    <Card.Section
                        sx={(theme) => ({
                            margin: '0px',
                            position: 'absolute',
                            bottom: 0,
                            left: 0,
                            width: '100%',
                            height: '60px',
                        })}>
                        <Group 
                            position='apart'
                            sx={(theme) => ({
                                height: '60px',
                                padding: '5px 20px',
                            })}>
                            <Button 
                                component={ isCardDisabled(item) ? null : 'a' }
                                href={`/projects/${project.id}/docs/${item.id}`}
                                variant="light" 
                                color="indigo"
                                disabled={isCardDisabled(item)}
                                onClick={handleOpen(item)}
                                >
                                Open
                            </Button>
                            <ButtonIconCircle
                                label="Delete"
                                icon={<TrashIcon />}
                                btnProps={{
                                    variant: "light",
                                    color: 'red',
                                    disabled: isCardDisabled(item)
                                }}
                                color={theme.colors.red[8]}
                                onClick={openDeleteModal(item)} />
                        </Group>
                    </Card.Section>
                </Card> 
            </Grid.Col>
            ))}
        </Grid>

    </Box>
    ) : <Box sx={(theme) => ({ paddingTop: theme.spacing.lg })}><Text>No document(s) added yet</Text></Box>
}

const mapStateToProps = state => {
    return {
        authData: ( state.auth && state.auth.user ) ? state.auth.user : null,
    };
};

export default compose(
    connect(mapStateToProps)
)(DocumentsList);