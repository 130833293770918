import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
// import { useParams } from "react-router-dom";
import { Space, Textarea, Text, Group, Avatar, Box, MultiSelect, TextInput } from '@mantine/core';
// import { useClipboard } from '@mantine/hooks';
// import { PlusCircledIcon, TrashIcon, CopyIcon, Pencil1Icon } from '@radix-ui/react-icons';

import AIModelSelector from '../AIModelSelector';

import { isArrayExists } from '../../helpers/validation';

// import { getWizardTypeData } from './helpers';

const StepForm = ({
    document = false,
    wizard = false,
    currentStep = 0,
    step = false,
    disabled = false,
    formData = {},
    onUpdate = () => {},
}) => {
    const { team } = useSelector((state) => state.auth);

    return (
    <>

        <Group>
            <Avatar color="indigo" radius={"md"}><Text size="md">{currentStep+1}</Text></Avatar>
            <Text size="lg" weight="500">{step.label}</Text>
        </Group>

        { step.desc && (
        <>
            <Space h="sm" />
            <Text size="sm" weight="400">{step.desc}</Text>
        </>
        ) }

        { step.fields && isArrayExists( step.fields ) ? step.fields.map(field => {
            const helper_text = field.helper_text && <Text color="dimmed" size="sm">{field.helper_text}</Text>;
            switch(field.field_type) {
                case 'text':
                    return (
                    <Box key={field.id}>
                        <Space h="sm" />
                        <TextInput
                            required
                            label={field.label}
                            placeholder={field.placeholder || ''}
                            value={formData && formData[field.id] ? formData[field.id] : '' }
                            onChange={(e) => onUpdate({ ...formData, [field.id]: e.target.value })} />
                        { helper_text }
                    </Box>
                    );
                case 'textarea':
                    return (
                    <Box
                        key={field.id}>
                        <Space h="md" />
                        <Textarea
                            required
                            placeholder={field.placeholder || ''}
                            label={field.label}
                            // disabled={disabled}
                            value={formData && formData[field.id] ? formData[field.id] : '' }
                            minRows={field.minRows || 3}
                            maxRows={field.maxRows || null}
                            onChange={(e) => onUpdate({ ...formData, [field.id]: e.target.value })} />
                        {helper_text}
                    </Box>
                    )
                case 'model_selector':
                    return (
                    <Box
                        key={field.id}>
                        <Space h="md" />
                        <AIModelSelector
                            noDivider
                            noLabel
                            team={team}
                            value={formData && formData[field.id] ? formData[field.id] : '' }
                            onUpdate={(newValue) => {
                                onUpdate({ ...document, [field.id]: newValue });
                            }} />
                        {helper_text}
                    </Box>
                    );
                case 'stop_sequence_selector':
                    return (
                     <Box
                        key={field.id}>
                        <Space h="md" />
                        <MultiSelect
                            label={field.label}
                            data={[{ value: '', label: 'Enter a sequence', disabled: true }]}
                            value={formData && formData[field.id] && isArrayExists( formData[field.id] ) ? [ ...formData[field.id] ] : [] }
                            searchable
                            creatable
                            getCreateLabel={(query) => `Add ${query}`}
                            onChange={(newValue) => {
                                onUpdate({ ...formData, [field.id]: ( newValue && isArrayExists(newValue) ? newValue : [] ) });
                            }}
                        />
                        {helper_text}
                    </Box>
                    );
                default:
                    return null;
            }
        }) : null }

    </>
    );
}

export default StepForm;