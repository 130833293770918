
import { 
    GET_WIZARDS,
    GET_WIZARD,
    RESET_WIZARDS,
    RESET_WIZARD
} from './types';

const initialState = {
    wizards: null,
    wizard: null,
    updated: false,
    rand_single: false,
    rand: false
};

export const wizards = (state = initialState, action) => {
    const { type, payload = null } = action;
    switch (type) {
        case GET_WIZARDS:
            return {
                ...state,
                wizards: payload.list || [],
                rand: Math.floor(Math.random() * 1000000)
            };
        case GET_WIZARD:
            return {
                ...state,
                wizard: payload.wizard || null,
                rand_single: Math.floor(Math.random() * 1000000)
            };
        case RESET_WIZARDS:
            return {
                ...state,
                wizards: null,
                rand: false
            };
        case RESET_WIZARD:
            return {
                ...state,
                wizard: null,
                rand_single: false
            };
        default:
            return state;
    }
}