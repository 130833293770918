import React, { useEffect, useState, useMemo } from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
// import { useParams } from "react-router-dom";
import { Title, Grid, Group } from '@mantine/core';
import { useDidUpdate } from '@mantine/hooks';
// import { PlusIcon } from '@radix-ui/react-icons';

import TemplatesList from './list';
import TemplateAddNew from './addnew';

// import Loader from '../../components/Loader';

// import { isArrayExists } from '../../helpers/validation';

const Templates = ({
    authData,
    dispatch
}) => {

    return (
    <>
        <Grid justify={"center"}>
            <Grid.Col span={6}>
                <Title order={2}>Templates</Title>
            </Grid.Col>
            <Grid.Col span={6}>
                <Group position='right'>
                    <TemplateAddNew />
                </Group>
            </Grid.Col>
        </Grid>

        <TemplatesList />

    </>
    );
}

const mapStateToProps = state => {
    return {
        authData: ( state.auth && state.auth.user ) ? state.auth.user : null
    };
};

export default compose(
    connect(mapStateToProps)
)(Templates);