

import { 
    GET_TEMPLATES,
    GET_TEMPLATE,
    GET_TEMPLATE_OPTIONS,
    SET_TEMPLATES_RESULTS,
    RESET_TEMPLATE_OPTIONS,
    RESET_TEMPLATES,
    RESET_TEMPLATE
} from './types';

const initialState = {
    templates: null,
    template: null,
    template_options: null,
    templates_results: null,
    updated: false,
    rand_single: false,
    rand: false
};

export const templates = (state = initialState, action) => {
    const { type, payload = null } = action;
    switch (type) {
        case GET_TEMPLATES:
            return {
                ...state,
                templates: payload.list || [],
                rand: Math.floor(Math.random() * 1000000)
            };
        case GET_TEMPLATE:
            return {
                ...state,
                template: payload.template || null,
                rand_single: Math.floor(Math.random() * 1000000)
            };
        case GET_TEMPLATE_OPTIONS:
            return {
                ...state,
                template_options: payload.options || null
            };
        case SET_TEMPLATES_RESULTS:
            return {
                ...state,
                templates_results: payload || null
            };
        case RESET_TEMPLATE_OPTIONS:
            return {
                ...state,
                template_options: null
            };
        case RESET_TEMPLATES:
            return {
                ...state,
                templates: null,
                rand: false
            };
        case RESET_TEMPLATE:
            return {
                ...state,
                template: null,
                rand_single: false
            };
        default:
            return state;
    }
}