import React, { useState, useMemo } from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

const Settings = ({
    authData
}) => {
    return (
    <>
        Settings
    </>
    )
}

const mapStateToProps = state => {
    return {
        authData: ( state.auth && state.auth.user ) ? state.auth.user : null,
    };
};

export default compose(
    connect(mapStateToProps)
)(Settings);