import { combineReducers } from "redux";
import { auth } from './redux/auth/reducers';
import { teams } from './pages/Teams/redux/reducers';
import { projects } from './pages/Projects/redux/reducers';
import { documents } from './pages/Documents/redux/reducers';
import { templates } from './pages/Templates/redux/reducers';

import { wizards } from './modules/Wizard/redux/reducers';

import { tokens } from './redux/tokens/reducers';
import { global } from "./redux/global/reducers";

export default combineReducers({
    auth,
    teams,
    projects,
    documents,
    templates,

    wizards,
    tokens,
    global
});