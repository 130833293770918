import React, { useState, useMemo } from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { Card, Space, Grid, Box } from '@mantine/core';
import { useDidUpdate, useDebouncedValue } from '@mantine/hooks';
import { useNotifications } from '@mantine/notifications';
// import { CheckIcon } from '@radix-ui/react-icons';

import TopBar from './topbar';
import Form from './form';

import Loader from '../../components/Loader';
import ItemNotFound from '../../components/ItemNotFound';

import { isObjectExists } from '../../helpers/validation';

import { useTemplate } from '../Templates/redux/hooks';
import { appChangesMade, appChangesReset } from '../../redux/global/actions';

import { callFunctionsAPI, getAPIErrorMessage } from '../../helpers/api';

const Template = ({
    authData,
    dispatch,
    // template,
    // templateRand,
    changesMade
}) => {
    const { template_id = 'emptyid' } = useParams();
    const [ loaded, template ] = useTemplate({ template_id });
    const [ formData, setFormData ] = useState({});
    const [ updating, setUpdating ] = useState(false); 
    const notifications = useNotifications();

    useDidUpdate(() => {
        let mounted = true;
        if ( mounted ) {
            if ( template && isObjectExists( template ) ) {
                setFormData({ ...template });
                mounted = false;
            }
        }
        return () => {
            mounted = false;
        }
    },[template]);

    const handleSaveChanges = () => {
        setUpdating(true);
        callFunctionsAPI({
            url: 'template',
            action: 'update',
            formData
        })
        .then(results => {
            setUpdating(false);
            dispatch(appChangesReset());
        })
        .catch(err => {
            setUpdating(false);
            notifications.showNotification({
                color: 'red',
                title: getAPIErrorMessage(err),
            })
        })
    }   

    const handleFormUpdate = (newValue) => {
        setFormData({ ...newValue });
        dispatch( appChangesMade() );
    }

    return loaded ? ( template && isObjectExists( template ) ? (
    <>
        <TopBar
            updating={updating}
            onSave={handleSaveChanges}
            />
        <Card
            p="lg"
            mt={"md"}
            mb="sm"
            shadow={"sm"}
            radius="md"
            sx={(theme) => ({
                height: '100%',
                background: theme.colors.gray[0],
                border: '1px solid '+ theme.colors.gray[3],
                minHeight: '95vh'
            })}>
            <Card.Section>
                <Form
                    formData={formData}
                    onUpdate={handleFormUpdate} />
            </Card.Section>
        </Card>
    </>
    ) : <ItemNotFound 
            label="Sorry. This template is either doesn't exist, or you don't have access to it."
            goBack="/templates" /> ) : <Loader height='70vh' /> ;
}

const mapStateToProps = state => {
    return {
        authData: ( state.auth && state.auth.user ) ? state.auth.user : null,
        template: ( state.templates && state.templates.template ) ? state.templates.template : null,
        templateRand: ( state.templates && state.templates.rand_single ) ? state.templates.rand_single : null,
        changesMade: ( state.global && state.global.changes_made ) ? state.global.changes_made : false,
    };
};

export default compose(
    connect(mapStateToProps)
)(Template);