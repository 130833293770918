
import { 
    TOKENS_GET,
} from './types';

const initialState = {
    tokens: 0,
    loaded: null
};

export const tokens = (state = initialState, action) => {
    const { type, payload = null } = action;
    switch (type) {
        case TOKENS_GET:
            return {
                ...state,
                tokens: payload.tokens || 0,
                loaded: true
            };
        default:
            return state;
    }
}