import { useEffect, useState } from 'react';
// import { useDidUpdate } from '@mantine/hooks';
import { useDispatch } from "react-redux";
import { getFirestore, onSnapshot, doc, where, limit, orderBy, query, collection } from "firebase/firestore";
// import { getDatabase, ref, onValue, off, orderByChild, equalTo, query } from "firebase/database";

import { 
    GET_DOCUMENTS,
    GET_DOCUMENT,
    GET_RECENT_DOCUMENTS
} from './types';

import { resetDocuments, resetDocument } from './actions';
// import { isArrayExists } from '../../../helpers/validation';
import { callFunctionsAPI } from '../../../helpers/api';
// import { getUserActiveTeam } from '../../../helpers/auth';

export const useDocuments = ({ project_id = null }) => {
    // const db = getDatabase();
    const dispatch = useDispatch();
    const [ loaded, setLoaded ] = useState(false);
    const [ list, setList ] = useState([]);

    // useEffect(() => {
    //     const Ref = query( ref(db, 'documents'), orderByChild("project_id"), equalTo(project_id) );
    //     onValue(Ref, (snapshot) => {
    //         let list = [];
    //         if ( snapshot.exists() && snapshot.hasChildren() ) {
    //             snapshot.forEach((childSnapshot) => {
    //                 let item = childSnapshot.val();
    //                 item.id = childSnapshot.key;
    //                 // for access
    //                 if ( childSnapshot.child("access").exists() && childSnapshot.child("access").hasChildren() ) {
    //                     item.access = [];
    //                     childSnapshot.child("access").forEach((accessSnapshot) => {
    //                         item.access.push({ ...accessSnapshot.val() });
    //                     });
    //                 }
    //                 list.push(item);
    //             });
    //         }

    //         // sort by modified_on
    //         if ( isArrayExists( list ) ) {
    //             list = list.sort((a, b) => {
    //                 if ( a.modified_on._seconds && b.modified_on._seconds && a.modified_on._seconds < b.modified_on._seconds ) {
    //                     return 1;
    //                 }
    //                 if ( a.modified_on._seconds && b.modified_on._seconds && a.modified_on._seconds > b.modified_on._seconds ) {
    //                     return -1;
    //                 }
    //                 return 0;
    //             });
    //         }

    //         dispatch({
    //             type: GET_DOCUMENTS,
    //             // payload: { list: list.sort((a, b) => {
    //             //     return a.name.localeCompare(b.name);
    //             // }) }
    //             payload: { list }
    //         });
    //         setList(list);
    //         setLoaded(true);
    //     }, (error) => {
    //         console.log(error);
    //         setList([]);
    //         setLoaded(true);
    //         dispatch({
    //             type: GET_DOCUMENTS,
    //             payload: { list: [] }
    //         });
    //     });
    //     return () => {
    //         setLoaded(false);
    //         setList([]);
    //         off(Ref);
    //         dispatch(resetDocuments());
    //     }
    // },[ project_id ]);

    useEffect(() => {
        setLoaded(false);
        setList([]);
        callFunctionsAPI({
            url: 'document',
            action: 'get_documents',
            formData: { project_id }
        })
        .then(list => {
            dispatch({
                type: GET_DOCUMENTS,
                payload: {
                    list,
                }
            });
            setList(list);
            setLoaded(true);
        })
        .catch(error => {
            setList([]);
            setLoaded(true);
            dispatch({
                type: GET_DOCUMENTS,
                payload: {
                    list: [],
                }
            });
        });
        return () => {
            dispatch( resetDocuments() );
        }
    }, [ project_id ]);

    return [ loaded, list ];
}

export const useDocument = ({ 
    doc_id = 'noid',
}) => {
    const db = getFirestore();
    const dispatch = useDispatch();
    const [ loaded, setLoaded ] = useState(false);
    const [ data, setData ] = useState({});

    useEffect(() => {
        let unsubscribe;
        setLoaded(false);
        unsubscribe = onSnapshot(doc(db, "documents", doc_id ), (doc) => {
            const val = ( doc.exists ? doc.data() : {} );
            dispatch({
                type: GET_DOCUMENT,
                payload: {
                    document: val
                }
            });
            setData({ ...val });
            setLoaded(true);
        });

        return () => {
            unsubscribe();
            dispatch(resetDocument());
        }
    },[ doc_id ]);

    return [ loaded, data ];
}

export const useRecentDocuments = ({
    authData = {
        email: 'noemail'
    },
    team_id = 'noteamid'
}) => {
    const db = getFirestore();
    const dispatch = useDispatch();
    const [ loaded, setLoaded ] = useState(false);
    const [ list, setList ] = useState([]);

    useEffect(() => {
        let unsubscribe;
        setLoaded(false);
        const q = query(collection(db, "documents"), where("team_id", "==", team_id), where( 'access_ids', 'array-contains-any', [ authData.email ] ), orderBy("modified_on", "desc"), limit(5));
        unsubscribe = onSnapshot(q, (querySnapshot) => {
            const list = [];
            querySnapshot.forEach((doc) => {
                list.push(doc.data());
            });
            dispatch({
                type: GET_RECENT_DOCUMENTS,
                payload: {
                    list,
                }
            });
            setList(list);
            setLoaded(true);
        });

        return () => {
            unsubscribe();
            dispatch(resetDocument());
        }
    },[ team_id, authData ]);

    return [ loaded, list ];
};