import React, { useState, useMemo } from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
// import { useParams } from "react-router-dom";
import { Box, Textarea, Text, Avatar, Group, Space, Divider, Button, SegmentedControl, Select, Switch } from '@mantine/core';
import { useHotkeys } from '@mantine/hooks';
import { useNotifications } from '@mantine/notifications';
import { BackpackIcon, TextAlignLeftIcon } from '@radix-ui/react-icons';

import { shouldAddExtraSpace } from './editor_helpers';

import AIModelSelector from '../../modules/AIModelSelector';

import { isDev } from '../../helpers/auth';
import { callFunctionsAPI, getAPIErrorMessage } from '../../helpers/api';
import { getCustomModel } from '../../helpers/ai';

import { toggleGlobalDisabled, toggleEditorDisabled } from '../../redux/global/actions';

const PanelWritingTools = ({
    editorRef = null,
    authData,
    navbarStatus,
    drawerMode = false,
    dispatch,
    team = null,
    document,
    onDrawerToggle = () => {},
    onUpdate = () => {}
}) => {
    const notifications = useNotifications();
    useHotkeys([
        ['mod+Enter', () => handleWriteForMe()],
    ]);
    const [ insertMode, setInsertMode ] = useState( false );
    const [ loading, setLoading ] = useState(false);

    const isPersonaModel = useMemo(() => {
        return getCustomModel( document.model, team, 'model_type' ) === 'persona';
    }, [ team, document ]);
    
    const isCodexModel = useMemo(() => {
        return document && ( document.model === 'code-davinci-002' );
    }, [ document ]);

    const isInsertModel = useMemo(() => {
        return document.model === 'text-davinci-002' || document.model === 'text-davinci-003' || document.model === 'code-davinci-002' ? true : false;
    }, [ document ]);

    const outputLengthOptions = useMemo(() => {
        let options = [
            { label: 'Short', value: 'short' },
            { label: 'Medium', value: 'medium' },
            { label: 'Long', value: 'long' },
        ];
        if ( isDev(authData) ) {
            options.push({ label: 'Extra Long', value: 'extra-long' },);
        }
        return options;
    },[ authData ]);

    const doTypingEffects = (newContent = '', editor, cursorIndex, format = 'html') => {
        // add leading space if needed
        const leadingSpace = shouldAddExtraSpace(editor);
        const lines = newContent.split('\n');

        // let content = '';
        // for (let index = 0; index <= lines.length; index++) {
        //     setTimeout(() => {
        //         if ( index === lines.length ) {
        //             // last step - re-enable editor
        //             dispatch(toggleEditorDisabled(false));
        //             setLoading(false);
        //             editor.commands.focus();
        //         } else if ( lines[index] ) {
        //             if ( index === 0 ) {
        //                 if ( leadingSpace === '' || leadingSpace === ' ' ) {
        //                     editor.commands.insertContentAt( (cursorIndex), leadingSpace + lines[index] );
        //                 } else {
        //                     editor.commands.insertContent('<p>' + lines[index] + '</p>');
        //                 }
        //             } else {
        //                 editor.commands.insertContent('<p>' + lines[index] + '</p>');
        //             }
        //         }
        //     }, 500);
        // }

        // for (let index = 0; index <= content.length; index++) {
        //     setTimeout(() => {
        //         if ( index === content.length ) {
        //             // last step - re-enable editor
        //             dispatch(toggleEditorDisabled(false));
        //             setLoading(false);
        //             editor.commands.focus();
        //         } else {
        //             editor.commands.insertContentAt( (cursorIndex+index), content.substring(index,index+1) );
        //         }
        //     }, 5);
        // }

        // break content by line
        let commands = editor.chain();

        for (let index = 0; index < lines.length; index++) {
            let string = lines[index];
            if ( format === 'text' ) {
                // Change < to &lt; and > to &gt;.
                string = string.replace(/</g, '&lt;').replace(/>/g, '&gt;');
            }
            if ( index === 0 ) {
                if ( leadingSpace === '' || leadingSpace === ' ' ) {
                    commands = commands.insertContentAt( (cursorIndex), leadingSpace+string );
                } else {
                    commands = commands.insertContent('<p>' + string + '</p>');
                    // commands = commands.insertContent(string);
                }
            } else {
                 commands = commands.insertContent('<p>' + string + '</p>');
                // commands = commands.enter().insertContent(string);
                
            }
        }

        commands.focus().run();
        dispatch(toggleEditorDisabled(false));
        setLoading(false);
    }

    const handleWriteForMe = () => {
        let errorMessage = false;
        const editor = editorRef.current;
        const cursorIndex = editor.commands.getCursorPosition();
        const context = trimContext( editor.commands.getTextUntilCursor() );
        const suffix = getSuffix( editor.getText({ blockSeparator: "\n\n" }) , editor.commands.getTextUntilCursor() );

        // do error check if is insert mode
        if ( insertMode ) {
            if ( context.length < 1 ) {
                errorMessage = 'Please enter some text to insert into.';
            }
        }

        if ( errorMessage ) {
                notifications.showNotification({
                    message: errorMessage,
                    color: 'red'
                })
        } else {
            // hide drawer if open
            if ( drawerMode ) {
                onDrawerToggle(false);
            }

            setLoading(true);
            dispatch(toggleEditorDisabled(true));
            callFunctionsAPI({
                url: 'write',
                action: ( insertMode ? 'insert_content' : 'write_for_me' ),
                formData: {
                    context,
                    suffix, 
                    team_id: ( team && team.id ) || '',
                    project_id: document.project_id,
                    document_id: document.id,
                    output_length: document.output_length || 'medium',
                    title: document.name || '',
                    brief: document.brief || '',
                    model: document.model || '',
                }
            })
            .then( data => {
                doTypingEffects( ( ( data && data.text ) || '' ) , editor, cursorIndex, 
                ( isCodexModel ? 'text' : 'html' ) 
                ); 
            })
            .catch( error => {
                setLoading(false);
                dispatch(toggleEditorDisabled(false));
                notifications.showNotification({
                    message: getAPIErrorMessage( error ),
                    color: 'red'
                })
            });
        }

    }

    const trimContext = (context) => {
        let newContext = context;
        if ( context.indexOf("///") >= 0 ) {
            const splitStart = context.split("///");
            if ( splitStart[1].length > 1 ) {
                newContext = splitStart[1];
            }  
        }
        if ( context.indexOf("-///") >= 0 ) {
            const splitEnd = context.split("-///");
            if ( splitEnd[0].length > 1 ) {
                newContext = splitEnd[0];
            }  
        }
        return newContext;
    }

    const getSuffix = (fulltext,beforetext) => {
        let suffix = fulltext.substring(beforetext.length,fulltext.length);   
        if ( suffix.indexOf("-///") >= 0 ) {
            const splitEnd = suffix.split("-///");
            if ( splitEnd[0].length > 1 ) {
                suffix = splitEnd[0];
            }  
        }
        return suffix;
    }

    return (
    <Box sx={(theme) => ({
        padding: '0px'
    })}>
        {/* { !isPersonaModel && (
        <>
            <Group position='left' spacing={"xs"}>
                <Avatar radius={"md"} sx={(theme) => ({
                    '& .mantine-Avatar-placeholder': {
                        backgroundColor: theme.colors.indigo[5],
                    }
                })}><BackpackIcon size={24} color="#fff" /></Avatar>
                <div>
                    <Text size="md" weight='700'>Article Brief</Text>
                    <Text size="xs" color="gray">(Max 500 characters)</Text>
                </div>
            </Group>
            <Space h="xs" />
            <Textarea
                value={( document && document.brief ) || '' }
                minRows={5}
                onChange={(e) => {
                    const newbrief = e.target.value;
                    if ( !( newbrief && newbrief.length > 500 ) )
                        onUpdate({ ...document, brief: newbrief });
                }}
                />
        </>
        ) } */}

        <AIModelSelector
            noDivider={isPersonaModel}
            team={team}
            value={document.model || ''}
            onUpdate={(newValue) => {
                onUpdate({ ...document, model: newValue });
                if ( newValue !== 'text-davinci-002' && newValue !== 'code-davinci-002' ) {
                    setInsertMode(false);
                }
            }} />

        { isInsertModel && (
        <>
            <Space h="md" />
            <Switch
                label="Use Insert Mode"
                checked={insertMode}
                onChange={(event) => setInsertMode(event.currentTarget.checked)} />
        </>
        ) }

        <Space h="sm" />
        <Divider />
        <Space h="sm" />
        <Group position='left' spacing={"xs"}>
            <Avatar radius={"md"} sx={(theme) => ({
                '& .mantine-Avatar-placeholder': {
                    backgroundColor: theme.colors.indigo[5],
                }
            })}><TextAlignLeftIcon size={24} color="#fff" /></Avatar>
            <div>
                <Text size="md" weight='700'>Output length</Text>
                <Text size="xs" color="gray">...</Text>
            </div>
        </Group>
        <Space h="xs" />
        { drawerMode ? <Select
            size="sm"
            value={( document && document.output_length ) ? document.output_length : 'medium' }
            data={outputLengthOptions}
            onChange={(newValue) => {
                onUpdate({ ...document, output_length: newValue });
            }} /> : <SegmentedControl
            size="sm"
            radius={"lg"}
            color="gray"
            value={( document && document.output_length ) ? document.output_length : 'medium' }
            fullWidth
            data={outputLengthOptions}
            onChange={(newValue) => {
                onUpdate({ ...document, output_length: newValue });
            }} /> }
        
        <Space h="md" />
        <Button 
            size="md"
            color="indigo" 
            radius={"xl"}
            fullWidth
            loading={loading}
            onClick={handleWriteForMe}>
            Write For Me</Button>
        <Space h="xs" />
        <Text color={"dimmed"} size="sm" align='center'>(Hot Key: CTRL + Enter)</Text>
    </Box>
    );
}

const mapStateToProps = state => {
    return {
        authData: ( state.auth && state.auth.user ) ? state.auth.user : null,
        navbarStatus: ( state.global && state.global.navbar ) ? state.global.navbar : false,
        team: ( state.teams && state.teams.team ) ? state.teams.team : null,
    };
};

export default compose(
    connect(mapStateToProps)
)(PanelWritingTools);