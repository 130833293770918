import React, { useState, useMemo } from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useMantineTheme, Button, Group, Text, Paper } from '@mantine/core';
import { useModals } from '@mantine/modals';
import { useNotifications } from '@mantine/notifications';
import { Pencil2Icon, TrashIcon, CheckIcon } from '@radix-ui/react-icons';

import ButtonIconCircle from '../../components/ButtonIconCircle';

import { callFunctionsAPI, getAPIErrorMessage } from '../../helpers/api';

const Item = ({
    dispatch,
    id = '',
    name = '',
    desc = '',
    team_id = ''
}) => {
    const [ deleting, setDeleting ] = useState(false);
    const theme = useMantineTheme();
    const modals = useModals();
    const notifications = useNotifications();
    const navigate = useNavigate();

    const disabled = useMemo(() => {
        return deleting ? true : false;
    },[ deleting ]);

    const handleDelete = () => {
        setDeleting(true);
        notifications.showNotification({
            loading: true,
            disallowClose: true,
            title: 'Deleting template (' + name + ')',
            autoClose: false
        });

        callFunctionsAPI({
            url: 'template',
            action: 'delete',
            formData: { id }
        })
        .then(() => {
            setDeleting(false);
            notifications.clean();
            notifications.showNotification({
                color: 'teal',
                icon: <CheckIcon />,
                title: 'Template ('+name+') deleted',
            }) 
        })
        .catch(err => {
            setDeleting(false);
            notifications.clean();
            notifications.showNotification({
                color: 'red',
                title: getAPIErrorMessage(err),
            }) 
        });
    }

    const openDeleteModal = () => modals.openConfirmModal({
        title: 'Delete Template: ' + name,
        centered: true,
        children: (
        <>
            <Text size="sm">
                Are you sure you want to delete this template ({name})? 
            </Text>
            <Text size="sm" weight={"700"} color="red">
                This action cannot be undone.
            </Text>
        </>
        ),
        labels: { confirm: 'Delete Template', cancel: "No don't delete it" },
        confirmProps: { color: 'red' },
        onConfirm: handleDelete,
    });

    return (
    <>
        <Paper
            shadow="sm" 
            p="lg" 
            sx={(theme) => ({
                border: '1px solid' + theme.colors.gray[2],
                backgroundColor: theme.colors.gray[0],
                borderRadius: theme.radius.md,
                opacity: ( deleting ? 0.35 : 1 ),
            })}>
            <Group position='apart' grow>
                <div>
                    <Text size="lg" weight={700}>{name}</Text>
                    { desc && desc.length ? <Text size="sm">{desc || ''}</Text> : null }
                </div>
                <Group position='right'>
                    <Button
                        component={ disabled ? null : 'a' }
                        color="indigo"
                        href={`/templates/${id}`}
                        radius={"lg"}
                        size="sm"
                        uppercase
                        leftIcon={<Pencil2Icon />}
                        disabled={disabled}
                        onClick={(event) => {
                            event.preventDefault();
                            navigate(`/templates/${id}`);
                        }}
                    >
                        Edit
                    </Button>
                    <ButtonIconCircle
                        label="Delete"
                        icon={<TrashIcon />}
                        btnProps={{
                            disabled
                        }}
                        color={theme.colors.red[8]}
                        onClick={openDeleteModal} />
                </Group>
            </Group>
        </Paper>
    </>
    )
}

export default compose(
    connect()
)(Item);