import React, { useEffect, useState, useRef, useMemo } from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
// import { useParams } from "react-router-dom";
import { useMantineTheme, Text, Card, Group, Textarea, Input, Space, Button, ScrollArea, Box, Loader } from '@mantine/core';
import { useNotifications } from '@mantine/notifications';
import { Cross1Icon, Pencil1Icon, CheckIcon, SymbolIcon } from '@radix-ui/react-icons';

import { reorganizeWizardResults } from './helpers';

import ButtonIconCircle from '../../components/ButtonIconCircle';
import MultilineText from '../../components/MultilineText';

import { isArrayExists, isObjectExists } from '../../helpers/validation';
import { callFunctionsAPI, getAPIErrorMessage } from '../../helpers/api';

import { toggleGlobalDisabled } from '../../redux/global/actions';

// import { getWizardTypeData } from './helpers';

const StepImprove = ({
    globalDisabled,
    dispatch,
    document = false,
    wizard = false,
    wizardData = {},
    selected = {},
    onUpdate = () => {},
    onUnselect = () => {},
}) => {
    const theme = useMantineTheme();
    const notifications = useNotifications();
    const viewport = useRef();
    const [ heading, setHeading ] = useState('');
    const [ content, setContent ] = useState('');
    const [ edit, setEdit ] = useState(false);
    const [ updating, setUpdating ] = useState(false);
    const [ generating, setGenerating ] = useState(false);

    useEffect(() => {
        setHeading( ( selected.heading || '' ) );
        setContent( ( selected.content || '' ) );
    }, [ selected ]);

    const variants = useMemo(() => {
        const results = reorganizeWizardResults(( wizardData && wizardData.results ) || []);
        const selected_result = results.find( ( item ) => item.id === selected.id );
        return selected_result && selected_result.variants && isArrayExists( selected_result.variants ) ? selected_result.variants.sort((a,b) => {
            return b.created_on - a.created_on;
        }) : [];
    },[ selected, wizardData ]);

    const handleCancelEdit = () => {
        dispatch(toggleGlobalDisabled(false));
        setEdit(false);
        setHeading( ( selected.heading || '' ) );
        setContent( ( selected.content || '' ) );
    }

    const handleEdit = () => {
        dispatch(toggleGlobalDisabled(true));
        setEdit(true);
    }

    const handleUpdate = () => {
        setUpdating(true);
        callFunctionsAPI({
            url: 'wizard',
            action: wizard.id+'_update',
            formData: {
                wizard_id: wizardData && wizardData.id ? wizardData.id : false,
                document_id: document.id,
                result_id: selected.id,
                result_type: selected.type,
                content
            }
        })
        .then( () => {
            // unload everything
            dispatch(toggleGlobalDisabled(false));
            setUpdating(false);
            setEdit(false);
        })
        .catch( error => {
            dispatch(toggleGlobalDisabled(false));
            setUpdating(false);
            setEdit(false);
            notifications.showNotification({
                message: getAPIErrorMessage( error ),
                color: 'red'
            })
        });
    }
    
    const handleAction = ( action = ''  ) => () => {
        dispatch(toggleGlobalDisabled(true));
        setGenerating(true);
        callFunctionsAPI({
            url: 'wizard',
            action: wizard.id+'_improve',
            formData: {
                wizard_id: wizardData && wizardData.id ? wizardData.id : false,
                document_id: document.id,
                result_id: selected.id,
                result_type: selected.type,
                action
            }
        })
        .then( () => {
            // unload everything
            dispatch(toggleGlobalDisabled(false));
            setGenerating(false);
            // go to variant results
            if ( viewport && viewport.current && viewport.current.scrollTo )
                viewport.current.scrollTo({ top: 0, behavior: 'smooth' });
        })
        .catch( error => {
            dispatch(toggleGlobalDisabled(false));
            setGenerating(false);
            notifications.showNotification({
                message: getAPIErrorMessage( error ),
                color: 'red'
            })
        });
    }

    const handleReplace = (variant_id) => () => {
        dispatch(toggleGlobalDisabled(true));
        notifications.showNotification({
            loading: true,
            disallowClose: true,
            title: 'Processing...',
            autoClose: false
        });
        callFunctionsAPI({
            url: 'wizard',
            action: wizard.id+'_replace',
            formData: {
                wizard_id: wizardData && wizardData.id ? wizardData.id : false,
                document_id: document.id,
                result_id: selected.id,
                result_type: selected.type,
                variant_id
            }
        })
        .then( () => {
            // unload everything
            dispatch(toggleGlobalDisabled(false));
            notifications.clean();
            // go to variant results
            if ( viewport && viewport.current && viewport.current.scrollTo )
                viewport.current.scrollTo({ top: 0, behavior: 'smooth' });
        })
        .catch( error => {
            dispatch(toggleGlobalDisabled(false));
            notifications.clean();
            notifications.showNotification({
                message: getAPIErrorMessage( error ),
                color: 'red'
            })
        });
    }

    return selected && isObjectExists( selected ) ? (
    <Card
        p="lg"
        radius={"md"}
        shadow={"md"}
        sx={(theme) => ({
            margin: '10px'
        })}>
        <Card
            radius={"md"}
            shadow="md"
            sx={(theme) => ({
                backgroundColor: theme.colors.indigo[0],
                border: '2px solid ' + theme.colors.indigo[5],
            })}>
            <Card.Section
                sx={(theme) => ({
                    padding: '5px 15px',
                })}>
                <Group 
                    spacing={"md"}
                    position='apart' 
                    noWrap>
                    { edit ? <ButtonIconCircle
                        icon={ updating ? <Loader size={12} color="dark" /> : <CheckIcon />}
                        label="Update"
                        btnProps={{
                            variant: "filled",
                            color: updating ? 'gray' : 'green',
                            radius: 'md',
                            size: 'xs',
                        }}
                        color={theme.colors.green[8]}
                        onClick={handleUpdate}
                    /> : <ButtonIconCircle
                        icon={<Pencil1Icon />}
                        label="Edit"
                        btnProps={{
                            variant: "light",
                            color: 'gray',
                            radius: 'md',
                            size: 'xs'
                        }}
                        color={theme.colors.indigo[8]}
                        onClick={handleEdit}
                    /> }
                    { edit ? <ButtonIconCircle
                        icon={<Cross1Icon />}
                        label="Cancel Edit"
                        btnProps={{
                            variant: "light",
                            color: 'gray',
                            radius: 'md',
                            size: 'xs',
                            disabled: updating
                        }}
                        color={theme.colors.red[8]}
                        onClick={handleCancelEdit}
                    /> : <ButtonIconCircle
                        icon={<Cross1Icon />}
                        label="Un-Select"
                        btnProps={{
                            variant: "light",
                            color: 'gray',
                            radius: 'md',
                            size: 'xs'
                        }}
                        color={theme.colors.red[8]}
                        onClick={() => onUnselect(selected)}
                    /> }
                </Group>
            </Card.Section>

            { edit && (
            <>
                <Space h="xs" />
                <Input value={heading} disabled />
                <Space h="sm" />
                <Textarea
                    value={content || ''}
                    minRows={5}
                    maxRows={15}
                    onChange={(e) => setContent(e.target.value)} />
            </>
            )}

            { !edit && (
            <>
                { selected.heading && (
                <>
                    <Text size="md" weight={"700"}>{selected.heading}</Text>
                    <Space h="sm" />
                </>
                ) }
                <ScrollArea
                    type="auto"
                    sx={() => ({
                        height: '200px',
                    })}>
                    <Box sx={() => ({ padding: '0 20px 0 0' })}>
                        <MultilineText text={content} textProps={{ size: 'sm', component: 'p' }} />
                    </Box>
                </ScrollArea>
            </>  
            ) }

        </Card>

        <Space h="md" />

        <Group position='center'>
            {/* <Button
                size='xs'
                radius={"md"}
                disabled={globalDisabled}>Expand</Button>
            <Button
                size='xs'
                radius={"md"}
                disabled={globalDisabled}>Change Tone</Button> */}
            <Button
                size='xs'
                radius={"md"}
                disabled={globalDisabled}
                loading={generating}
                onClick={handleAction('regenerate')}>Re-generate</Button>
        </Group>

        { variants && isArrayExists( variants ) && (
        <>
            <Text size="sm" weight={"700"}>Variations:</Text>
            <Space h="xs" />
            <ScrollArea
                viewportRef={viewport}
                sx={(theme) => ({
                    height: 'calc( 75vh - 430px )',
                    borderRadius: theme.radius.md,
                    border: '2px solid ' + theme.colors.gray[5], 
                    background: theme.colors.gray[2],
                })}>
                <Box sx={(theme) => ({
                    padding: '20px 25px 10px 20px',
                    background: theme.colors.gray[2],
                    '& > div': {
                        marginBottom: theme.spacing.sm
                    }
                })}>
                    {variants.map((variant,index) => variant.id && variant.id !== selected.selected_variant && (
                    <Card
                        key={variant.id}
                        withBorder
                        p="sm"
                        mb="sm"
                        sx={(theme) => ({
                            border: '2px solid ' + theme.colors.gray[5],
                        })}>
                        <Group position='right'>
                            <ButtonIconCircle
                                icon={<SymbolIcon />}
                                label="Use This Variant"
                                btnProps={{
                                    variant: "filled",
                                    color: 'indigo',
                                    radius: 'md',
                                    size: 'xs',
                                    disabled: globalDisabled
                                }}
                                color={theme.colors.indigo[8]}
                                onClick={handleReplace(variant.id)}
                            />
                        </Group>
                        <Space h="xs" />    
                        <MultilineText text={variant.content} textProps={{ size: 'sm', component: 'p' }} />
                    </Card>
                    ) )}
                </Box>
            </ScrollArea>
        </>
        ) }

    </Card>
    ) : null ;
}

const mapStateToProps = state => {
    return {
        authData: ( state.auth && state.auth.user ) ? state.auth.user : null,
        globalDisabled: ( state.global && state.global.disabled ) ? state.global.disabled : false,
    };
};

export default compose(
    connect(mapStateToProps)
)(StepImprove);