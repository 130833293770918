

const theme = {
    colors: {
        brand: [ '#1F1D36', '#3F3351' ],
        white: [ '#ffffff', '#fafafa', '#f1f1f1' ],
        // brand: ['#1C0C5B','#3D2C8D','#916BBF']
    }
};

export default theme;