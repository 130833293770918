
export const TEMPLATE_TYPES = [
    {
        id: 'article_titles',
        label: 'Article Ideas',
        desc: 'Create a list of ideas for your article.',
        fields: [
            {
                id: 'topic',
                label: 'Topic',
                desc: '',
                type: 'string',
                required: 'yes',
                field_type: 'textarea',
                placeholder: 'Ex: How to become more productive',
                helpers: [ 'title', 'selected_text' ],
                error_msg: 'Please insert a topic'
            }
        ]
    },
    {
        id: 'article_introduction',
        label: 'Article Introduction',
        desc: 'Create an introduction to your article',
        fields: [
            {
                id: 'title',
                label: 'Article Title',
                desc: '',
                type: 'string',
                required: 'yes',
                field_type: 'textarea',
                placeholder: 'Ex: How to increase your productivity at work',
                helpers: [ 'title', 'selected_text' ],
                error_msg: 'Please insert an article title'
            }
        ]
    },
    {
        id: 'article_brief',
        label: 'Article Brief',
        desc: 'Create a brief description of your article',
        fields: [
            {
                id: 'title',
                label: 'Article Title',
                desc: '',
                type: 'string',
                required: 'yes',
                field_type: 'textarea',
                placeholder: 'Ex: How to increase your productivity at work',
                helpers: [ 'title', 'selected_text' ],
                error_msg: 'Please insert an article title'
            }
        ]
    },
    {
        id: 'article_outlines',
        label: 'Article Outlines',
        desc: 'Create a list of outlines for your article',
        fields: [
            {
                id: 'title',
                label: 'Article Title',
                desc: '',
                type: 'string',
                required: 'yes',
                field_type: 'textarea',
                placeholder: 'Ex: How to increase your productivity at work',
                helpers: [ 'title', 'selected_text' ],
                error_msg: 'Please insert an article title'
            },
            {
                id: 'brief',
                label: 'Article Brief',
                desc: '',
                type: 'string',
                required: 'yes',
                field_type: 'textarea',
                // placeholder: 'Ex: How to increase your productivity at work',
                helpers: [ 'brief', 'selected_text' ],
                error_msg: 'Please insert a brief description of the article'
            }
        ]
    },
    {
        id: 'article_conclusion',
        label: 'Article Conclusion',
        desc: 'Create a conclusion to your article',
        fields: [
            {
                id: 'title',
                label: 'Article Title',
                desc: '',
                type: 'string',
                required: 'yes',
                field_type: 'textarea',
                placeholder: 'Ex: How to increase your productivity at work',
                helpers: [ 'title', 'selected_text' ],
                error_msg: 'Please insert an article title'
            }
        ]
    },
];