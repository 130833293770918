import React, { useState, useMemo } from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import shortid from 'shortid';
// import { useParams } from "react-router-dom";
import { Space, Box, Text, Button, Badge } from '@mantine/core';
import { useDidUpdate } from '@mantine/hooks';
import { useNotifications } from '@mantine/notifications';
import { PlusIcon } from '@radix-ui/react-icons';

import Item from './item';

import { isArrayExists } from '../../helpers/validation';


const FormItems = ({
    label = '',
    itemLabel = 'input',
    newItemProps = {},
    fields = [],
    showCopy = false,
    copyProps = {},
    value = [],
    onUpdate = () => {},
}) => {
    // const theme = useMantineTheme();
    const notifications = useNotifications();
    const [ addNew, setAddNew ] = useState(false);
    const [ isEditing, setIsEditing ] = useState(false);

    useDidUpdate(() => {
        setAddNew(false);
        setIsEditing(false);
    }, [ value ]);

    const handleItemAdd = (item) => {
        const newID = shortid.generate();
        let error = false,
            formData = { id: newID };
        // make sure required field are filled
        if ( fields && isArrayExists( fields ) ) {
            fields.forEach( (field) => {
                formData[field.id] = item[field.id] ? ( field.filter ? field.filter( item[field.id] ) : item[field.id] ) : null ;
                if ( field.required && !formData[field.id] ) {
                    error = `Please fill out "${field.label}" field`;
                }
            });
        }

        // do unique check
        if ( fields && isArrayExists( fields ) ) {
            fields.forEach( (field) => {
                if ( field.unique ) {
                    const found = value.find( (item) => item[field.id] === formData[field.id] && item.id !== formData.id );
                    if ( found ) {
                        error = ( field.unique && field.unique.length > 1 ? field.unique : `${field.label} is already taken` );
                    }
                }
            });
        }

        if ( error ) {
            notifications.showNotification({
                color: 'red',
                title: error,
            })
        } else {
            onUpdate([ ...value, formData ]);
        }
    }
    
    const handleItemEdit = (item)=> {
        let error = false,
            formData = { id: item.id };
        // make sure required field are filled
        if ( fields && isArrayExists( fields ) ) {
            fields.forEach( (field) => {
                formData[field.id] = item[field.id] ? ( field.filter ? field.filter( item[field.id] ) : item[field.id] ) : null ;
                if ( field.required && !item[field.id] ) {
                    error = `Please fill out "${field.label}" field`;
                }
            });
        }

        // do unique check
        if ( fields && isArrayExists( fields ) ) {
            fields.forEach( (field) => {
                if ( field.unique ) {
                    const found = value.find( (item) => item[field.id] === formData[field.id] && item.id !== formData.id );
                    if ( found ) {
                        error = ( field.unique && field.unique.length > 1 ? field.unique : `${field.label} is already taken` );
                    }
                }
            });
        }

        if ( error ) {
            notifications.showNotification({
                color: 'red',
                title: error,
            })
        } else {
            onUpdate([...value.filter(i => i.id !== item.id), formData ]);
        }
    }

    const handleItemRemove = (item) => {
        onUpdate([...value.filter(i => i.id !== item.id)]);
    }

    return (
    <>
        { label && (
        <>
            <Text size="sm">{label}</Text>
            <Space h="sm" />
        </>
        ) }
        
        <Box
            sx={(theme) => ({
                '& .mantine-Card-root': {
                    marginBottom: theme.spacing.sm,
                    '&:last-child': {
                        marginBottom: 0,
                    },
                }
            })}>
            { value && isArrayExists( value ) ? value.map((item, index) => (
            <Item 
                key={item.id} 
                item={item}
                itemLabel={itemLabel}
                fields={fields}
                isEditing={isEditing && isEditing === item.id ? true : false }
                showCopy={showCopy}
                copyProps={copyProps}
                onEdit={(item) => setIsEditing(item.id)}
                onUpdate={handleItemEdit}
                onCancelEdit={() => setIsEditing(false)}
                onDelete={handleItemRemove} />
            )) : ( !addNew && <Badge color="gray">No {itemLabel} entered yet.</Badge> ) }
            { addNew && <Item  
                item={newItemProps}
                itemLabel={itemLabel}
                fields={fields}
                isAddNew={true}
                onUpdate={handleItemAdd}
                onCancelEdit={() => setAddNew(false)}
                onDelete={() => { /* do nothing */ }} /> }
        </Box>
 
        <Space h="sm" />
        <Button 
            size='sm' 
            compact 
            color="dark"
            leftIcon={<PlusIcon />}
            variant='filled'
            onClick={() => {
                setAddNew(true);
            }}>Add New</Button>
    
    </>
    )
}

export default FormItems;