
import {
	TOGGLE_NAVBAR,
	EXPAND_NAVBAR,
	SHRINK_NAVBAR,
    CHANGES_MADE,
    CHANGES_RESET,
    TOGGLE_GLOBAL_DISABLED,
    TOGGLE_EDITOR_DISABLED
} from './types';

export const toggleNavbar = () => {
    return dispatch => {
        setTimeout(() => {
            dispatch({ type: TOGGLE_NAVBAR });
        },150);
    }
}

export const expandNavbar = () => {
    return dispatch => {
        setTimeout(() => {
            dispatch({ type: EXPAND_NAVBAR });
        },150);
    }
}

export const shrinkNavbar = () => {
    return dispatch => {
        setTimeout(() => {
            dispatch({ type: SHRINK_NAVBAR });
        },150);
    }
}

export const appChangesMade = () => {
    return dispatch => {
        setTimeout(() => {
            dispatch({ type: CHANGES_MADE });
        },150);
    }
}

export const appChangesReset = () => {
    return dispatch => {
        setTimeout(() => {
            dispatch({ type: CHANGES_RESET });
        },150);
    }
}

export const toggleGlobalDisabled = () => {
    return dispatch => {
        setTimeout(() => {
            dispatch({ type: TOGGLE_GLOBAL_DISABLED });
        },150);
    }
}

export const toggleEditorDisabled = () => {
    return dispatch => {
        setTimeout(() => {
            dispatch({ type: TOGGLE_EDITOR_DISABLED });
        },150);
    }
}