import React, { useState, useCallback } from 'react';
// import { compose } from "recompose";
// import { connect } from "react-redux";
// import { useParams } from "react-router-dom";
// import { Card, Space, Grid, Box } from '@mantine/core';
import { useDidUpdate } from '@mantine/hooks';

import './styles.css';

import ReactFlow, {
    Controls,
    Background,
    applyNodeChanges,
    applyEdgeChanges,
    addEdge,
} from 'reactflow';

const Flow = ({
    nodes = [],
    edges = [],
    onNodesChange = () => {},
    onEdgesChange = () => {},
}) => {

    const handleNodesChange = useCallback((changes) => {
        onNodesChange(applyNodeChanges(changes, nodes));
    }, [ nodes ] );

    const handleEdgesChange = useCallback((changes) => {
        onEdgesChange(applyEdgeChanges(changes, edges));
    }, [ edges ] );

    const handleConnect = useCallback((params) => {
        onEdgesChange(addEdge(params, edges));
    }, [ edges ]);

    return (
    <>
        <ReactFlow
            nodes={nodes}
            onNodesChange={handleNodesChange}
            edges={edges}
            onEdgesChange={handleEdgesChange}
            onConnect={handleConnect}
        >
            <Background />
            <Controls />
        </ReactFlow>
    </>
    )
}

export default Flow;