import React, { useState, useMemo } from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
// import { useNavigate } from "react-router-dom";
import { Title, Grid, Space, Container, Divider } from '@mantine/core';
// import { InfoCircledIcon } from '@radix-ui/react-icons';

import WidgetRecentDocs from '../../modules/WidgetRecentDocs';
import WidgetCreditsStats from '../../modules/WidgetCreditsStats';

import { useScreenSize } from '../../redux/global/hooks';

const Dashboard = ({
    authData
}) => {
    const [ screenSize ] = useScreenSize();
    return (
    <Container size={"lg"}>
        <Title order={3}>Welcome back, <em style={{ fontWeight: '300' }}>{authData.name}</em></Title>
        <Space h="md" />
        <Divider />
        <Space h="xl" />
        <Grid>
            <Grid.Col sm={12} md={6}>
                <WidgetRecentDocs />
            </Grid.Col>
            <Grid.Col sm={12} md={6}>
                <WidgetCreditsStats />
            </Grid.Col>
        </Grid>
        <Space h="xl" />
        <Space h="xl" />
    </Container>
    )
}

const mapStateToProps = state => {
    return {
        authData: ( state.auth && state.auth.user ) ? state.auth.user : null,
    };
};

export default compose(
    connect(mapStateToProps)
)(Dashboard);