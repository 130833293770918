import React, { useMemo, useState } from 'react';
import { compose } from "recompose";
import { connect, useSelector, useDispatch } from "react-redux";
import { Navbar, ScrollArea, UnstyledButton, Group, Avatar, Text, Button, Box, useMantineTheme, Tooltip, Menu, Loader, Modal, Select, LoadingOverlay, Space } from '@mantine/core';
import { MagicWandIcon, DashboardIcon, CubeIcon, ChevronLeftIcon, ChevronRightIcon, ExitIcon, ShuffleIcon, ChatBubbleIcon } from '@radix-ui/react-icons';
import { useNotifications } from '@mantine/notifications';

import ProjectsList from '../ProjectsList';

import ButtonIconCircle from '../../components/ButtonIconCircle';

import { isArrayExists } from '../../helpers/validation';
import { getAPIErrorMessage, callFunctionsAPI } from '../../helpers/api';

import { AUTH_LOGOUT } from '../../redux/auth/types';
import { logoutUser } from '../../redux/auth/api';
import { toggleNavbar } from '../../redux/global/actions';
import { useNavigation } from '../../redux/global/hooks';

import { AUTH_LOGIN } from '../../redux/auth/types';

const Brand = ({
    dispatch,
    navbarStatus,
    drawerMode = false,
    onDrawerToggle = () => {}
}) => {
    const theme = useMantineTheme();
    const navigate = useNavigation();

    const handleToggleNavbar = () => {
        if ( drawerMode ) {
            onDrawerToggle();
        } else {
            dispatch(toggleNavbar());
        }
    }

    const fullMode = useMemo(() => {
        return navbarStatus || drawerMode ? true : false ;
    }, [navbarStatus, drawerMode]);

    return (
    <Box
        sx={(theme) => ({
            paddingTop: theme.spacing.sm,
            paddingBottom: theme.spacing.sm,
            paddingLeft: ( fullMode ? '15px' : null ),
            marginBottom: theme.spacing.sm,
            borderBottom: `2px solid ${theme.colors.brand[1]}`
        })}>
        <Group position='apart' direction={ fullMode ? 'row' : 'column-reverse' }>
            <Group>
                <Avatar
                    component="a"
                    href="/" 
                    color="dark" radius="sm"
                    onClick={(event) => {
                        event.preventDefault();
                        navigate('/');
                        if ( drawerMode )
                            onDrawerToggle();
                    }}>
                    <MagicWandIcon />
                </Avatar>
                { navbarStatus ? <Text>Wizardly AI</Text> : null }
            </Group>
            <div style={{ textAlign: fullMode ? 'right' : 'center' }}>
                <ButtonIconCircle
                    label={ ( fullMode ? "Shrink" : "Expand" ) + " Navbar" }
                    icon={ fullMode ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    position="right"
                    btnProps={{
                        size: 'xs',
                        variant: "subtle",
                        color: 'gray',
                        padding: 'xs',
                        radius: 'md'
                    }}
                    color={theme.colors.indigo[8]}
                    onClick={handleToggleNavbar} />
            </div>
        </Group>
    </Box>
    )
}

const User = ({
    authData,
    navbarStatus,
    drawerMode = false,
    onDrawerToggle = () => {},
    onLogout = () => {}
}) => {
    const { profile_image = null } = useSelector((state) => state.auth);
    const { teams = [], teams_loaded = false } = useSelector((state) => state.auth);
    const tokens = useSelector((state) => state.tokens.tokens);
    const tokensLoaded = useSelector((state) => state.tokens.loaded);
    const navigate = useNavigation();
    const dispatch = useDispatch();
    const notifications = useNotifications();
    const [ switchTeamOpened, setSwitchTeamOpened ] = useState( false );
    const [ activeTeam, setActiveTeam ] = useState( ( authData && authData.active_team ) || '' );
    const [ loading, setLoading ] = useState(false);

    const handleLinkClick = (link) => () => {
        navigate(link);
        if ( drawerMode )
            onDrawerToggle();
    }

    const handleTeamSwitch = () => {
        let error = false;
        if ( !( activeTeam && activeTeam !== '' ) ) {
            error = 'Please select a team to switch to.';
        }

        if ( error ) {
            notifications.showNotification({
                color: 'red',
                title: error
            });
        } else {
            setLoading(true);
            callFunctionsAPI({
                url: 'auth',
                action: 'switch_team',
                formData: {
                    new_team_id: activeTeam
                }
            })
            .then(data => {
                window.location = '/';
                // dispatch({
                //     type: AUTH_LOGIN,
                //     payload: data
                // });
                // setTimeout(() => {
                //     setLoading(false);
                //     setSwitchTeamOpened(false);
                //     notifications.showNotification({
                //         color: 'teal',
                //         icon: <CheckIcon />,
                //         title: 'Team Switched',
                //     });
                // },250);
            })
            .catch(error => {
                setLoading(false);
                notifications.showNotification({
                    color: 'red',
                    title: getAPIErrorMessage(error)
                });
            });
        } // end - error
    }

    return (
    <Box
        sx={(theme) => ({
            paddingTop: theme.spacing.xs,
            paddingBottom: theme.spacing.xs,
            borderTop: `2px solid ${theme.colors.brand[1]}`
        })}>
        <Menu
            position="right" 
            gutter={20}
            withArrow
            control={(
            <UnstyledButton>
                <Group>
                    <Avatar src={( profile_image && profile_image.length > 0 ? profile_image : null )} size={40} />
                    { navbarStatus ? <div>
                        <Text sx={(theme) => ({
                            color: theme.colors.gray[4],
                        })}>{ authData.name || '' }</Text>
                        <Text size="xs" sx={(theme) => ({
                            color: theme.colors.gray[4],
                        })}>{ authData.email || '' }</Text>
                    </div> : null }
                </Group>
            </UnstyledButton>
            )}>
            <Menu.Label>Quick Links</Menu.Label>
            {/* <Menu.Item icon={<PersonIcon />} onClick={handleLinkClick('/account')}>My Account</Menu.Item> */}
            <Menu.Item 
                component='div'
                onClick={(e) => {
                    e.preventDefault();
                }}
                icon={<i className="ri-copper-coin-line"></i>}
                rightSection={ tokensLoaded ? (
                <Text size='sm' color="indigo">
                    {((tokens).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')).toString().replace('.00','')}
                </Text>
                ) : <Loader /> }
                >Tokens:</Menu.Item>
                { teams_loaded && teams && isArrayExists( teams ) && (
                <>
                    <Menu.Item 
                        icon={<i className="ri-team-line"></i>}
                        onClick={() => setSwitchTeamOpened(true)}>Switch Team</Menu.Item>
                </>
                ) }
            <Menu.Item color="red" icon={<ExitIcon />} onClick={onLogout}>Sign Out</Menu.Item>
        </Menu>

        <Modal
            opened={switchTeamOpened}
            title="Switch Team"
            size="md"
            centered
            closeOnClickOutside={false}
            closeOnEscape={false}
            onClose={() => setSwitchTeamOpened(false)}
            >
            <LoadingOverlay visible={loading} />
            <Select
                value={activeTeam}
                data={ teams && isArrayExists( teams ) ? teams.map(t => ({
                    value: t.id,
                    label: t.name || ''
                })) : [] }
                onChange={(newValue) => setActiveTeam(newValue)} />
            <Space h="md" />
            <Button
                color="indigo"
                size="sm"
                fullWidth
                disabled={ activeTeam !== authData.active_team ? false : true }
                onClick={handleTeamSwitch}>
                Switch Now!
            </Button>
        </Modal>

    </Box>
    )
}

const MainNavBar = ({
    authData,
    dispatch,
    navbarStatus,
    drawerMode = false,
    onDrawerToggle = () => {}
}) => {
    const navigate = useNavigation();
    const notifications = useNotifications();

    const baseWidth = useMemo(() => {
        return ( navbarStatus ? 300 : 60 );
    }, [ navbarStatus ]);

    const fullMode = useMemo(() => {
        return navbarStatus || drawerMode ? true : false ;
    }, [navbarStatus, drawerMode]);

    const links = [
        {
            label: 'Dashboard',
            icon: <DashboardIcon />,
            link: '/dashboard',
            onClick: () => handleLinkClick('/dashboard')
        },
        {
            label: 'Templates',
            icon: <CubeIcon />,
            link: '/templates',
            onClick: () => handleLinkClick('/templates')
        },
        {
            label: 'Workflows',
            icon: <ShuffleIcon />,
            link: '/workflows',
            onClick: () => handleLinkClick('/workflows')
        },
        {
            label: 'Chat',
            icon: <ChatBubbleIcon />,
            link: '/chat',
            onClick: () => handleLinkClick('/chat')
        },
        // {
        //     label: 'My Team',
        //     icon: <MixIcon />,
        //     onClick: () => handleLinkClick('/team')
        // },
    ];

    const handleLinkClick = (path) => {
        navigate(path);
    }

    const handleLogout = () => {
        logoutUser()
        .then(() => {
            dispatch({ type: AUTH_LOGOUT });
        })
        .catch(error => {
            notifications.showNotification({
                color: 'red',
                title: getAPIErrorMessage(error),
            })
        });
    }

    return (
    <Navbar 
        width={{ base: drawerMode ? '100%' : baseWidth }} 
        height={"100vh"} 
        fixed={ drawerMode ? false : true }
        p="xs"
        sx={(theme) => ({
            backgroundColor: theme.colors.brand[0],
            color: theme.colors.gray[4],
        })}>

        <Navbar.Section mt="xs">
            <Brand
                dispatch={dispatch}
                navbarStatus={navbarStatus}
                drawerMode={drawerMode}
                onDrawerToggle={onDrawerToggle} />
        </Navbar.Section>

        <Navbar.Section
            pb={10}
            >
            { links.map((link, index) => (
                <Tooltip
                    key={index}
                    disabled={ fullMode }
                    position="right"
                    label={link.label}
                    withArrow
                    sx={(theme) => ({
                        display: navbarStatus ? 'block' : 'inline-block',
                    })}>
                    <Button 
                        component='a'
                        href={link.link}
                        variant="subtle"
                        color="gray" 
                        radius="xs" 
                        size={ fullMode ? "md" : 'sm' }
                        leftIcon={link.icon}
                        fullWidth
                        sx={(theme) => ({
                            height: fullMode ? '50px' : '42px',
                            padding: fullMode ? '0 22px' : '0 10px',
                            color: theme.colors.gray[5],
                            backgroundColor: 'none',
                            '& .mantine-Button-inner': {
                                justifyContent: 'flex-start',
                            },
                            '&:hover': {
                                backgroundColor: theme.colors.gray[1],
                                color: theme.colors.dark[9]
                            },
                            '& .mantine-Button-leftIcon': {
                                marginRight: fullMode ? '10px' : '0px',
                            }
                        })}
                        onClick={(e) => {
                            e.preventDefault();
                            link.onClick();
                        }} >
                        { fullMode ? <Text>{ link.label || '' }</Text> : null }
                    </Button>
                </Tooltip>
            )) }
        </Navbar.Section>

        <Navbar.Section
            grow
            component={ScrollArea}
            sx={(theme) => ({
                borderTop: `2px solid ${theme.colors.brand[1]}`,
                padding: fullMode ? '15px 10px 15px 10px' : '15px 0px'
            })}>
            <ProjectsList
                drawerMode={drawerMode}
                onDrawerToggle={onDrawerToggle} />
        </Navbar.Section>

        <Navbar.Section>
            { authData ? <User 
                authData={authData}
                navbarStatus={navbarStatus}
                onLogout={handleLogout}
                drawerMode={drawerMode}
                onDrawerToggle={onDrawerToggle} /> : null }
        </Navbar.Section>

    </Navbar>
    )
}

const mapStateToProps = state => {
    return {
        authData: ( state.auth && state.auth.user ) ? state.auth.user : null,
        navbarStatus: ( state.global && state.global.navbar ) ? state.global.navbar : false,
    };
};

export default compose(
    connect(mapStateToProps)
)(MainNavBar);