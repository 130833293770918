import React, { useState, useMemo } from 'react';
// import { useParams } from "react-router-dom";
import { useMantineTheme, Card, Text, TextInput, Group } from '@mantine/core';
import { useClipboard, useDidUpdate } from '@mantine/hooks';
import { useModals } from '@mantine/modals';
import { Pencil1Icon, TrashIcon, CopyIcon, CheckIcon, Cross1Icon, PlusCircledIcon } from '@radix-ui/react-icons';

import ButtonIconCircle from '../ButtonIconCircle';

import { isArrayExists } from '../../helpers/validation';

const Field = ({
    isAddNew = false,
    isEditing = false, 
    field = {},
    value = '',
    onChange 
}) => {
    const isDisabled = useMemo(() => {
        if ( isAddNew ) {
            return ( field.disabled && field.disabled.find(i => i === 'add') ? true : false );
        } else if ( isEditing ) {
            return ( field.disabled && field.disabled.find(i => i === 'edit') ? true : false );
        } else {
            return false;
        }
    },[ isAddNew, isEditing, field ]);

    switch( field.type ) {
        case 'text':
            return <TextInput 
                label={ isEditing ? field.label : null }
                placeholder={field.label} 
                value={value} 
                disabled={isDisabled}
                onChange={(e) => onChange(e.target.value,field)} />
        default:
            return null;
    }
}

const Item = ({
    item = {},
    itemLabel = '',
    fields = [],
    isAddNew = false,
    isEditing = false,
    showCopy = false,
    copyProps = {
        label: 'Copy',
        success_label: 'Copied',
        key: 'label',
        icon: <CopyIcon />
    },
    onEdit = () => {},
    onUpdate = () => {},
    onCancelEdit = () => {},
    onDelete = () => {},
}) => {
    const theme = useMantineTheme();
    const modals = useModals();
    const clipboard = useClipboard({ timeout: 1000 });
    const [ formData, setFormData ] = useState({});

    useDidUpdate( () =>  {
        if ( ( isEditing || isAddNew ) ) {
            setFormData({ ...item });
        }
    },[ isEditing, isAddNew ]);

    const editMode = useMemo(() => {
        return isEditing || isAddNew ? true : false;
    }, [ isAddNew, isEditing ] );

    const handleUpdate = () => {
        onUpdate(formData);
    }

    const handleFieldUpdate = (newValue,field) => {
        let newData = { ...formData, [field.id]: newValue };
        if ( field.modifier ) {
            newData = field.modifier( newData, ( isAddNew ? 'add' : 'edit' ) );
        }
        setFormData(newData);
    }

    const openDeleteModal = () => modals.openConfirmModal({
        title: 'Remove ' + itemLabel + ( item.label ? ': ' + item.label : ( item.name ? ': ' + item.name : '' ) ),
        centered: true,
        children: (
        <>
            <Text size="sm">
                Are you sure you want to remove this {itemLabel}{( item.label ? ' ('+item.label+')' : ( item.name ? ' ('+item.name+')' : '' ) )}? 
            </Text>
            <Text size="sm" weight={"700"} color="red">
                This action cannot be undone.
            </Text>
        </>
        ),
        labels: { confirm: 'Remove', cancel: "No don't remove it" },
        confirmProps: { color: 'red' },
        onConfirm: () => onDelete(item),
    });

    return (
    <Card
        p={"xs"}
        radius="md"
        withBorder>
        <Group position='apart' spacing="md" align={"center"}>
            { editMode ? (
            <>
                <Group 
                    align={"center"}
                    position='left' 
                    spacing="20px">
                    { fields && isArrayExists( fields ) ? fields.map( (field, index) => (
                        <Field 
                            key={field.id}
                            isAddNew={isAddNew}
                            isEditing={isEditing} 
                            field={field}
                            value={( formData && formData[field.id] ) || '' }
                            onChange={handleFieldUpdate} />
                    )) : null }
                </Group>
                <Group position='right' spacing={"5px"}>
                    { isAddNew ? <ButtonIconCircle
                        label="Add New"
                        icon={<PlusCircledIcon />}
                        btnProps={{
                            variant: "filled",
                            color: 'indigo',
                            radius: 'md',
                            size: 'xs'
                        }}
                        color={theme.colors.indigo[8]}
                        onClick={handleUpdate} /> : <ButtonIconCircle
                        label="Update"
                        icon={<CheckIcon />}
                        btnProps={{
                            variant: "filled",
                            color: 'indigo',
                            radius: 'md',
                            size: 'xs'
                        }}
                        color={theme.colors.indigo[8]}
                        onClick={handleUpdate} /> }
                    <ButtonIconCircle
                        label="Cancel"
                        icon={<Cross1Icon />}
                        btnProps={{
                            variant: "filled",
                            color: 'dark',
                            radius: 'md',
                            size: 'xs'
                        }}
                        color={theme.colors.dark[8]}
                        onClick={() => onCancelEdit()} />
                </Group>        
            </>
            ) : (
            <>
                <Text>{item.label}</Text>
                <Group position='right'>
                    { showCopy && <ButtonIconCircle
                        icon={copyProps.icon}
                        label={ clipboard.copied ? copyProps['success_label'] : copyProps['label'] }
                        useClipboard={true}
                        btnProps={{
                            variant: "light",
                            color: 'gray',
                            radius: 'md',
                            size: 'xs'
                        }}
                        color={theme.colors.indigo[8]}
                        onClick={() => clipboard.copy( copyProps.filter ? copyProps.filter( item[ copyProps['key'] ] || '' ) : item[ copyProps['key'] ] || '' )}
                    /> }
                    <ButtonIconCircle
                        label="Edit"
                        icon={<Pencil1Icon />}
                        btnProps={{
                            variant: "light",
                            color: 'lime',
                            radius: 'md',
                            size: 'xs'
                        }}
                        color={theme.colors.lime[8]}
                        onClick={() => onEdit(item)} />
                    <ButtonIconCircle
                        label="Remove"
                        icon={<TrashIcon />}
                        btnProps={{
                            variant: "light",
                            color: 'red',
                            radius: 'md',
                            size: 'xs'
                        }}
                        color={theme.colors.red[8]}
                        onClick={openDeleteModal} />
                </Group>
            </>
            ) }
        </Group>
    </Card>
    )
}

export default Item;