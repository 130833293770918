
import {
	TOGGLE_NAVBAR,
	EXPAND_NAVBAR,
	SHRINK_NAVBAR,
    CHANGES_MADE,
    CHANGES_RESET,
    TOGGLE_GLOBAL_DISABLED,
    TOGGLE_EDITOR_DISABLED
} from './types';

const initialState = {
    navbar: true,
    disabled: false,
    changes_made: false
};

export const global = (state = initialState, action) => {
    const { type } = action;
    switch (type) {
        case TOGGLE_NAVBAR:
            return {
                ...state,
                navbar: !state.navbar
            };
        case EXPAND_NAVBAR:
                return {
                    ...state,
                    navbar: true
                };
        case SHRINK_NAVBAR:
            return {
                ...state,
                navbar: false
            };
        case CHANGES_MADE:
            return {
                ...state,
                changes_made: true
            };
        case CHANGES_RESET:
            return {
                ...state,
                changes_made: null
            };
        case TOGGLE_GLOBAL_DISABLED:
            return {
                ...state,
                disabled: !state.disabled
            };
        case TOGGLE_EDITOR_DISABLED:
            return {
                ...state,
                editor_disabled: !state.editor_disabled
            };
        default:
            return state;
    }
}