
import { isArrayExists } from './validation';

export const tiptapApplyHTMLMarks = ( text = '', item = {}, ignore = [] ) => {
    let replace = '';
    if ( item.marks && isArrayExists( item.marks ) ) {
        item.marks.forEach(mark => {
            switch( mark.type ) {
                case 'italic':
                    if ( !( ignore && isArrayExists( ignore ) && ignore.find( i => i === mark.type ) ) ) {
                        replace = '<em>'+item.text.trim()+'</em>';
                    }
                    break;
                case 'bold':
                    if ( !( ignore && isArrayExists( ignore ) && ignore.find( i => i === mark.type ) ) ) {
                        replace = '<strong>'+item.text.trim()+'</strong>';
                    }
                    break;
                case 'code':
                    if ( !( ignore && isArrayExists( ignore ) && ignore.find( i => i === mark.type ) ) ) {
                        replace = `<code class="code-hightlight">${item.text.trim()}</code>`;
                    }
                    break;
            }
        });
    }
    if ( replace !== '' ) {
        text = text.replace( item.text, replace );
    }
    return text;
}

export const tiptapApplyMDMarks = ( text = '', item = {}, ignore = [] ) => {
    let replace = '';
    if ( item.marks && isArrayExists( item.marks ) ) {
        item.marks.forEach(mark => {
            switch( mark.type ) {
                case 'italic':
                    if ( !( ignore && isArrayExists( ignore ) && ignore.find( i => i === mark.type ) ) ) {
                        replace = '_'+item.text.trim()+'_';
                    }
                    break;
                case 'bold':
                    if ( !( ignore && isArrayExists( ignore ) && ignore.find( i => i === mark.type ) ) ) {
                        replace = '__'+item.text.trim()+'__';
                    }
                    break;
                case 'code':
                    if ( !( ignore && isArrayExists( ignore ) && ignore.find( i => i === mark.type ) ) ) {
                        replace = "`"+item.text.trim()+"`";
                    }
                    break;
            }
        });
    }
    if ( replace !== '' ) {
        text = text.replace( item.text, replace );
    }
    return text;
}

export const tiptapGetMarkDownBySelection = ({ start = 0, until = 0, offset = 0, editor = null, node = null, ignoreMarks = [] }) => {
    const { content = [] } = ( editor ? editor.state.doc.toJSON() : {} );
    const selectedNodes = ( isArrayExists( content ) ? content.slice(start, until+1) : [] );
    // console.log( selectedNodes );
    let markdown = '';
    selectedNodes.forEach((node, index) => {
        switch( node.type ) {
            case 'paragraph':
                markdown += ( markdown && markdown !== '' ? '\n\n' : '' );
                node.content.forEach(childNode => {
                    if ( childNode.type === 'text' ) {
                        markdown += tiptapApplyMDMarks( ( childNode.text || '' ), childNode, ignoreMarks );
                    }
                });
                break;
            case 'heading':
                markdown += ( markdown && markdown !== '' ? '\n\n' : '' );
                node.content.forEach(childNode => {
                    if ( childNode.type === 'text' ) {
                        markdown += '#'.repeat( node.attrs.level ) + ' ' + childNode.text;
                    }
                });
                break;
            case 'codeBlock':
                markdown += ( markdown && markdown !== '' ? '\n\n' : '' ) + '```';
                node.content.forEach(childNode => {
                    if ( childNode.type === 'text' ) {
                        markdown += ( childNode.text && childNode.text !== '' ? '\n' : '' );
                        markdown += '#'.repeat( node.attrs.level ) + ' ' + childNode.text;
                    }
                });
                markdown += '\n```';
                break;
        }
    });

    return markdown;
}