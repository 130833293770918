
import { 
    RESET_TEMPLATE_OPTIONS,
    RESET_TEMPLATES,
    RESET_TEMPLATE
} from './types';

export const resetTemplates = () => {
    return dispatch => {
        setTimeout(() => {
            dispatch({
                type: RESET_TEMPLATES,
            });
        },150);
    }
}

export const resetTemplate = () => {
    return dispatch => {
        setTimeout(() => {
            dispatch({
                type: RESET_TEMPLATE,
            });
        },150);
    }
}

export const resetTemplateOptions = () => {
    return dispatch => {
        setTimeout(() => {
            dispatch({
                type: RESET_TEMPLATE_OPTIONS,
            });
        },150);
    }
}