import React from 'react';
import { Loader, Center, Box, Title, Avatar } from '@mantine/core';
import { MagicWandIcon } from '@radix-ui/react-icons';


import { PLATFORM_NAME } from '../../constants';

const ScreenLoader = ({
    loaderProps = {}
}) => {
    return (
    <Box sx={(theme) => ({
        height: '100%',
        minHeight: '100vh',
        backgroundColor: theme.colors.brand[0],
    })}>
        <Center style={{ width: '100%', height: '100%', minHeight: '100vh' }}>
            <Box sx={(theme) => ({ textAlign: 'center' })}>
                <Avatar 
                    color="gray" radius="6px"
                    sx={(theme) => ({
                        width: '60px',
                        minWidth: '60px',
                        height: '60px',
                        margin: '0 auto',
                    })}
                    ><MagicWandIcon style={{ width: 30, height: 30 }} /></Avatar>
                <Box sx={(theme) => ({
                    paddingTop: theme.spacing.md,
                    paddingBottom: '45px',
                })}>
                    <Title variant="h1" sx={(theme) => ({ color: theme.colors.gray[0] })}>{PLATFORM_NAME}</Title>
                </Box>
                <Loader 
                    size="xl" 
                    color="indigo"
                    variant="bars"
                    {...loaderProps} />
            </Box>
        </Center>
    </Box>
    )
}

export default ScreenLoader;