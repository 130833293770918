import React, { useMemo } from 'react';
import { Text, Box } from '@mantine/core';

import { isArrayExists } from '../../helpers/validation';

const MultilineText = ({
    text = '',
    textProps = {}
}) => {

    const splitData = useMemo(() => {
        return text.split('\n');
    }, [ text ] );

    return splitData && isArrayExists( splitData ) ? (
    <Box
        sx={(theme) => ({
            '& > p': {
                margin: '0 0 5px 0',
                '&:last-child': {
                    marginBottom: '0px'
                }
            }
        })}>
        {splitData.map( (string,index) => (
            <Text key={index} {...textProps}>{string}</Text>
        ) )}
    </Box>
    ) : ( text || '' );
}

export default MultilineText;