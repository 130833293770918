import React from 'react';
import { Loader, Center } from '@mantine/core';


const LoaderComp = ({
    height = '350px',
    loaderProps = {}
}) => {
    return (
    <Center style={{ width: '100%', height }}>
        <Loader size="lg" color="indigo" variant="bars" {...loaderProps} />
    </Center>
    )
}

export default LoaderComp;