// import './reset.css';
import './styles.css';
import 'remixicon/fonts/remixicon.css';
import 'reactflow/dist/style.css';

import React from 'react';
import ReactDOM from 'react-dom';
import { initializeApp } from "firebase/app";

import App from './app';
// import reportWebVitals from './reportWebVitals';

import { FIREBASE_CONFIG_DATA } from './firebase';

ReactDOM.render(
  <React.StrictMode>
    <App 
      fbApp={initializeApp(FIREBASE_CONFIG_DATA)} />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();