
import { replaceAll } from './data';

export const validate = function( value, type ) {
	let returnData = false;

	switch( type ) {
		case 'alphanumeric': returnData = ( value && isAlphanumeric(value) ? value : false ); break;
		case 'numeric': returnData = ( value && isNumeric(value) ? value : false ); break;
		case 'integer': returnData = ( value && isInteger(value) ? value : false ); break;
		case 'currency': returnData = ( value && isCurrency(value) ? value : false ); break;
		case 'timestamp': returnData = ( value && isTimeStamp(value) ? value : false ); break;
		case 'email': returnData = ( value && validateEmail(value) ? value : false ); break;
		case 'phone': returnData = ( value && isPhoneNumber(value) ? value : false ); break;
		case 'string_id': returnData = ( value && isStringID(value) ? value : false ); break;
		case 'numeric_id': returnData = ( value && isNumericID(value) ? value : false ); break;
		case 'key': returnData = ( value && isKey(value) ? value : false ); break;
		case 'is_nan': returnData = ( value && isNan(value,false) ? value : false ); break;
		case 'safe_escape': returnData = ( value ? safeEscape(value) : false ); break;
	}

	return returnData;
}

// validate email
export const validateEmail = function(email) {
	const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(email);
}

// check whether string provided is valid phone number
export const isPhoneNumber = function(string) {
	const re = /^\+?([0-9 -]+)$/;
	return re.test(string);
}

// check whether string provided is valid string id
export const isStringID = function(string) {
	const re = /^([a-zA-Z0-9_-]+)$/;
	return re.test(string);
}

// check whether string provided is valid numeric id
export const isNumericID = function(string) {
	const re = /^([0-9-]+)$/;
	return re.test(string);
}

// check whether string provided is currency
export const isCurrency = function(string) {
	const re = /^([0-9-.]+)$/;
	return re.test(string);
}

// check whether string provided is valid key
export const isKey = function(string) {
	const re = /^([a-zA-Z0-9-_]+)$/;
	return re.test(string);
}

// check whether string provided is valid string name
export const isStringName = function(string) {
	const re = /^([a-zA-Z0-9 _-]+)$/;
	return re.test(string);
}

// check whether string provided is alphanumeric
export const isAlphanumeric = function(string) {
	const re = /^([a-zA-Z0-9]+)$/;
	return re.test(string);
}

// check whether string provided is numeric
export const isNumeric = function(string) {
	const re = /^([0-9]+)$/;
	return re.test(string);
}

// check whether string provided is integer
export const isInteger = function(string) {
	const re = /^([0-9-]+)$/;
	return re.test(string);
}

// check whether string provided is NaN
export const isNan = function(number) {
    const re = /^([0-9-.]+)$/;
    return re.test(number);
}

// check whether value provided is timestamp
export const isTimeStamp = function(value) {
	return ( ( new Date(value) ).getTime() > 0 ? true : false );
};

// is array exists
export const isArrayExists = function(array) {
    return ( array && array.length > 0 && Array.isArray(array) ? true : false );
}

// is array exists
export const isObjectExists = function(object) {
    return ( object && Object.keys( object ).length > 0 && object !== null && typeof object === 'object' ? true : false );
}

// check whether string provided is price (max 2 decimal)
export const isPrice = function(string) {
	var valid = false,
		re = /^([0-9.]+)$/;
	if ( re.test(string) ) {
		valid = true;
	}

	return valid;
}

// filter out unsafe string
export const safeEscape = function(string) {
	let returnData = string;

	if ( string.indexOf('>') > -1 )
		returnData = replaceAll( returnData, '>', '' );

	if ( string.indexOf('"') > -1 )
		returnData = replaceAll( returnData, '"', '' );

	// if ( string.indexOf("'") > -1 )
	// 	returnData = replaceAll( returnData, "'", '' );

	return returnData;
}
