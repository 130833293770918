/* eslint-disable */
import axios from 'axios';
import qs from 'qs';

import { isArrayExists, isObjectExists } from './validation';

import { FIREBASE_CLOUD_API } from '../constants';
import { getIDToken } from '../redux/auth/api';

// trigger Functions API
export const callFunctionsAPI = ( props ) => {
    const { url, action, formData, method = 'post', params = [] } = props;
    return new Promise((resolve,reject) => {

        getIDToken()
        .then(idToken => {

            doPromise({
                idToken,
                method,
                url,
                params: ( isArrayExists(params) ? [ ...params, { key: 'action', value: action } ] : [{ key: 'action', value: action }] ),
                formData
            })
            .then(res => {
                resolve(res.data);
            })
            .catch(error => {
                reject(error);
            });

        })
        .catch(error => {
            reject(error);
        });

    });
}

/* perform axios via promise */
export const doPromise = function( props ) {
    const { idToken, method, url, formData = {}, params = [], api_url } = props;
    return new Promise((resolve,reject) => {

        let paramString = '';
        if ( isArrayExists(params) ) {
            let paramCount = 1;
            params.forEach((param) => {
                paramString += ( paramCount > 1 ? '&' : '' ) + param.key + "=" + param.value;
                paramCount++;
            });
        }

        let query = { 
            method: method, 
            baseURL: api_url || FIREBASE_CLOUD_API, 
            url: '/' + url + ( idToken ? '?token=' + idToken + ( paramString && paramString.length > 0 ? '&' + paramString : '' ) : ( paramString && paramString.length > 0 ? '?' + paramString : '' ) ),
        };

        if ( isObjectExists( formData ) ) {
            query['headers'] = { 'Content-Type': 'application/x-www-form-urlencoded' };
            query['data'] = qs.stringify(formData)
        }

        axios(query)
        .then(function(response) {
            resolve(response);
        })
        .catch(function(error) {
            reject(error);
        });

    });
};

// get error message
export const getAPIErrorMessage = (error) => {
    return ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unknown API Error' ) );
}

// delay function call 
export const delayedFunctionCall = ( props, delay = 2000 ) => {
    return new Promise((resolve,reject) => {
        setTimeout(() => {
            resolve( callFunctionsAPI(props) );
        }, delay);
    });
}