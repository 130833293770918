import React, { useState, useMemo } from 'react';
// import { useParams } from "react-router-dom";
import { Slider, NumberInput, Group, Text, Box, Space } from '@mantine/core';
// import { useDidUpdate } from '@mantine/hooks';
// import { useNotifications } from '@mantine/notifications';
// import { PlusIcon } from '@radix-ui/react-icons';

// import Item from './item';

// import { isArrayExists } from '../../helpers/validation';


const FormRangeInput = ({
    label = '',
    desc = '',
    step = 1,
    min = 0,
    max = 100,
    precision = 0,
    value = 0,
    onChange = () => {},
}) => {
    // const theme = useMantineTheme();

    return (
    <Box>
        <Box
            sx={(theme) => ({
                '& .mantine-NumberInput-wrapper': {
                    maxWidth: '50px'
                }
            })}>
            <Group position='apart' align={"center"}>
                <div>
                    <Text size="sm">{label}</Text>
                    <Text size="xs" color={"dimmed"}>{desc}</Text>
                </div>
                <NumberInput
                    value={value}
                    max={max}
                    min={min}
                    step={step}
                    precision={precision}
                    size="xs"
                    hideControls
                    onChange={(newValue) => onChange(newValue)} />
            </Group>
        </Box>
        <Space h="xs" />
        <Slider
            label={ precision ? parseFloat(value).toFixed(precision) : null }
            value={value}
            step={step}
            min={min}
            max={max}
            color="indigo"
            onChange={(newValue) => {
                onChange(newValue);
            }} />
    </Box>
    )
}

export default FormRangeInput;