import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
// import { useNavigate } from "react-router-dom";
import { Title, Grid, Group } from '@mantine/core';
// import { PlusIcon } from '@radix-ui/react-icons';

import ProjectAddNew from './addnew';
import ProjectList from './list';

import { useScreenSize } from '../../redux/global/hooks';

const Projects = ({
    authData
}) => {
    const [ screenSize, mobileMode ] = useScreenSize();

    return (
    <>
        <Grid justify={"center"}>
            <Grid.Col span={ mobileMode ? 12 : 6 }>
                <Title order={2}>Projects</Title>
            </Grid.Col>
            <Grid.Col span={ mobileMode ? 12 : 6 }>
                <Group position={ mobileMode ? 'center' : 'right' }>
                    <ProjectAddNew
                         />
                </Group>
            </Grid.Col>
        </Grid>

        <ProjectList />        

    </>
    )
}

const mapStateToProps = state => {
    return {
        authData: ( state.auth && state.auth.user ) ? state.auth.user : null,
    };
};

export default compose(
    connect(mapStateToProps)
)(Projects);