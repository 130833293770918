import React, { useMemo, useState, useEffect } from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { getFirestore, doc, onSnapshot } from "firebase/firestore";
// import { useParams } from "react-router-dom";
import { Box, Modal, Grid, Title, Space, Divider, LoadingOverlay } from '@mantine/core';
// import { useDidUpdate } from '@mantine/hooks';
// import { BackpackIcon } from '@radix-ui/react-icons';

import StepsNav from './nav';
import Step from './step';

import { isArrayExists } from '../../helpers/validation';

import { getWizardTypeData, getWizardType, getWizardFormTypeData, getWizardStepIndex } from './helpers';

import { GET_WIZARD, RESET_WIZARD } from './redux/types';
// import { toggleGlobalDisabled } from '../../redux/global/actions';

const Wizard = ({
    authData,
    globalDisabled,
    dispatch,
    editorRef = null,
    document,
    selectedWizard = {},
    opened = false,
    type = '',
    onUpdate = () => {},
    onClose = () => {}
}) => {
    const db = getFirestore();
    const [ currentStep, setCurrentStep ] = useState(0);
    const [ wizardID, setWizardID ] = useState(false);
    const [ wizardModel, setWizardModel] = useState(false);
    const [ contents, setContents ] = useState([]);
    const [ loading, setLoading ] = useState(false);
    const [ wizardStatus, setWizardStatus ] = useState('setup');

    // set wizard id based on selection before modal is triggered
    useEffect(() => {
        if ( opened ) {
            setWizardID( selectedWizard && selectedWizard.id ? selectedWizard.id : false );
            setWizardModel( selectedWizard && selectedWizard.model ? selectedWizard.model : false );
        }
    },[ opened, selectedWizard ]);

    const wizard = useMemo(() => {
        return ( type && type.length ? getWizardType(type) : false );
    },[type]);

    useEffect(() => {
        let unsubscribe;
        // reset
        if ( opened && wizardID ) {
            setLoading(true);
            unsubscribe = onSnapshot(doc(db, "documents", document.id, "wizards", wizardID), (doc) => {
                const data = ( doc.exists ? doc.data() : null );
                // dispatch wizard data
                dispatch({ type: GET_WIZARD, payload: { wizard: data } });
                // set contents
                let newContents = [];
                if ( wizard && wizard.steps ) {
                    wizard.steps.forEach(step => {
                        let item = {
                            id: step.id,
                            formdata: {},
                            results: data && data.generated_content && isArrayExists( data.generated_content ) ? data.generated_content.filter(item => item.step_id === step.id) : [],  
                        };

                        // sort results based on added_on
                        if ( isArrayExists( item.results ) ) {
                            item.results = item.results.sort((a,b) => {
                                return ( a.added_on < b.added_on ? -1 : 1 );
                            });
                            // reverse it
                            item.results = item.results.reverse();
                        } // end - item.results

                        switch( step.type ) {
                            case 'form':
                                item.formdata = getWizardFormTypeData(data,step);
                                newContents.push(item);
                                break;
                            case 'selector':
                                item.formdata = data.selected_content && data.selected_content[step.id] ? data.selected_content[step.id] : {};
                                newContents.push(item);
                                break;
                        }
                    })
                }
                // update state
                setContents(newContents);
                setLoading(false);
                setWizardStatus(( data && data.status ) ? data.status : 'setup');
                // if status is process, jump to generation step
                if ( data && data.status === 'process' ) {
                    setCurrentStep(getWizardStepIndex(type,'generation'));
                }
                // if status is complete, jump to complete step
                if ( data && data.status === 'complete' ) {
                    setCurrentStep(getWizardStepIndex(type,'generation'));
                }
            });
        } else {
            setContents([]);
            setCurrentStep(0);
            setLoading(false);
            setWizardStatus('setup');
        }
        return () => {
            if ( opened && wizardID && unsubscribe ) {
                // unsubscribe here
                unsubscribe();
                dispatch({ type: RESET_WIZARD });
            }   
        }
    }, [ opened, wizardID ] );

    const disabled = useMemo(() => {
        return ( loading || globalDisabled ? true : false );
    },[ loading, globalDisabled ]);

    const handleClose = () => {
        onClose();
    }

    return (
    <>
        <Modal
            opened={opened}
            centered
            closeOnClickOutside={false}
            closeOnEscape={false}
            withCloseButton={true}
            size="90vw"
            padding="0px"
            overflow="outside"
            onClose={handleClose}
            sx={(theme) => ({
                '& .mantine-Modal-modal': {
                    background: 'none'
                },
                '& .mantine-Modal-header': {
                    marginRight: '0px',
                    marginBottom: '5px',
                    '& .mantine-Modal-close': {
                        color: theme.colors.gray[0],
                        opacity: ( disabled ? 0.5 : 1 ),
                        pointerEvents: ( disabled ? 'none' : 'all' ),
                        '&:hover': {
                            color: theme.colors.dark[8]
                        }
                    }
                },
            })}>
            <Box
                sx={(theme) => ({
                    minHeight: '75vh',
                    backgroundColor: theme.colors.gray[4]
                })}>
                <LoadingOverlay
                    visible={loading} />
                <Grid gutter={"none"} style={{ minHeight: '75vh' }}>
                    { wizardStatus && wizardStatus === 'complete' ? null : (
                    <Grid.Col sm={12} md={3}>
                        <Box 
                            sx={(theme) => ({
                                padding: '20px',
                            })}>
                            <Title order={3} sx={(theme) => ({ color: theme.colors.dark[8] })}>
                                {getWizardTypeData(type,'label')}
                            </Title>
                            <Space h="sm" />
                            <Divider size={2} sx={(theme) => ({
                                width: '50px',
                                borderColor: theme.colors.dark[8],
                            })} />
                            <Space h="sm" />
                            <StepsNav
                                wizard={wizard}
                                document={document}
                                currentStep={currentStep}
                                wizardStatus={wizardStatus}
                                disabled={disabled || ( wizardStatus === 'complete' || wizardStatus === 'process' )}
                                onStepUpdate={(newStep) => setCurrentStep(newStep)} />
                        </Box>
                    </Grid.Col>
                    )}
                    <Grid.Col sm={12} md={ wizardStatus && wizardStatus === 'complete' ? 12 : 9 }>
                        <Box 
                            sx={(theme) => ({
                                background: theme.colors.indigo[8],
                                padding: '20px',
                                height: 'calc( 100% - 40px )',
                            })}>
                            <Step
                                editorRef={editorRef}
                                wizardModel={wizardModel}
                                document={document}
                                wizard={wizard}
                                contents={contents}
                                currentStep={currentStep}
                                onStepUpdate={(newStep) => setCurrentStep(newStep)}
                                onUpdate={(newValue) => setContents(newValue)}
                                onWizardUpdate={(newWizardID) => {
                                    if ( !wizardID || wizardID !== newWizardID ) {
                                        setWizardID(newWizardID);
                                    }
                                }}
                                onWizardClose={onClose} />
                        </Box>
                    </Grid.Col>
                </Grid>
            </Box>
        </Modal>
    </>
    );
}

const mapStateToProps = state => {
    return {
        authData: ( state.auth && state.auth.user ) ? state.auth.user : null,
        globalDisabled: ( state.global && state.global.disabled ) ? state.global.disabled : false,
    };
};

export default compose(
    connect(mapStateToProps)
)(Wizard);