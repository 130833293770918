import React, { useState, useEffect, useRef } from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
// import { useParams } from "react-router-dom";
import { Box, Card, Group, Text, Avatar, Space, ScrollArea, Button } from '@mantine/core';
// import { useWindowScroll } from '@mantine/hooks';
import { LightningBoltIcon } from '@radix-ui/react-icons';

import Result from './result';

import { isArrayExists } from '../../helpers/validation';

// import { getWizardTypeData } from './helpers';

const Results = ({
    globalDisabled,
    document,
    wizard = false,
    step = false,
    selected = {},
    results = [],
    currentStep = 0,
    onSelect = () => {},
    onSuggestionTrigger = () => {},
    onDelete = () => {},
}) => {
    const viewport = useRef();
    const [ generating, setGenerating ] = useState(false);

    useEffect(() => {
        viewport.current.scrollTo({ top: 0, behavior: 'smooth' });
    }, [ results, currentStep ] );

    useEffect(() => {
        if ( generating )
            setGenerating(false);
    },[ results ]);

    const handleDelete = (item) => {
        // remove item from results
        onDelete(results.filter(result => result.id !== item.id));
    }

    const handleGenerate = () => {
        setGenerating(true);
        onSuggestionTrigger();
    }

    return (
    <Card
        shadow={"md"}
        radius="md"
        p="lg"
        pb="xs"
        sx={(theme) => ({
            height: '100%',
            margin: '10px',
        })}>

        <Group>
            <Avatar color="indigo" radius={"md"}><Text size="md">{currentStep+1}</Text></Avatar>
            <Text size="lg" weight="500">{step.label}</Text>
        </Group>

        { step.desc && (
        <>
            <Space h="sm" />
            <Text size="sm" weight="400">{step.desc}</Text>
        </>
        ) }

        <Space h="sm" />
    
        <ScrollArea
            viewportRef={viewport}
            sx={(theme) => ({
                height: 'calc( 75vh - 220px )',
                borderRadius: theme.radius.md,
                border: '2px solid ' + theme.colors.gray[5], 
                background: theme.colors.gray[2],
            })}>
            {results && isArrayExists( results ) && (
            <Box sx={(theme) => ({
                padding: '20px 25px 20px 20px',
                background: theme.colors.gray[2],
                '& > div': {
                    marginBottom: theme.spacing.sm
                }
            })}>
                {results.map((result,index) => (
                    <Result 
                        result={result} 
                        key={index} 
                        selected={selected}
                        onSelect={onSelect}
                        onDelete={handleDelete} />
                ))}
        
            </Box>
            )}
        </ScrollArea>

        <Space h="md" />

        <Group 
            position='center'>
            <Button
                color="dark"
                size="md"
                disabled={globalDisabled}
                loading={generating}
                leftIcon={<LightningBoltIcon />}
                onClick={handleGenerate}>
                Generate More
            </Button>
        </Group>

    </Card>
    );
}

const mapStateToProps = state => {
    return {
        authData: ( state.auth && state.auth.user ) ? state.auth.user : null,
        globalDisabled: ( state.global && state.global.disabled ) ? state.global.disabled : false,
    };
};

export default compose(
    connect(mapStateToProps)
)(Results);