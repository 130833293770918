import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { compose } from "recompose";
import { useDispatch, useSelector } from "react-redux";
// import { useParams } from "react-router-dom";
import { Card, Space, Grid, Box, Group } from '@mantine/core';
import { useViewportSize } from '@mantine/hooks';

import Canvas from './canvas';


import { shrinkNavbar, expandNavbar, appChangesMade, appChangesReset } from '../../redux/global/actions';
import { useScreenSize } from '../../redux/global/hooks';

const initialNodes = [
    {
        id: '1',
        data: { label: 'Hello' },
        position: { x: 0, y: 0 },
        type: 'input',
    },
    {
        id: '2',
        data: { label: 'World' },
        position: { x: 100, y: 100 },
    },
    {
        id: '3',
        data: { label: 'World' },
        position: { x: 200, y: 200 },
    },
];

const Workflow = () => {
    const changesMade = useSelector(state => state.global && state.global.changes_made);
    const [ screenSize, mobileMode ] = useScreenSize();
    const dispatch = useDispatch();
    const { height } = useViewportSize();
    const [ workflow, setWorkflow ] = useState({
        nodes: [],
        edges: [],
    });

    useEffect(() => {
        setWorkflow({
            nodes: [ ...initialNodes ],
            edges: [],
        });
    },[]);

    useEffect(() => {
        dispatch(shrinkNavbar());
        return () => {
            if ( !mobileMode ) {
                dispatch(expandNavbar());
            }
        }
    },[]);

    const canvasHeight = useMemo(() => {
        return height - 96;
    },[ height ]);

    const handleSaveChanges = () => {

    }

    return (
    <>
        <Group
            sx={(theme) => ({
                borderBottom: '1px solid ' + theme.colors.gray[3],
                paddingBottom: theme.spacing.md,
                marginBottom: theme.spacing.md,
            })}>

        </Group>
        <Box
            sx={(theme) => ({
                height: canvasHeight,
                paddingRight: theme.spacing.md,
            })}>
            <Canvas
                height={canvasHeight}
                workflow={workflow}
                onSave={handleSaveChanges}
                />
        </Box>
    </>
    );
}

export default Workflow;