import React, { useState, useMemo } from 'react';
import { Button, Tooltip } from '@mantine/core';

const ButtonIconCircle = ({
    label = '',
    btnProps = {},
    btnSx = {},
    icon = null,
    position = 'top',
    color = false,
    useClipboard = false,
    onClick = () => {}
}) => {
    const [ opened, setOpened ] = useState(false);

    const iconBtnProps = useMemo(() => {
        return {
            variant: 'outline',
            color: "dark",
            radius: "100%",
            size: "sm",
            ...btnProps
        };
    }, [ btnProps ]);

    const handleClick = () => {
        if ( !useClipboard )
            setOpened(false);
        
        onClick();
    }

    return (
    <Tooltip
        opened={opened}
        position={position}
        label={label}
        withArrow>
        <Button
            aria-describedby="tooltip-id"
            // onFocus={() => setOpened(true)}
            onMouseEnter={() => setOpened(true)}
            // onBlur={() => setOpened(false)}
            onMouseLeave={() => setOpened(false)}
            {...iconBtnProps}
            sx={(theme) => ({
                padding: '0px',
                width: '36px',
                '&:hover': {
                    backgroundColor: color || theme.colors.brand[0],
                    color: theme.colors.gray[0],
                    border: 'none'
                },
                ...btnSx
            })}
            onClick={handleClick}>
            {icon}
        </Button>
    </Tooltip>
    )
}

export default ButtonIconCircle;