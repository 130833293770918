
export const groupSequentialNumbers = (array) => {
    let result = [];
    let temp = [];
    array.forEach(item => {
        if ( temp.length === 0 ) {
            temp.push(item);
        } else {
            if ( item - temp[temp.length - 1] === 1 ) {
                temp.push(item);
            } else {
                result.push(temp);
                temp = [];
                temp.push(item);
            }
        }
    });
    result.push(temp);
    return result;
}

export const getFormattedCredits = (credits) => {
    return ((credits).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')).toString().replace('.00','');
}