import React, { useMemo, useState } from 'react';
// import { useParams } from "react-router-dom";
import { Stepper, Box } from '@mantine/core';
// import { BackpackIcon } from '@radix-ui/react-icons';

import { isArrayExists } from '../../helpers/validation';

// import { getWizardStepIndex } from './helpers';

const StepsNav = ({
    document,
    wizard = false,
    wizardStatus = 'setup',
    disabled = false,
    currentStep = 0,
    onStepUpdate = () => {}
}) => {

    const handleStepChange = (index) => {
        onStepUpdate(index);
    }

    const isActive = (index) => {
        return ( index === currentStep );
    }

    const isDisabled = (index) => {
        return ( disabled || index > currentStep ? true : false );
    }

    const isLoading = (step) => {
        return ( step && step.type && step.type === 'generation' && wizardStatus && wizardStatus === 'process' ? true : false );
    }

    return wizard && wizard.steps && isArrayExists( wizard.steps ) ? (
    <Box sx={(theme) => ({
        padding: '20px 0'
    })}>
        <Stepper 
            color="indigo"
            size="md"
            radius="md"
            active={currentStep}
            onStepClick={handleStepChange} 
            orientation="vertical">
            {wizard.steps.map((step, index) => (
                <Stepper.Step 
                    key={step.id}
                    label={step.label}
                    allowStepSelect={!isDisabled(index)}
                    loading={isLoading(step)} />
            ))}
        </Stepper>  
    </Box>
    ) : null ;
}

export default StepsNav;