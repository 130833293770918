import { useRef, useEffect } from 'react';
import { Card, ScrollArea } from '@mantine/core';

import TextInput from '../TextInput';
import ChatBox from '../ChatBox';

const ChatContainer = ({
    messages = [],
    disabled = false,
    loading = false,
    onAdd = () => {},
    onUpdate = () => {},
}) => {
    const ScrollAreaRef = useRef(null);

    useEffect(() => {
        // when added new message, scroll to bottom
        // check if new message is from bot
        if ( messages && messages.length > 2 
            // && messages[messages.length - 1].role === 'assistant' 
        ) {
            if (ScrollAreaRef.current) {
                // scroll to bottom
                ScrollAreaRef.current.scrollTo({ 
                    top: ScrollAreaRef.current.scrollHeight,
                    behavior: 'smooth'
                });
            }
        }
    }, [ messages ]);

    return (
    <Card
        shadow="sm" 
        p="0px" 
        radius="md" 
        withBorder
        sx={() => ({
            minHeight: 'calc( 100vh - 100px )',
        })}
        >
        <Card.Section>
            <ScrollArea
                style={{ height: 'calc( 100vh - 200px )' }}
                viewportRef={ScrollAreaRef}
                >
                <ChatBox
                    messages={messages} 
                    onUpdate={onUpdate}
                    />
            </ScrollArea>
        </Card.Section>
        <Card.Section
            p="lg"
            sx={(theme) => ({
                borderTop: '1px solid #ddd',
                background: theme.colors.blue[0],
            })}
            >
            <TextInput
                disabled={disabled}
                loading={loading}
                onSend={onAdd}
                />
        </Card.Section>
    </Card>
    )
}

export default ChatContainer;