import React, { useState, useMemo } from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
// import { useParams } from "react-router-dom";
import { Box, Text, Avatar, Group, Space, SegmentedControl, Button } from '@mantine/core';
// import { useHotkeys } from '@mantine/hooks';
// import { useNotifications } from '@mantine/notifications';
import { DownloadIcon } from '@radix-ui/react-icons';

// import AIModelSelector from '../../modules/AIModelSelector';

// import { toggleGlobalDisabled } from '../../redux/global/actions';


const PanelExport = ({
    editorRef = null,
    authData,
    navbarStatus,
    dispatch,
    documentData,
    onUpdate = () => {}
}) => {
    const editor = editorRef.current;
    const [ format, setFormat ] = useState('html');

    const handleExport = () => {
        // convert string to snakeCase
        const docName = ( ( documentData && documentData.name ) ? documentData.name : 'Untitled' ).toLowerCase().replace(/ /g, '_');
        let fileName = docName+'.txt';
        let fileType = 'text/plain';
        let data = editor.getText();
        switch( format ) {
            case 'html':
                fileName = docName+'.html';
                fileType = 'text/html';
                data = editor.getHTML();
                break;
            case 'json':
                fileName = docName+'.json';
                fileType = 'application/json';
                data = JSON.stringify( editor.getJSON() );
                break;
        }
        const blob = new Blob( [ data ] , { type: fileType + ';charset=utf-8;' });
        let uri = window.URL.createObjectURL(blob);
        let link = document.createElement("a");

        link.setAttribute("href", uri);
        link.setAttribute("target", '_blank');
        link.setAttribute("download", fileName);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    return (
    <Box sx={(theme) => ({
        padding: '0px'
    })}>
        <Group position='left' spacing={"xs"}>
            <Avatar radius={"md"} sx={(theme) => ({
                '& .mantine-Avatar-placeholder': {
                    backgroundColor: theme.colors.indigo[5],
                }
            })}><DownloadIcon size={24} color="#fff" /></Avatar>
            <div>
                <Text size="md" weight='700'>Export</Text>
                <Text size="xs" color="gray">Export document to Text, HTML, or JSON file</Text>
            </div>
        </Group>
        <Space h="xs" />
        <SegmentedControl
            value={format}
            onChange={setFormat}
            data={[
                { label: 'HTML', value: 'html' },
                { label: 'Text', value: 'text' },
                { label: 'JSON', value: 'json' },
            ]}
            />
        <Space h="xs" />
        <Button
            fullWidth
            radius={"xl"}
            pl="xl"
            pr="xl"
            leftIcon={<DownloadIcon />}
            onClick={handleExport}
            >
            Export
        </Button>
    </Box>
    );
}

const mapStateToProps = state => {
    return {
        authData: ( state.auth && state.auth.user ) ? state.auth.user : null,
        navbarStatus: ( state.global && state.global.navbar ) ? state.global.navbar : false,
    };
};

export default compose(
    connect(mapStateToProps)
)(PanelExport);