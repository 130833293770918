
import { 
    GET_PROJECTS,
    GET_PROJECT,
    DELETING_PROJECT,
    RESET_PROJECTS,
    RESET_PROJECT,
} from './types';

const initialState = {
    projects: null,
    project: null,
    updated: false,
    rand_single: false,
    rand: false
};

export const projects = (state = initialState, action) => {
    const { type, payload = null } = action;
    switch (type) {
        case GET_PROJECTS:
            return {
                ...state,
                projects: payload && payload.list ? payload.list : [],
                rand: Math.floor(Math.random() * 1000000)
            };
        case GET_PROJECT:
            return {
                ...state,
                project: payload && payload.project ? payload.project : {},
                rand_single: Math.floor(Math.random() * 1000000)
            };
        case DELETING_PROJECT:
            return {
                ...state,
                deleting: payload && payload.id ? payload.id : null,
            };
        case RESET_PROJECTS:
            return {
                ...state,
                projects: [],
                rand: false
            };
        case RESET_PROJECT:
            return {
                ...state,
                project: {},
                rand_single: false
            };
        default:
            return state;
    }
}