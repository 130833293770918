// const _isEmpty      = require('lodash/isEmpty');
const { isArrayExists } = require('./validation');

export const isCustomModel = (model,team) => {
	return ( model && team && team.custom_engines && isArrayExists( team.custom_engines ) && team.custom_engines.find(item => item.id === model && item.custom_model) ) ? true : false;
}

export const getCustomModel = (model,team,key) => {
	const selected = ( model && team && team.custom_engines && isArrayExists( team.custom_engines ) ? team.custom_engines.find(item => item.id === model) : false );
	return ( selected && selected[key] ) || false;
}