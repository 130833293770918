import React, { useMemo } from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
// import { useParams } from "react-router-dom";
import { useMantineTheme, Card, Text, Group, Box } from '@mantine/core';
import { useClipboard } from '@mantine/hooks';
import { TrashIcon, CopyIcon, PlusCircledIcon, UpdateIcon, ReaderIcon } from '@radix-ui/react-icons';
import { useEditor, EditorContent } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import CodeBlockLowlight from '@tiptap/extension-code-block-lowlight';
import { lowlight } from 'lowlight';

import ButtonIconCircle from '../../components/ButtonIconCircle';
import MultilineText from '../../components/MultilineText';

// import { isArrayExists } from '../../helpers/validation';

// import { getWizardTypeData } from './helpers';

const CodeBlock = ({
    result
}) => {

    const editor = useEditor({
        editable: false,
        content: `<pre><code>${result.text}</code></pre>`,
        extensions: [
            StarterKit.configure({
                codeBlock: false,
                code: {
                    HTMLAttributes: {
                        class: 'code-hightlight',
                    },
                }
            }),
            CodeBlockLowlight.configure({
                lowlight,
                HTMLAttributes: {
                    class: 'code-block-highlight',
                }
            }),
        ]
    });

    if ( !editor ) {
        return <MultilineText text={result.text} textProps={{ size: 'md', component: 'p' }} />;
    }

    return (
    <EditorContent 
        editor={editor} />
    );
}

const Result = ({ 
    globalDisabled,
    result,
    onAdd = () => {},
    onReplace = () => {},
    onAddToNotes = () => {},
    onDelete = () => {}
}) => {
    const theme = useMantineTheme();
    const clipboard = useClipboard({ timeout: 1000 });

    const isCodeModel = useMemo(() => {
        // if result.model starts with code-
        return ( result && result.model && result.model.startsWith('code-') );
    }, [ result ]);

    return (
    <Card
        radius={"md"}
        withBorder>
        <Card.Section
            sx={(theme) => ({
                padding: '5px 10px',
            })}>
            <Group 
                spacing={"md"}
                position='apart' 
                noWrap>
                <Group
                    spacing={"3px"}>
                    <ButtonIconCircle
                        icon={<PlusCircledIcon />}
                        label={ "Add"}
                        useClipboard={true}
                        btnProps={{
                            variant: "light",
                            color: 'gray',
                            radius: 'md',
                            size: 'xs'
                        }}
                        color={theme.colors.indigo[8]}
                        onClick={() => onAdd(result)}
                    />
                    <ButtonIconCircle
                        icon={<UpdateIcon />}
                        label={ "Replace selected text"}
                        useClipboard={true}
                        btnProps={{
                            variant: "light",
                            color: 'gray',
                            radius: 'md',
                            size: 'xs'
                        }}
                        color={theme.colors.indigo[8]}
                        onClick={() => onReplace(result)}
                    />
                    <ButtonIconCircle
                        icon={<CopyIcon />}
                        label={ clipboard.copied ? "Copied" : "Copy" }
                        useClipboard={true}
                        btnProps={{
                            variant: "light",
                            color: 'gray',
                            radius: 'md',
                            size: 'xs'
                        }}
                        color={theme.colors.indigo[8]}
                        onClick={() => clipboard.copy(( result.text || '' ))}
                    />
                    <ButtonIconCircle
                        icon={<ReaderIcon />}
                        label={ "Add to Notes"}
                        useClipboard={true}
                        btnProps={{
                            variant: "light",
                            color: 'gray',
                            radius: 'md',
                            size: 'xs'
                        }}
                        color={theme.colors.indigo[8]}
                        onClick={() => onAddToNotes(result)}
                    />
                </Group>
                <Box>
                    <ButtonIconCircle
                        icon={<TrashIcon />}
                        label="Remove"
                        btnProps={{
                            variant: "light",
                            color: 'gray',
                            radius: 'md',
                            size: 'xs',
                            // disabled: globalDisabled,
                        }}
                        color={theme.colors.red[8]}
                        onClick={() => onDelete(result)}
                    />
                </Box>
            </Group>
        </Card.Section>
        { isCodeModel ? <CodeBlock result={result} /> : <MultilineText text={result.text} textProps={{ size: 'md', component: 'p' }} /> }
    </Card>
    )
}

const mapStateToProps = state => {
    return {
        authData: ( state.auth && state.auth.user ) ? state.auth.user : null,
        globalDisabled: ( state.global && state.global.disabled ) ? state.global.disabled : false,
    };
};

export default compose(
    connect(mapStateToProps)
)(Result);